import React, {useState, createContext, useEffect,useContext,useCallback} from "react";
import {AuthProvider,AuthContext} from "../../contexts/auth";

import {Link} from "react-router-dom"

import {getDates} from "../../services/apiRoutes";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStar,faChevronLeft,faTrash,faBars,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'
import {faStar as farStar} from '@fortawesome/free-regular-svg-icons'


import logo from '../../logo.png';
import logolight from '../../logo-light.png';


import Calendar from "../../layout/header/calendar";	
import MenuHeader from "../../layout/header/menu";	
import Notification from "../../layout/header/notification";
import MapHeader from "../../layout/header/map";
import AdmDevice from "../../layout/header/admdevice";
import Camera from "../../layout/header/camera";

const Header = () => {

	let dateToday = (new Date());
  	let dateTodayString = dateToday.getFullYear()+'/'+ ("0" + (dateToday.getMonth()+1)).slice(-2) +'/'+("0" + dateToday.getDate()).slice(-2);

	const {logout,user,location,global,globalUpdate,view,viewUpdate,header,headerUpdate} = useContext(AuthContext);

	const [menuHeaderView,menuHeaderViewUpdate] = useState('');
	const [menuHeaderStatus,menuHeaderStatusUpdate] = useState('');
	const [menupointStatus,menupointStatusUpdate] = useState('');

	let handleMenu = (type,callback) =>{
		

		if( menuHeaderView !== type ){
			
			menuHeaderStatusUpdate('active');
			menuHeaderViewUpdate(type);
		}else{

			if(menuHeaderStatus === ''){
				menuHeaderStatusUpdate('active');
				menuHeaderViewUpdate(type);
			}else{
				menuHeaderStatusUpdate('');
				menuHeaderViewUpdate('');
			}
		}

		setTimeout(()=>{
			if(callback)callback();
		},1000)
	}

	useEffect(()=>{

		header.menuToggle = handleMenu;
		headerUpdate({...header});

	},[]);
	





	
	
	const mapdateHandle = () =>{	
		// listFavoriteHandle();
		handleMenu('date',()=>{});
	}

	const menuHeaderViewHandle = () =>{			
		handleMenu('menu');
	}

	const notificationHeaderViewHandle = () =>{			
		handleMenu('notification');
	}

	const mapsMenuHandle = () =>{			
		handleMenu('map');
	}

	
	
	const locateDeviceHandle = () =>{
		global.map.locateDevice();
	}
	
	const positionSelectHandle = (mode) =>{		
		global.map.positionSelect(mode,()=>{
			global.map.selectRoute();
		});
	}

	

	const menupointHandle = () =>{
		if(menupointStatus === ''){
			menupointStatusUpdate('active');
		}else{
			menupointStatusUpdate('');
		}
	}

	

	const markerHandle = () =>{
		if(view.map.settings !== undefined){
			view.map.settings = undefined;
			viewUpdate({...view});	
			menupointHandle();	
			return;
		}
		view.map.settings = 'marker';
		viewUpdate({...view});		
		menupointHandle();
	}

	const trackersHandle = () =>{
		/*if(view.map.settings !== undefined){
			view.map.settings = undefined;
			viewUpdate({...view});		
			menupointHandle();
			return;
		}
		view.map.settings = 'trackers';*/

		if(view.map.trackersbar){
			view.map.trackersbar = false;
		}else{
			view.map.trackersbar = true;			
		}
		viewUpdate({...view});		
		menupointHandle();
	}

	
	const mylocateIconHandle = () =>{		
		if(view.map.mylocate === true)
			view.map.mylocate = undefined;
		else
			view.map.mylocate = true;

		viewUpdate({...view});	
		menupointHandle();
	}

	
	/* const myCameraHandle = () =>{		
		if(view.map.mycamera === true)
			view.map.mycamera = undefined;
		else
			view.map.mycamera = true;

		viewUpdate({...view});	
		menupointHandle();
	}
 */
	const mapsCameraHandle = () =>{			
		// handleMenu('camera');
		if(view.map.settings !== undefined){
			view.map.settings = undefined;
			viewUpdate({...view});	
			menupointHandle();	
			return;
		}
		view.map.settings = 'camera';
		viewUpdate({...view});		
		menupointHandle();
	}





	return (
		<div className="layout_header --menutop">

			<div className={"headerItens "+menuHeaderStatus+" "+menuHeaderView}>

				<Calendar handleMenu={handleMenu} menuHeaderView={menuHeaderView}/>
				<MenuHeader handleMenu={handleMenu}/>
				<Notification handleMenu={handleMenu}/>
				<MapHeader handleMenu={handleMenu}/>
				<AdmDevice handleMenu={handleMenu}/>
				<Camera handleMenu={handleMenu}/>
				
			</div>


			<div className="line mobile-nobreak">
				<div className="column middle left">


					{/* Menu Header and Button Date*/}

					{ (location.pathname==='/dashboard/map')?(
						<div className="headerarea">
							
							<a className={"button buttonHeader menupointIcon "+menupointStatus} onClick={()=>{ menupointHandle() }}></a>
						
						{ global.map.date === 'today' || global.map.date===dateTodayString?
							(
								<label className="date disabled" disabled onClick={(e)=>{mapdateHandle()}}>
									Hoje
								</label>
							)
							:
							(

								global.map.date && (
									<label className="date disabled" disabled onClick={(e)=>{mapdateHandle()}}>
										{global.map.date}
									</label>
								)
								
							) 
						}
							
									
							<div className={"headerControls "+menupointStatus}>
								<ul>
									{/*<li><a className="button buttonHeader locateIcon" onClick={()=>{ locateDeviceHandle() }}></a></li>*/}
									<li>
										<a className="button buttonHeader bifurcationIcon" onClick={()=>{ positionSelectHandle() }} >
											<span className="badge" onClick={(e)=>{ e.stopPropagation(); positionSelectHandle('reset') }}> { (global.map.positionCurrent === null)?global.map.positionCount:global.map.positionCurrent+"/"+global.map.positionCount}</span>
										
										</a>	
									</li>
									<li><a className="button buttonHeader mapsIcon" onClick={()=>{ mapsMenuHandle() }}></a></li>
									<li><a className="button buttonHeader devicesIcon" onClick={()=>{ trackersHandle() }}></a></li>
									<li><a className="button buttonHeader marksIcon" onClick={()=>{ markerHandle() }}></a></li>
									<li><a className="button buttonHeader mylocateIcon" onClick={()=>{ mylocateIconHandle() }}></a></li>
									<li><a className="button buttonHeader mycamera" onClick={()=>{ mapsCameraHandle() }}></a></li>
								</ul>
								
							</div>
														
						</div>
					):(
						<div className="headerarea">
							<img src={logolight} className="logo" />
							<span className="title">{user.name || 'Onistation'}</span>
						</div>
					)}
					
				</div>
				<div className="column col3 middle right">
					{/*<a className="button buttonHeader notificationIcon" onClick={notificationHeaderViewHandle}></a>*/}
					<a className="button buttonHeader menuIcon" onClick={menuHeaderViewHandle} ></a>
				</div>
			</div>
		</div>
	);
};


export default Header;