import React, {useState,useContext,useEffect} from "react";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faImages,faDownload,faLock,faUnlock,faBan,faCamera,faFlag,faThumbtack,faMapMarkerAlt,faUsers,faUserCircle,faGlobe,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'

import {AuthProvider,AuthContext} from "../../../contexts/auth";

import {blockVehicle} from "../../../services/apiTrackerCommand";

import { useSnackbar } from 'notistack';

import {showFullName} from "../../../libs/tracker.js";

const PopupMap = (props) => {
    
    const { enqueueSnackbar } = useSnackbar();


    const {view,viewUpdate,global,globalUpdate} = useContext(AuthContext);

    const [visibleCommands,visibleCommandsUpdate] = useState(false);

    const handleGooglemap = (position) =>{
        
        let latitude = position[0];
        let longitude = position[1];
    
        window.open('https://www.google.com/maps/dir//'+latitude+','+longitude+'/@'+latitude+','+longitude+',15z');
    }

    const followDeviceHandle = (imei) =>{
        
        props.global.map.followdevice = {};
        props.global.map.followdevice.index = props.index;
        props.global.map.followdevice.imei = imei;
        props.global.map.followdevice.name = props.name;
        props.global.map.followdevice.speed = props.speed;
        props.global.map.followdevice.parked = props.parked;
        props.global.map.followdevice.online = props.online;


        props.globalUpdate({...props.global});
    }

    const handleBlockVehicle = async (data)  =>{  

        let input = window.confirm("Deseja Bloquear este veículo?");
        if(input == false)return;
        

        
        const response = await blockVehicle(data);
        enqueueSnackbar('Bloqueio de veículo enviado', { variant: "success"});
    }

    const handleCamera = (data) =>{  

        view.map.settings = undefined;   
        viewUpdate({...view});
        
        setTimeout(()=>{

            view.map.settings = 'camera';   
            view.map.data = data;   
            viewUpdate({...view});
        },100);
        
    }

    const handleImage = (data) =>{  

        view.map.settings = undefined;   
        // view.map.positionid = undefined;   
        viewUpdate({...view});
        
        setTimeout(()=>{

            //console.log(data);
            //if(typeof  data !== 'undefined')
            //data.positionid = props.positionid;

            view.map.settings = 'images';   
            view.map.data = data;                 
            viewUpdate({...view});
        },10);
        
    }

    

    return(
        <div className="PopupDevice">
            <h3  style={{"borderColor":props.color}}>
            {props.name}
            <small>{props.subtitle}</small>
            </h3>

            <table>
                <tbody>
                  
                    {props.imei && (
                        <tr>
                            <th>IMEI</th><td>{props.imei}</td>
                        </tr>
                    )}

                    {props.lastupdate && (
                        <tr>
                            <th>Último registro</th><td>{props.lastupdate.split(' ')[1]}</td>
                        </tr>
                    )}


                    {props.speed && (
                        <tr>
                            <th>Velocidade</th><td>{props.speed} km</td>
                        </tr>
                    )}
                                        

                    {props.departureTime && (
                        <tr>
                            <th>Partida anterior</th><td>{props.departureTime.split(' ')[1]}</td>
                        </tr>
                    )}

                    {props.arrivalTime && (
                        <tr>
                            <th>Chegada</th><td>{props.arrivalTime.split(' ')[1]}</td>
                        </tr>
                    )}

                    {props.exitTime && (
                        <tr>
                            <th>Saída</th><td>{props.exitTime}</td>
                        </tr>
                    )}

                    {props.travelTime && (
                        <tr>
                            <th>Tempo de trajeto</th><td>{props.travelTime}</td>
                        </tr>
                    )}

                    {props.parkedTime && (
                        <tr>
                            <th>Tempo estacionado</th><td>{props.parkedTime}</td>
                        </tr>
                    )}

                    {props.geoDistance && (
                        <tr>
                            <th>Distancia do trajeto</th><td>{props.geoDistance}</td>
                        </tr>
                    )}
            

                    {props.geoDistanceTotal && (
                        <tr>
                            <th>Trajeto do dia</th><td>{props.geoDistanceTotal}</td>
                        </tr>
                    )}

                    {props.positionid && (
                        <tr>
                            <th>ID do trecho</th><td><input type="text" readOnly value={props.positionid}></input></td>
                        </tr>
                    )}
            
                    
            
                  
                </tbody>
            </table>

            {props.address && (
                <small style={{"display":"block","marginBottom":"10px","fontSize":"11px"}}>{props.address}</small>
            )}

            

            <div className="line mobile-nobreak">
                <div className="column ">
                    <button className="button padding w100" title="Ver localização no Google Maps" onClick={()=>{handleGooglemap(props.lastposition)}}><FontAwesomeIcon icon={faMapMarkerAlt} /></button>
                </div>

                {props.follow && (
                    <div className="column padding-left">
                        <button className="button padding -transparent w100" onClick={()=>{followDeviceHandle(props.imei)}}><FontAwesomeIcon icon={faThumbtack} /></button>
                    </div>
                )}
                
                { (props.camera  && props.online ) && (
                    <div className="column padding-left">
                        <button className="button padding w100" title="Ver Imagens da câmera" onClick={()=>{handleCamera(props)}}><FontAwesomeIcon icon={faCamera} /></button>
                    </div>
                )}
                
                {props.images && (
                    <div className="column padding-left">
                        <button className="button padding w100" title="Ver Imagem do trajeto" onClick={()=>{handleImage(props)}}><FontAwesomeIcon icon={faImages} /></button>
                    </div>
                )}

                {props.commands && (
                    <div className="column padding-left">
                        <button className="button padding w100 -link" title="Desbloquear comandos" onClick={()=>{visibleCommandsUpdate(!visibleCommands)}}>
                            { visibleCommands === true &&(<FontAwesomeIcon icon={faUnlock} />)}
                            { visibleCommands === false &&(<FontAwesomeIcon icon={faLock} />)}
                        </button>
                    </div>
                )}

                {/*{props.marker && (
                    <div className="column padding-left">
                        <button className="button -transparent w100"><FontAwesomeIcon icon={faFlag} /></button>
                    </div>
                )}*/}
                
            </div>

            { visibleCommands === true &&(
                <div className="line mobile-nobreak padding-top">
                    <div className="column col3">
                        <button className="button padding w100 -error" title="Bloquear veículo" onClick={()=>{handleBlockVehicle(props)}}><FontAwesomeIcon icon={faBan} /></button>
                    </div>
                    <div className="column col3"></div>
                    <div className="column "></div>
                    <div className="column "></div>
                </div>

            )}


            {/* {props.images && (
                <div className="popupimages">
                    <div className="line mobile-nobreak">
                        <div className="column">
                            
                            {Object.keys(props.images).map((key,index) => {
                                return (
                                        <span key={index}>
                                            <div className="line mobile-nobreak ">
                                                <div className="column right middle">
                                                    <label style={{"margin":"0px"}}>{showFullName(key)}</label>
                                                </div>
                                                <div className="column col0  center middle">
                                                    <a className="button  padding -link w100" href={props.images[key]} download><FontAwesomeIcon icon={faDownload} /></a>
                                                </div>
                                            </div>
                                            <img src={props.images[key]} alt="" />
                                        </span>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )} */}
        </div>
    )
}

export {PopupMap}