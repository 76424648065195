const seqArray = (parameters) => {

    let array = parameters['array'];
    let index = parameters['index'];
    let step = parameters['step'];
    let finish = parameters['finish'];
    let variables = parameters['variables'] || {};
    

    if(array[index] === undefined){
        if(finish)finish(variables);
        return;
    }

    const next = (variables)=> {
        seqArray({
            array:array,
            index:index+1,
            step:step,
            finish:finish,
            variables:variables
        });
    }

    if(step) step(next,array[index],index,array,variables);

}	

export {seqArray}