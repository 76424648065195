import React, {useState, createContext, useEffect} from "react";

// import Header from "../../layout/header/index.jsx";
// import Footer from "../../layout/footer/index.jsx";
// import FormLogin from "../../layout/formlogin/index.jsx";

import {setDevicesPosition} from "../../../services/apiTrackers";

import {useLocation} from "react-router-dom";

// import "./style.css";

const DashboardNotFoundPage = () => {


	const [heading,headingUpdate] = useState(15);
	const [speed,speedUpdate] = useState(21);

	const location = useLocation();

/*	useEffect(()=>{
		getLocation();
	},[]);
*/
	const getLocation = () =>{

		navigator.geolocation.getCurrentPosition(function(position) {
			let latitude = position.coords.latitude;
			let longitude = position.coords.longitude;
			
	      
	      console.log(position);
	      
	      console.log("Latitude is :", position.coords.latitude);
	      console.log("Longitude is :", position.coords.longitude);
	      console.log("Heading is :", heading);

	      	let dates = (new Date());
			let currentDate = dates.getFullYear()+'-'+ ("0" + (dates.getMonth()+1)).slice(-2) +'-'+("0" + dates.getDate()).slice(-2);
			let currentTime = ("0" + (dates.getHours())).slice(-2)+':'+ ("0" + (dates.getMinutes()+1)).slice(-2) +':'+("0" + dates.getSeconds()).slice(-2);

			// console.log(currentDate+" "+currentTime);


			let data = {
				"type":"tk303",
				"latitude":latitude,
				"longitude":longitude,
				"altitude":"null",
				"heading":heading,
				"speed":speed,
				"provider":"",
				"typemsg":"tracker",
				"accuracy":"F",
				"date": currentDate+" "+currentTime,
			};

			console.log(data);

	      setDevicesPosition('abc123',data);

	    });

	    /*setTimeout(()=>{
	    	getLocation();
	    },10000);*/
	}

	const getLocationHandle = () =>{
		getLocation();
	}


	return (
		<>
			<div className="container">
				<div className="line">
					<div className="column middle ">
						<h1>Simulate Route</h1>
					
						
						<label>heading</label>
						<input type="text" name="heading" value={heading} onChange={(e)=>{ headingUpdate(e.target.value); }} />

						<label>speed</label>
						<input type="text" name="number" value={speed} onChange={(e)=>{ speedUpdate(e.target.value); }} />

						<br/>

						<button onClick={()=>{getLocationHandle()}}>Set</button>

					</div>
				</div>
			</div>
		</>
	);
};


export default DashboardNotFoundPage;
