import { env } from '../.env.js';
import axios from "axios";

import {getUniqueID} from "../libs/getuniqueid.js";
import  {md5} from "../libs/md5";	

export const api = axios.create({
	baseURL: env.AchoordURL
});


// session 
export const listDevices = async () => {

	// console.log(window.username);

	

	// let device = getUniqueID();	
	// password = md5(password);
	// hashuser = "3fc01891d40d45a0765fd1d28e9abd62";
	return api.get("/trackers/devices",{
	  headers: {
	    'account': window.username,
	    // 'hashuser': window.hashuser
	    'hashuser': window.hashuser
	  }
	});


	// return api.get("/users/api/token/?email="+email+"&password="+password);
}

export const addDevice = async (parameters) => {
	// let date = parameters['date'];
	// let title = parameters['title'];
	// let description = parameters['description'];
	
	var bodyFormData = new FormData();

	for(let key in parameters){
		bodyFormData.append(key, parameters[key]);
	}

	// console.log(parameters);
	// let data = parameters;

	// return api.put("http://admin.achoord.com/api/v1/accounts/wallrio/onistation/p/apigateway/api/call/marker/setmarker/",bodyFormData,{
	return api.post("/trackers/add/",parameters,{
	  headers: {
	  		// "Content-Type": "application/json",
	  		'account': window.username,
	  		'hashuser': window.hashuser
	  		// "Content-Type": "multipart/form-data"
	    // 'hashuser': hashuser
	  }
	});

}

export const setDevice = async (imei,parameters) => {
	// let date = parameters['date'];
	// let title = parameters['title'];
	// let description = parameters['description'];
	
	var bodyFormData = new FormData();

	for(let key in parameters){
		bodyFormData.append(key, parameters[key]);
	}

	// console.log(parameters);
	// let data = parameters;

	// return api.put("http://admin.achoord.com/api/v1/accounts/wallrio/onistation/p/apigateway/api/call/marker/setmarker/",bodyFormData,{
	return api.put("/trackers/set/",parameters,{
	  headers: {
	  		// "Content-Type": "application/json",
	  		'account': window.username,
	  		'imei': imei,
	  		'hashuser': window.hashuser
	  		// "Content-Type": "multipart/form-data"
	    // 'hashuser': hashuser
	  }
	});

}


export const delDevices = async (imei) => {
	
	return api.delete("/trackers/del/?onlydevice",{
	  headers: {
	  	'account': window.username,
	  	'imei': imei,
	  	'hashuser': window.hashuser
	  		// "Content-Type": "multipart/form-data"
	    // 'hashuser': hashuser
	  }
	});

}


export const getDevices = async (imei) => {
	
	return api.get("/trackers/get/",{
	  headers: {
	  	'account': window.username,
	  	'imei': imei,
	  	'hashuser': window.hashuser
	  		// "Content-Type": "multipart/form-data"
	    // 'hashuser': hashuser
	  }
	});

}


export const checkTrackers = async (email) => {
	return api.get("/users/api/checkconnected/email:"+email);
}

export const checkTrackersDevice = async (device) => {
	if(device){
		return api.get("/session/check?device="+device);
	}else{

		let device = getUniqueID();
		return api.get("/session/check?device="+device);
		// return api.get("/session/check/");
	}
}

export const disconnectTrackersDevice = async (device) => {
	if(device){
		return api.get("/session/signout/?device="+device);
		// return api.get("/users/api/deleteconnected/?device="+device);
	}else{
		let device = getUniqueID();
		return api.get("/session/signout/?device="+device);
		// return api.get("/users/api/deleteconnected/?device="+device);
		// return api.get("/users/api/deleteconnected/");
	}
}

export const disconnectTrackersUser = async (email) => {
	return api.get("/users/api/deleteconnected/?iduser="+email);
}
// https://admin.achoord.com/api/v1/accounts/wallrio/onistation/p/trackers/api/routes/864893035564049/


export const setDevicesPosition = async (imei,data) => {

	

	let hashuser = "3fc01891d40d45a0765fd1d28e9abd62";
	return api.post("/trackers/createroute/?imei="+imei,data,{
	  headers: {
	  	'account': window.username,
	    'hashuser': hashuser
	  }
	});
}

