import React, {useState, createContext, useEffect,useContext} from "react";
import {AuthProvider,AuthContext} from "../../../contexts/auth";
import { MapContainer, TileLayer, useMap,Marker,Popup ,Polyline,Tooltip} from 'react-leaflet'
import L from "leaflet";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes,faFlag,faThumbtack,faMapMarkerAlt,faUsers,faUserCircle,faGlobe,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'

// import {useNavigate,useLocation} from "react-router-dom";
// import {useMap} from 'react-leaflet/hooks'




import {ajustArrowZoom} from "../../../libs/ajustArrowZoom.jsx";
// import {listDevices} from "../../../services/apiTrackers";
import {Storage} from "../../../libs/Storage.jsx";
import {keepAwake} from "../../../libs/keepAwake.jsx";

import {DataMap} from "./DataMap.jsx";
import {SetupDevices} from "./SetupDevices.jsx";
import {LocationMarker} from "./LocationMarker.jsx";

import {DrawDevices} from "./draw/devices.jsx";
import {DrawLines} from "./draw/lines.jsx";
import {DrawPoints} from "./draw/points.jsx";
import {DrawTags} from "./draw/tags.jsx";
import {DrawMarker} from "./draw/marker.jsx";

import {SettingsMap} from "./settingsmap/index.jsx";

import {TrackersBar} from "./settingsmap/Trackers";

import { useSnackbar } from 'notistack';

const Component = () => {

    const { enqueueSnackbar } = useSnackbar();

    // const location = useLocation();

    const {logout,user,location,global,globalUpdate,view,viewUpdate} = useContext(AuthContext);    
    

    // define o mapa a ser exibido
    const [mapCurrent,mapCurrentUpdate] = useState(global.map.image[global.map.imagecurrent]);        
    useEffect(()=>{

        
        // if(location.pathname !== '/dashboard/map')
        // global.map.date = null;

       let mapType = Storage().load('onistation-map-type');  
        if(mapType === null) mapType = 'simple';
        global.map.imagecurrent = mapType;

        // executa quando o usuário sair da pagina do mapa
        return ()=>{
            followdeviceClose();
        }
    },[]);

    useEffect(()=>{
         mapCurrentUpdate(global.map.image[global.map.imagecurrent]);
    },[global.map.imagecurrent]);
    



    // configura os rastreadores
    const [devices,devicesUpdate] = useState([]);
    SetupDevices(devices,devicesUpdate,(devices2)=>{        
        devicesUpdate(devices2);
    });


    // carrega/define o zoom e o posicionamento inicial do mapa
    let position = Storage().load('onistation-map-position'); 
    let zoom = Storage().load('onistation-map-zoom');     
    if(zoom === null)zoom = 3;
    if(position !== null){
        position = position.split(',');
        position = [parseFloat(position[0]),parseFloat(position[1])];
    }else{
        position = [-27.761329874505233,-60.02929687500001];
    } 
    
    // carrega a data inicial do mapa
    useEffect(()=>{

        enqueueSnackbar('Carregando rastreadores', { variant: "info"});
        setTimeout(()=>{
            enqueueSnackbar('Carregando datas', { variant: "info"});
        },1000);

        let mapDate = Storage().load('onistation-map-date');
        if(mapDate === null){
            let dates = (new Date());
            // mapDate = dates.getFullYear()+'/'+ ("0" + (dates.getMonth()+1)).slice(-2) +'/'+("0" + dates.getDate()).slice(-2);        
            mapDate = 'today';        
        }else if(mapDate === 'today'){
            let dates = (new Date());
            mapDate = dates.getFullYear()+'/'+ ("0" + (dates.getMonth()+1)).slice(-2) +'/'+("0" + dates.getDate()).slice(-2);
        }
        globalUpdate((glob)=>{                      
            glob.map.date = mapDate;
            return glob;
        });
    },[]);


    const followdeviceClose = () =>{
        global.map.followdevice = null;
        globalUpdate({...global});
    }

    if( global.map.followdevice && typeof devices[global.map.followdevice.index] === 'undefined' ){
        global.map.followdevice = null;
    }
    
    


    

	return (
		<div className="h100" >	
            
            <SettingsMap devices={devices} />
            {view.map.trackersbar === true && (    <TrackersBar devices={devices} /> )}
            
            <MapContainer 
            center={position} 
            zoom={zoom} 
            scrollWheelZoom={true} 
            zoomControl={false} >

            
            {(global.map.followdevice ) && (
                <div className="followPanel">
                    
                    <div className="followPanel_area">

                        <div className="line mobile-nobreak tablet-nobreak">
                            <div className="column middle left padding-h-20 ">
                                <h3 className="followPanel_title ">
                                    {global.map.followdevice.name}
                                    {/*<small className="followPanel_subtitle">Seguindo...</small>*/}
                                </h3>

                            </div>
                            <div className="column col3 middle  right">
                                
                                {(devices[global.map.followdevice.index].parked!=='' && devices[global.map.followdevice.index].parked!==null)?(
                                    <div>
                                        {devices[global.map.followdevice.index].online===true?('Estacionado'):('sem sinal')}
                                    </div>
                                ):(                            
                                    <>
                                    { (  devices[global.map.followdevice.index].lastspeed <= 3) && (
                                        <div>
                                            Parado
                                        </div>
                                    )}

                                    { ( (devices[global.map.followdevice.index].parked==='' || devices[global.map.followdevice.index].parked===null) && devices[global.map.followdevice.index].lastspeed > 3) && (
                                        <div>
                                            {devices[global.map.followdevice.index].lastspeed?devices[global.map.followdevice.index].lastspeed:'?'} km
                                        </div>
                                    )}

                                    

                                    </>
                                    
                                )}

                                
                            </div>
                            <div className="column col1 middle padding-h-10 ">
                                <button className="link -primary w100" onClick={()=>{followdeviceClose()}}><FontAwesomeIcon icon={faTimes} /></button>
                            </div>
                        </div>

                        { global.map.followdevice.address && (
                            <div className="line mobile-nobreak tablet-nobreak" style={{"marginTop":"-8px"}}>
                                <div className="column middle left padding-bottom padding-h-20 ">
                                    <label className="margin-0">{ global.map.followdevice.address?global.map.followdevice.address.street:''}</label>
                                    <small style={{"marginTop":"-5px","display": "block"}}>{ global.map.followdevice.address?global.map.followdevice.address.city:''},&nbsp;
                                    { global.map.followdevice.address?global.map.followdevice.address.state:''},&nbsp;
                                    { global.map.followdevice.address?global.map.followdevice.address.postcode:''},&nbsp;
                                    { global.map.followdevice.address?global.map.followdevice.address.country:''}
                                    </small>
                                </div>
                            </div>
                        )}

                    </div>
                    
                </div>
            )}

                <DrawMarker/>

                <DataMap devices={devices} />
                <TileLayer url={mapCurrent} />

                <DrawPoints devices={devices} />
                <DrawLines devices={devices} />
                <DrawTags devices={devices} />
                <DrawDevices devices={devices} />

                {view.map.mylocate && (
                    <LocationMarker />
                )}

                        
                

            </MapContainer>
        </div>
	);
};


export default Component;
