import React, {useState, createContext, useEffect,useContext,useCallback} from "react";
import {AuthProvider,AuthContext} from "../../../contexts/auth";

import {Link} from "react-router-dom"

import {Storage} from "../../../libs/Storage.jsx";

const Component = (props) =>{

	const {logout,user,location,global,globalUpdate} = useContext(AuthContext);

	const mapsHandle = (mapType) =>{			
        global.map.imagecurrent = mapType;
        Storage().save('onistation-map-type',mapType);
		globalUpdate({ ...global,"update":(new Date()).getTime() });
		props.handleMenu('');
	}

	return (
		<>
			<ul className="headerItem camera">
				<li>
					<a onClick={()=>{ mapsHandle('simple') }}>Simples</a>
				</li>
				<li>
					<div className="viewer"></div>
				</li>
			</ul>
		</>
	);
}

export default Component;