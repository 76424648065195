const ajustArrowZoom = (zoom) => {
      
      zoom = parseInt(zoom);

      let pointsiconList = document.querySelectorAll('.pointsicon img');

            let scaleZoom = 1;
            let width = 25;
            let height = 25;

            if(zoom <= 17){
                  scaleZoom = 0;
                  width = 0;
                  height = 0;
            }

            if(zoom === 18){
                  scaleZoom = 0.7;
                  width = 15;
                  height = 15;
            }
            
            if(zoom === 17){
                  scaleZoom = 0.7;
                  width = 10;
                  height = 10;
            }

            // console.log(zoom,width,pointsiconList);

            for (var i = 0; i < pointsiconList.length; i++) {
                  // let scal = pointsiconList[i].style['transform'];
                  // console.log(scal);
                // pointsiconList[i].style['transform'] = 'scale('+(scaleZoom)+') '+scal;
                pointsiconList[i].style['width'] = width+'px';
                pointsiconList[i].style['height'] = height+'px';
            }
}

export {ajustArrowZoom}

 