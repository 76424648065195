function slugfy (str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to   = "aaaaeeeeiiiioooouuuunc------";
  for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

  return str;
}

export {slugfy}


const objToArray = (obj) => {
    	function* values(obj) {
		    for (let prop of Object.keys(obj)) // own properties, you might use
		                                       // for (let prop in obj)
		        yield obj[prop];
		}
		
		return Array.from(values(obj));
	}

export {objToArray}


const sortKey = (array,key) => {
	array = array.sort(function(a,b){
      return parseInt(a[key]) - parseInt(b[key]);
    });
    return array;
    // return array.reverse();
}

export {sortKey}


const secToDate = (seconds,mode) => {

    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    if( mode === 'extenso' ){

        var dDisplay = d > 0 ? d + (d == 1 ? " dia, " : " dias, ") : "";
        var hDisplay = h >= 0 ? h + (h == 1 ? " hora, " : " horas, ") : "";
        var mDisplay = m >= 0 ? m + (m == 1 ? " minuto, " : " minutos, ") : "";
        var sDisplay = s >= 0 ? s + (s == 1 ? " segundo" : " segundos") : "";
        return dDisplay + hDisplay + mDisplay + sDisplay;

    }else{


        var dDisplay = d > 0 ? d + (d == 1 ? "d, " : "d, ") : "";
        var hDisplay = h >= 0 ? h + (h == 1 ? "h, " : "h, ") : "";
        var mDisplay = m >= 0 ? m + (m == 1 ? "m, " : "m, ") : "";
        var sDisplay = s >= 0 ? s + (s == 1 ? "s" : "s") : "";
        return dDisplay + hDisplay + mDisplay + sDisplay;
    }
}

export {secToDate}


const stringToColor = (str) => {

   function hashCode(str) {
      let hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    }

    function pickColor(str) {
      // Note the last value here is now 50% instead of 80%
      return `hsl(${hashCode(str) % 360}, 100%, 50%)`;
    }

    return pickColor(str);
}

export {stringToColor}


const diffDateToHuman = (date1,date2) => {

    let date1InMs = new Date(date1).getTime();
    let date2InMs = new Date(date2).getTime();

    let duration = date2InMs - date1InMs;


    var seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
    days = Math.floor(duration / (1000 * 60 * 60 * 24));

    days = days < 10 ? "0" + days : days;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    // return days + " dias " + hours + "h:" + minutes +'m';
    return hours + "h" + minutes +'m';
}

export {diffDateToHuman}


const geoTodistance = (lat1, lon1, lat2, lon2) => {

    var p = 0.017453292519943295;    // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p)/2 + 
          c(lat1 * p) * c(lat2 * p) * 
          (1 - c((lon2 - lon1) * p))/2;
    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km

}

export {geoTodistance}
