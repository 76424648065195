import { env } from '../.env.js';
import axios from "axios";

import {getUniqueID} from "../libs/getuniqueid.js";
import  {md5} from "../libs/md5";	

import * as CryptoJS from 'crypto-js';

export const api = axios.create({
	baseURL: env.AchoordURL
});



export const getUser = async (iduser) => {
	
	let responseRequest = await api.get("/session/getuser/"+iduser+'/');
	return responseRequest;

}


export const setUser = async (parameters) => {

	let newParameters = {};

	for(let key in parameters){
		newParameters[key] = parameters[key];
	}

	let id = newParameters.id;

	delete newParameters.connected;
	delete newParameters.created;
	delete newParameters.id;
	delete newParameters.hashuser;
	delete newParameters.enabled;
	
/*	var bodyFormData = new FormData();

	for(let key in parameters){
		bodyFormData.append(key, newParameters[key]);
	}*/

	
		return api.put("/session/setuser/"+id,newParameters,{
	  headers: {
	  		// "Content-Type": "application/json",
	  		'account': window.username,
	  		// 'id': id,
	  		// 'imei': imei,
	  		// 'hashuser': window.hashuser
	  		// "Content-Type": "multipart/form-data"
	 
	  }
	});

}


export const addUserSession = async (parameters) => {
	
	let newParameters = {};

	for(let key in parameters){
		newParameters[key] = parameters[key];
	}

	let id = newParameters.id;

	delete newParameters.connected;
	delete newParameters.created;
	delete newParameters.id;
	delete newParameters.hashuser;
	delete newParameters.enabled;



	return api.post("/session/adduser/",newParameters,{
	  headers: {
	  		// "Content-Type": "application/json",
	  		'account': window.username,
	  		'id': id,
	
	  }
	});

}

export const addusertracker = async (parameters) => {
		
	let id = parameters.id;

	delete parameters.connected;
	delete parameters.created;
	delete parameters.id;
	delete parameters.hashuser;
	delete parameters.enabled;
	
	var bodyFormData = new FormData();

	for(let key in parameters){
		bodyFormData.append(key, parameters[key]);
	}


	return api.post("/session/addusertracker/",parameters,{
	  headers: {
	  		// "Content-Type": "application/json",
	  		'account': window.username,
	  		'id': id,
	  		// 'imei': imei,
	  		// 'hashuser': window.hashuser
	  		// "Content-Type": "multipart/form-data"
	
	  }
	});

}



// session 
export const createSession = async (email,password) => {
	let device = getUniqueID();	
	
	

	if(email.substr(0,1)!=='#'){
		password = md5(password);
	}else{
		email = email.substr(1);
	}

	
	// return api.get("/session/signin/?email="+email+"&password="+password+'&device='+device);
	return api.get("/session/signin/",{
	  headers: {
	  	'email': email,
	  	'password': password,
	  	'device': device,
	  		// "Content-Type": "multipart/form-data"
	    // 'hashuser': hashuser
	  }
	});	
}

export const checkSession = async (email) => {
	return api.get("/users/api/checkconnected/email:"+email);
}

export const checkSessionDevice = async (device) => {
	if(device){		
		return api.get("/session/check/"+device);
	}else{

		let device = getUniqueID();		
		let responseRequest = await api.get("/session/check/"+device+"/?autogenerate");
		/*let responseRequest = await api.get("/session/check/"+device+"/?autogenerate",{
			  headers: {
			  	'token': token
			  		// "Content-Type": "multipart/form-data"
			    // 'hashuser': hashuser
			  }
			});*/

/*
		var KeyObj = CryptoJS.enc.Utf8.parse('0123456789abcdef');
		responseRequest.data = CryptoJS.AES.decrypt(responseRequest.data, KeyObj, {mode: CryptoJS.mode.ECB});
		responseRequest.data = responseRequest.data.toString(CryptoJS.enc.Utf8);
		responseRequest.data = JSON.parse(responseRequest.data);
*/

		return responseRequest;		
	}
}

export const disconnectSessionDevice = async (device) => {
	if(device){
		return api.get("/session/signout/"+device);
		// return api.get("/users/api/deleteconnected/?device="+device);
	}else{
		let device = getUniqueID();
		return api.get("/session/signout/"+device);
		// return api.get("/users/api/deleteconnected/?device="+device);
		// return api.get("/users/api/deleteconnected/");
	}
}

export const disconnectSessionUser = async (email) => {
	return api.get("/users/api/deleteconnected/?iduser="+email);
}

