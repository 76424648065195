
import React, {useState, createContext, useEffect,useContext,useCallback} from "react";
import {AuthProvider,AuthContext} from "../../contexts/auth";

import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
	Outlet
} from "react-router-dom"


import Header from "../../layout/header/index.jsx";
import MenuMap from "../../layout/menumap/index.jsx";
import Menu from "../../layout/menu/index.jsx";

import {getUser} from "../../services/apiSession";
import {sendValidation} from "../../services/apiMisc";
import {diffDateToHuman,secToDate} from "../../libs/misc";

const Dashboard = () => {

	const {logout,user,setUser,global,globalUpdate,header,headerUpdate} = useContext(AuthContext);
	const [validationDate,validationDateUpdate] = useState('');
	const [timeTrySend,timeTrySendUpdate] = useState(31);


	useEffect(()=>{
		if(user.validation){
			let validationDatePre = ( (new Date).getTime()/1000) - parseInt(user.created) ;
			validationDatePre = 86400-parseInt(validationDatePre);
			validationDateUpdate(validationDatePre);
		}
	},[window.username]);


	const continueHandle = () =>{
		global.validation = null;
		globalUpdate({...global});
	}

	const logoutHandle = () =>{
		logout();
	}

	const trySendHandle = (e) =>{
		
		e.target.classList.add('disabled');
		e.target.classList.add('loading');

		sendValidation(user.email,user.name,user.validation).then((response)=>{
			let status = response.data.status;
			let body = response.data.body;
			if(status === 'success'){		
				e.target.classList.add('-success');
				setTimeout(()=>{
					e.target.classList.remove('-success');
				},2000);
			}			
			e.target.classList.remove('loading');		
		});


		var timeTryId = setInterval(()=>{
			timeTrySendUpdate((time)=>{
				if(time-1 <= 0){
					clearInterval(timeTryId);
					timeTrySendUpdate(31);
					checkUser();
				}
				return time-1;
			});
		},1000);
	}


	const checkUser = async () =>{
		const responseUser = await getUser(user.id);
				
		let statusUser = responseUser.data.status;
		let bodyUser = responseUser.data.body[0];
		
		if( statusUser === 'success' ){
			if(bodyUser.validation === 'ok') continueHandle();
		}
	}

	// if(global.validation ){
	if(global.validation && user.username !== 'outro' && user.username !== 'giselereis'){

		return (
			<div className="alerthome h100 center">
				<div className="line mobile-nobreak tablet-nobreak padding-20 margin-bottom-20 h100 desktop-w50 tablet-w50 ">
					<div className="column right desktop-center tablet-center middle">
								<h1>Validação</h1>

						{validationDate !== '' && (
							<>
								{validationDate> 0?(
									<>
										<p>Você tem <strong>{ secToDate(validationDate)  }</strong> para validação.</p>
										
										<p>A mensagem foi enviado para seu email. <small style={{"display":"block","color":"#bb4fff"}}>{user.email}</small></p>										
										<br/>
									</>
								):(
									<>
										<p>O prazo para validação esgotou.</p>
										
										<p>Valide seu cadastro para continuar utilizando a plataforma.</p>
										<br/>
										<p>A mensagem foi enviado para seu email. <small style={{"display":"block","color":"#bb4fff"}}>{user.email}</small></p>
										
										<br/>
									</>

								)}
							</>
						)}

						<div className="line mobile-nobreak tablet-nobreak desktop-w70 tablet-w80 ">
							<div className="column middle desktop-col4 tablet-col4 mobile-col4">
								{validationDate> 0 ? (
									<button className={"button -primary padding margin-right w100" } onClick={(e)=>{ continueHandle(e); }} >
						    			Continuar
						    		</button>
								):(
									<button className={"button -primary padding margin-right w100" } onClick={(e)=>{ logoutHandle(e); }} >
						    			Sair
						    		</button>
								)}
								</div>
							<div className="column  middle">
								<button className={"button -link padding margin-left w100  "+(timeTrySend===31?'':'disabled') } onClick={(e)=>{ trySendHandle(e); }} >
					    			Enviar novamente {timeTrySend===31?'':timeTrySend+'s'}
					    		</button>
							</div>
						</div>
						

					</div>
				</div>
			</div>
		)
	}


	return (
		<div className="wrapper layout_dashboard " >
			<div className="header">				
				<Header/>
			</div>
			<div className="main">
				<Outlet/>
			</div>
			<div className="footer">
				{/*<MenuMap/>*/}
				<Menu/>
			</div>
		</div>
	);
};


export default Dashboard;
