import {diffDateToHuman,geoTodistance} from "../../../libs/misc";

const getDateLastPosition = (positiondata,index2,name) => {

	

	if(positiondata[index2] === undefined){
		return null;
	}

	let pos = positiondata[index2].length-1;

    let arrivalTime = null;
    let exitTime = null;
    let parkedTime = null;
    let travelTime = null;
    let departureTime = null;
    let geoDistance = null;
    let geoDistanceTotal = null;
    let speed = positiondata[index2][pos].speed;

   
   

    departureTime = positiondata[index2][0].date;

    try{
        
        
        parkedTime = diffDateToHuman( 
            positiondata[index2][pos].date, 
            positiondata[index2+1][0].date
        );

    }catch(e){

        let dates = (new Date());
        let currentDate = dates.getFullYear()+'-'+ ("0" + (dates.getMonth()+1)).slice(-2) +'-'+("0" + dates.getDate()).slice(-2)
        +' ' + dates.getHours()+':'+ ("0" + (dates.getMinutes())).slice(-2) +':'+("0" + dates.getSeconds()).slice(-2);;

        parkedTime = diffDateToHuman( 
            positiondata[index2][pos].date, 
            currentDate
        );
        // parkedTime = "Não saiu";
    }

    try{
        
        travelTime = diffDateToHuman( 
            positiondata[index2][0].date, 
            positiondata[index2][pos].date
        );

    }catch(e){
        {/*travelTime = "Não saiu";*/}
    }


    // distancia do trajeto ------------------------------
    let lat2 = 0;
    let lon2 = 0;
    for(let keyPos in positiondata[index2]){
        let lat1 = positiondata[index2][keyPos].latitude;
        let lon1 = positiondata[index2][keyPos].longitude;
                                                                            
        if(geoDistance === null){
            geoDistance = 0;
            lat2 = lat1;
            lon2 = lon1;
            continue;
        }

        let geoDistanceCurrent =  geoTodistance(lat1, lon1, lat2, lon2);
        geoDistance +=  parseFloat(geoDistanceCurrent);

        lat2 = lat1;
        lon2 = lon1;
    }
    geoDistance = geoDistance.toFixed(2) + ' km';
  
    let subtitle = "Ponto de chegada";

    if( Object.keys(positiondata).length-1 === index2 ){
    
        subtitle = "Ponto de chegada final";
        
        //start: distancia total do trajeto ------------------------------
        lat2 = 0;
        lon2 = 0;
        for(let keyPos0 in positiondata){
            for(let keyPos in positiondata[keyPos0]){
                let lat1 = positiondata[keyPos0][keyPos].latitude;
                let lon1 = positiondata[keyPos0][keyPos].longitude;
                                                                                    
                if(geoDistanceTotal === null){
                    geoDistanceTotal = 0;
                    lat2 = lat1;
                    lon2 = lon1;
                    continue;
                }

                let geoDistanceCurrent =  geoTodistance(lat1, lon1, lat2, lon2);
                geoDistanceTotal +=  parseFloat(geoDistanceCurrent);
        
                lat2 = lat1;
                lon2 = lon1;
            }
        }
        geoDistanceTotal = geoDistanceTotal.toFixed(2) + ' km';
        //end: distancia total do trajeto ------------------------------
    }

   

    try{                           
        exitTime = positiondata[index2+1][0].date;
    }catch(e){}

    try{                           
        arrivalTime = positiondata[index2][pos].date;
        // arrivalTime = positiondata[index2][pos].date.split(' ')[1];
    }catch(e){}

    let geopos= positiondata[index2][pos].geopos;

	return{
		"subtitle":subtitle,
		"geopos":geopos,
		"parkedTime":parkedTime,
		"arrivalTime":arrivalTime,
		"exitTime":exitTime,
		"travelTime":travelTime,
		"departureTime":departureTime,
		"geoDistance":geoDistance,
		"geoDistanceTotal":geoDistanceTotal,
	};
}

export {getDateLastPosition}