import {geoToAddress} from "../services/apiMisc";

const geoToAddressHandle = async (latitude,longitude) =>{
    const response = await geoToAddress(latitude,longitude);

    
    // let status = response.data.status;
    // let body = response.data.body;

    // console.log(response.data.status);

    if(response.data.status === 'no-match'){
        return {};
    }

    return {
        street:response.data.address.road,
        state:response.data.address.state,
        postcode:response.data.address.postcode,
        country:response.data.address.country,
        city:response.data.address.city,
        district:response.data.address.suburb,
    };
}
export {geoToAddressHandle}
