import React, {useState, createContext, useEffect,useContext} from "react";
import {AuthProvider,AuthContext} from "../../../../../contexts/auth";


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStop,faPause,faPlay,faVideo,faDownload,faLock,faUnlock,faWindowMaximize,faCheck,faSave,faBan,faTrash,faSortAmountDownAlt,faPlus,faEdit,faTimes,faFlag,faThumbtack,faMapMarkerAlt,faUsers,faUserCircle,faGlobe,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'

import {startRealtimeCam,getVideos,getImageCamera,listCameras} from "../../../../../services/apiCameras";

import QuestionInline from "../../../../../components/questioninline";
// import { useMap, useMapEvents,useMapEvent } from 'react-leaflet/hooks'


import {geoToAddressHandle} from "../../../../../libs/geoToAddressHandle.jsx";

import {slugfy} from "../../../../../libs/misc";
import {Base64} from "../../../../../libs/base64";
import {showFullName} from "../../../../../libs/tracker.js";

import { useSnackbar } from 'notistack';

import "./style.css";

import imageEmpty from "./empty.jpg";
// import encBase64 from "crypto-js/enc-base64";


const CameraList = (props) => {

	const { enqueueSnackbar } = useSnackbar();

    const {logout,user,location,global,globalUpdate,action,actionUpdate,view,viewUpdate} = useContext(AuthContext);

	let realtime_delay_resend = 32000;

	let dataDefault = {name:"ef",fields:{address:""}};

	let intervalTime = null;

    const [camData,camDataUpdate] = useState({buffer:null,date:null});

    const [loading,loadingUpdate] = useState(true);

    const [editable,editableUpdate] = useState(false);

    const [viewLocal,viewLocalUpdate] = useState(['list',0]);
    const [list,listUpdate] = useState({});
    const [data,dataUpdate] = useState(dataDefault);
    const [areaView,areaViewUpdate] = useState(false);
    
	const [listVideos,listVideosUpdate] = useState([]);
	
	const [videolistCurrent,videolistCurrentUpdate] = useState(0);
	const [videocurrentElement,videocurrentElementUpdate] = useState('');
	const [statusVideo,statusVideoUpdate] = useState('init');
	
	const [progressvideo_value,progressvideo_valueUpdate] = useState(0);
	const [progressvideo_min,progressvideo_minUpdate] = useState(0);
	const [progressvideo_max,progressvideo_maxUpdate] = useState(100);
	
	const [videoCurrentData,videoCurrentDataUpdate] = useState('');

	
 
    const settingsClose = () =>{
        view.map.settings = undefined;   
        viewUpdate({...view});
    }

    const areaViewHandle = () =>{
        areaViewUpdate(!areaView);
    }

   

    const listCamerasHandle = async (callback) => {

		// let imei = view.map.data.imei;
		// console.log(view.map.data.imei);

		const response = await listCameras(view.map.data);

		
		let body = response.data;
		
	
		if(body.length > 0){
			
		
			

			listUpdate(body);
		}else{
			listUpdate({});			
		}
		// setTimeout(()=>{
			if(callback)callback(body);
		// });
	}
	
	

	


	/* useEffect(()=>{		
		action.map.updateCameras = async (id,data) => {   	        
			const response = await updateCameras(view.map.data,id,data);			
			listCamerasHandle(()=>{
				global.map.markerRefresh();
			});		       
		}	    
	    actionUpdate({ ...action});   
	},[]); */

	const maximizeCamerasHandle = (key) => {

		document.querySelector('.SettingsMap').classList.toggle('fullscreen');
	}

	const selectCamerasHandle = async (key) => {
		
		window.realtime_start = (new Date()).getTime();
		

		clearTimeout(intervalTime);

		viewLocalUpdate(['view',key]);

		

		 camDataUpdate({buffer:null,date:null});
		window.viewLocal = key;
		window.viewLocalType = 'view';

		let position = list[key][0];
		await startRealtimeCam(view.map.data,position);
		await getImageHandle(list); 


		loadingUpdate(false);

	}

	

	useEffect(()=>{				
		if(listVideos.length > 0) playVideoHandle('init');
	},[videolistCurrent]);

	 useEffect(()=>{				
		listCamerasHandle((listResponse)=>{
			// getImageLoop(listResponse);			
		});
	},[]); 

	const previewOnLoadHandle = async (e) =>{				
		setTimeout(async ()=>{
			await getImageHandle(list);
		},250);
	}

	const previewOnErrorHandle = async (e) =>{
		// loadingUpdate(true);
		// await getImageHandle(list);
		camDataUpdate({buffer:imageEmpty,date:""});
	}

	const getImageHandle = async (listResponse)  => {

	
		
		if(window.viewLocalType !== 'view') return;
		if(window.viewLocal === undefined) return;
		
		
		// let name = list[window.viewLocal].name;
		//let name = listResponse[window.viewLocal].name;		
		let position = listResponse[window.viewLocal][0];
		let ip = listResponse[window.viewLocal][1];
		let port = listResponse[window.viewLocal][2];
		
		
		let realtime_dif = (new Date()).getTime()-window.realtime_start;
		if(realtime_dif >= realtime_delay_resend ){
			window.realtime_start = (new Date()).getTime();
			await startRealtimeCam(view.map.data,position);
		}
		


		// await startRealtimeCam(view.map.data,position);

		//const response = await getImageCamera(view.map.data,position);			
		
		//console.log(view.map.data);

		let imei = view.map.data.imei;

		let DateObj = new Date();
		// DateObj.toLocaleString("en-US", {timeZone: "America/Noronha"});

		let year = DateObj.getFullYear();
		let month = ("0" + DateObj.getMonth()+2).slice(-2);
		let day = ("0" + DateObj.getDate()).slice(-2);

		let url = "https://admin.achoord.com/api/v1/accounts/wallrio/onistation/p/files/api/files/?pwd=/idr/"+imei+"/cameras/images/ef/"+year+"/"+month+"/"+day+"/current.jpg&mode=content&timestamp=" + new Date().getTime();
		// let buffer = Base64.encode(response.data);
		
		// const response = await getImageCamera(position);			
		//  const {status,body} = response.data;
		// if( status === 'success' ){
			//let fields = JSON.parse(body[0].fields);
			// camDataUpdate({buffer:"data:image/jpg;base64, "+buffer,date:""});
			camDataUpdate({buffer:url,date:""});
		
		// } 

	}

	const videoHandle = async (data) =>{

		videoCurrentDataUpdate(data);
		//  console.log('==',data);

		viewLocalUpdate(['video',window.viewLocal]);
		window.viewLocalType = 'video';
		// camDataUpdate({buffer:null,date:null});
		// window.viewLocal = key;

		let username = user.username;
		let camName = data.name;

		const response = await getVideos(username,camName,view.map.data);

		let addressAPIvideos = response.request.responseURL;

		let {status,body} = response.data;

		let videos = [];
		for(let key in body.files){
			let item = body.files[key];
			let name = item.name;
			let path = item.path;
			name = name.replace('.mp4','');
			let nameArray = name.split('_');
			let hour = nameArray[1].replace(/-/g,':');
			videos[videos.length] = {
				"title":hour,
				"url":addressAPIvideos,
				"pwd":path,
			};
		}

		// videolistCurrentUpdate(0);

		
		
		listVideosUpdate(...[videos]);
		
/* 		setInterval(() => {
			videoHandle(videoCurrentData);
		}, 25000); */
		

	}

	const videoLoad = (id,num) =>{
		
		if(typeof num === 'undefined') num = videolistCurrent;
		
		if(typeof listVideos[num] === 'undefined') return;
		
		let url = listVideos[num].url;
		let pwd = listVideos[num].pwd;


		let video = document.querySelector('video[name="'+id+'"]');	
		let src = url+'?mode=content&pwd='+pwd;
		video.src = src;
		video.classList.remove('active');
		video.load();

	}

	const videoPlayInit = (id,callback) =>{
		window.videoCurrentId = id;
		let video = document.querySelector('video[name="'+id+'"]');					
		video.onended = function(){			
			clearInterval(window.timeVideoInterval);
			if(callback) callback();
			videoHandle(videoCurrentData);
		}
		video.classList.add('active');
		video.play();
		
		
		clearInterval(window.timeVideoInterval);
		window.timeVideoInterval = setInterval(()=>{
				
			progressvideo_maxUpdate(video.duration??100);
			progressvideo_valueUpdate(video.currentTime);
			
		},150);
		
	}

	const videoPause = (id,callback) =>{		
		let video = document.querySelector('video[name="'+id+'"]');							
		video.pause();
		statusVideoUpdate('paused');
	}

	const videoPlay = (id,callback) =>{		
		let video = document.querySelector('video[name="'+id+'"]');							
		video.play();
		statusVideoUpdate('');
	}

	const videoStop = (id,callback) =>{		
		let video = document.querySelector('video[name="'+id+'"]');							
		video.pause();
        video.currentTime = 0;
		statusVideoUpdate('paused');
	}

	const pauseVideoHandle = () =>{
		videoPause(videocurrentElement);
	}

	const playPosPausedHandle = () =>{
		videoPlay(videocurrentElement);
	}

	const stopVideoHandle = () =>{
		videoStop('main');
		videoStop('second');
	}
	
	const videoSetProgressHandle = (e) =>{	
		let id = window.videoCurrentId;
		let video = document.querySelector('video[name="'+id+'"]');							
		video.currentTime = e.target.value;
	}

	const playVideoHandle = (mode,num) =>{

		if(statusVideo === 'paused'){
			playPosPausedHandle();
			return;
		}

		if(typeof num === 'undefined') num = Number(videolistCurrent);


		videolistCurrentUpdate(num);



		if(mode === 'init'){
			videoLoad('main',num);
			playVideoHandle('a',num);
			statusVideoUpdate('');
		}

		if(mode === 'a'){
			videocurrentElementUpdate('main');

			videoLoad('second',num+1);
			videoPlayInit('main',(()=>{
				playVideoHandle('b',num+1);
			}));
		}

		if(mode === 'b'){
			videocurrentElementUpdate('second');
			//videoLoad('second',num);
			videoLoad('main',num+1);
			videoPlayInit('second',(()=>{
				playVideoHandle('a',num+1);
			})); 
		}


		

		
	}

	const saveVideoHandle = (data) =>{
		//console.log( listVideos[videolistCurrent] );

		let url = listVideos[videolistCurrent].url;
		let pwd = listVideos[videolistCurrent].pwd;

		
		let src = url+'?mode=content&pwd='+pwd;

		window.open(src,'_blank');
		// document.querySelector('.imageSaveBtn').setAttribute( 'download',"video_");
		// document.querySelector('.imageSaveBtn').setAttribute( 'href',src);
	}

	const saveImageHandle = (data) =>{


		let position = list[window.viewLocal][0];
		let ip = list[window.viewLocal][1];
		let port = list[window.viewLocal][2];

		
		let name = slugfy(view.map.data.name)+"_"+slugfy(showFullName(position));
		// name = slugfy(name);

		let dataImage = document.querySelector('.view img').src;
	

		
		// document.querySelector('.imageSaveBtn').setAttribute( 'download',"camera_"+name);
		// document.querySelector('.imageSaveBtn').setAttribute( 'href',dataImage);


		var link = document.createElement("a");
		// If you don't know the name or want to use
		// the webserver default set name = ''
		link.setAttribute('download', "camera_"+name);
		link.href = dataImage;
		document.body.appendChild(link);
		link.click();
		link.remove();
		// window.open(dataImage,'_blank');
		
	}

	

    return (
		<>
			
				
				<div className="line mobile-nobreak tablet-nobreak SettingsMap_header">
					<div className="column left middle">
						<h3>Cameras 

						{ (viewLocal[0] === 'edit' ) && (
							<>						
								&nbsp;<small>(Edit)</small>&nbsp;
							</>
						)}
						
						{ (viewLocal[0] === 'add' ) && (
							<>						
								&nbsp;<small>(Add)</small>&nbsp;
							</>
						)}
						
						<div>
							
						

							<small>{view.map.data.name}</small>

						{ (viewLocal[0] !== 'view' && viewLocal[0] !== 'video' ) && (
							<>
							&nbsp;<small>({Object.keys(list).length})</small>
							</>
						)}


						

						{ (viewLocal[0] === 'view' || viewLocal[0] === 'video' ) && (
							<>						
								&nbsp;<small>({ showFullName(list[viewLocal[1]][0]) })</small>
							</>
						)}

						</div>

						</h3>	
									
					</div>	

					{ (viewLocal[0] === 'video' ) && (
						<>						
										
							{/* <div className="column col1 middle">
								<button className="link  w100 padding" onClick={()=>{ maximizeCamerasHandle() }}>
									<FontAwesomeIcon icon={faWindowMaximize} />
								</button>
							</div>	 */}
							<div className="column col1 middle">
								<a className="link  w100 padding imageSaveBtn" target="_blank" download="camera_image.jpg" onClick={()=>{ saveVideoHandle(list[viewLocal[1]]) }}>
									<FontAwesomeIcon icon={faDownload} />
								</a>
							</div>	
							<div className="column col1 middle closeview">
								<button className="link  w100 padding " onClick={()=>{ viewLocalUpdate(['view',window.viewLocal]);/* window.viewLocal=null; */ }}>
									<FontAwesomeIcon icon={faTimes} />
								</button>
							</div>	
						</>
					)} 
					
					{ (viewLocal[0] === 'view' ) && (
						<>						
							<div className="column col1 middle">
								<a className="link  w100 padding" download="video_image.jpg" onClick={()=>{ videoHandle(list[viewLocal[1]]) }}>
									<FontAwesomeIcon icon={faVideo} />
								</a>
							</div>			
							<div className="column col1 middle">
								<a className="link  w100 padding imageSaveBtn" download="camera_image.jpg" onClick={()=>{ saveImageHandle(list[viewLocal[1]]) }}>
									<FontAwesomeIcon icon={faDownload} />
								</a>
							</div>				
							<div className="column col1 middle">
								<button className="link  w100 padding" onClick={()=>{ maximizeCamerasHandle() }}>
									<FontAwesomeIcon icon={faWindowMaximize} />
								</button>
							</div>	
							<div className="column col1 middle closeview">
								<button className="link  w100 padding " onClick={()=>{ viewLocalUpdate(['list']);window.viewLocal=null;window.viewLocalType = null; }}>
									<FontAwesomeIcon icon={faTimes} />
								</button>
							</div>	
						</>
					)}
					
					{ (viewLocal[0] === 'edit' || viewLocal[0] === 'add') && (
						<>
							
							<div className="column col1 middle">
								<button className="link  w100 padding" onClick={()=>{ viewLocalUpdate(['list']);window.viewLocal=null; }}>
									<FontAwesomeIcon icon={faTimes} />
								</button>
							</div>	
						</>
					)}


					{ viewLocal[0] === 'list' && (
						<>
						
						

							
							<div className="column col1 right middle">
								<button className="link  padding-h-10" onClick={()=>{ settingsClose() }}>
									<FontAwesomeIcon icon={faTimes} />
								</button>
							</div>	
						</>
					)}

					{/*<div className="column col1 middle">
						<button className="link  w100" onClick={()=>{ areaViewHandle() }}>
							<FontAwesomeIcon icon={faSortAmountDownAlt} />
						</button>
					</div>	*/}
										
				</div>	

					<div className="SettingsMap_content">
					
					
					{ (viewLocal[0] === 'video' ) && (
						<>						
							
							<div className="video">
								
								<div className="wrapper">
									<div className="header">
									</div>
									<div className="main">
										{listVideos.length > 0 && (
											<>
												<video name="main" ></video>
												<video name="second" ></video>
											</>
										)}
									</div>
									<div className="footer">
										{listVideos.length < 1 && (
											<>
												<p className="padding-5 center">Carregando...</p>
											</>
										)}

										{listVideos.length > 0 && (
											<>										
												<div className="line mobile-nobreak tablet-nobreak">																		
													<div className="column left middle ">
														
														
														{ ( (statusVideo === 'paused' || statusVideo === 'init') ) && (
															<button className="button -link padding " onClick={()=>{ playVideoHandle('init') }}>
																<FontAwesomeIcon icon={faPlay} />
															</button>
														)}

														{ (statusVideo !== 'paused' && statusVideo !== 'init') && (
															<button className="button -link padding " onClick={()=>{ pauseVideoHandle() }}>
																<FontAwesomeIcon icon={faPause} />
															</button>
														)}
																										
														<button className="button -link padding " onClick={()=>{ stopVideoHandle() }}>
															<FontAwesomeIcon icon={faStop} />
														</button>
														
														
													</div>
													<div className="column right middle col0 ">
														<p className="padding-right-10">
															<small>{progressvideo_value.toFixed(1)}</small>
														</p>
													</div>
													<div className="column right middle ">
														<input className="w100" type="range" id="progressvideo" name="progressvideo" min={progressvideo_min} max={progressvideo_max} value={progressvideo_value} onChange={(e)=>{ videoSetProgressHandle(e); }} />
													</div>
													
													<div className="column right middle padding-h-10 col0">
														<select name="videolist" value={videolistCurrent} onChange={(e)=>{ let value = e.target.options[e.target.selectedIndex].getAttribute('value');   videolistCurrentUpdate(value); }} >
															{listVideos.map((key,index) => {
																return (
																	<option key={index} value={index}>{listVideos[index].title}</option>
																)
															})}
														</select>
														<select name="videolist" value={videolistCurrent} onChange={(e)=>{ let value = e.target.options[e.target.selectedIndex].getAttribute('value');   videolistCurrentUpdate(value); }} >
															{listVideos.map((key,index) => {
																return (
																	<option key={index} value={index}>{listVideos[index].title}</option>
																)
															})}
														</select>
													</div>
												</div>
											</>
										)}

									</div>
								</div>
								

								
							</div>	
						</>
					)}


					
					{ (viewLocal[0] === 'view' ) && (
						<>						
							
							<div className="view">
								
								
								{ (loading === true) && (
									<div className="line mobile-nobreak tablet-nobreak h100 previewloading">											
										<div className="column center middle">
											<span className="loading"></span>
										</div>										
									</div>
								)}

								{ (loading === false) && (
									<img className="preview" onLoad={previewOnLoadHandle} onError={previewOnErrorHandle} src={ (camData.buffer?camData.buffer:imageEmpty) } ></img>
								)}

								<span className="date">{ camData.date?(new Date(camData.date*1000).toLocaleString()) :""  }</span>
							</div>	
						</>
					)}



					{ viewLocal[0] === 'list' && (
						<>
						{Object.keys(list).length < 1 &&(
							<p className="padding-h-20">Nenhuma camera</p>
						) }

						<div className="listing">
							<ul>
							{Object.keys(list).map((key,index) => {

								

							
								return (
									<li key={key}>
										
										<div className="line mobile-nobreak tablet-nobreak">											
											<div className="column left middle">
												<a onClick={()=>{ selectCamerasHandle(key)}} >{ showFullName(list[key][0]) }</a>
											</div>										
										</div>	
										
									</li>
								)
							})}
							</ul>
						</div>
						</>
					)}

					</div>
				

				
		</>
    );
}

export {CameraList}
