import React, {useState, createContext, useEffect,useContext} from "react";

import {useNavigate,useLocation} from "react-router-dom";


import {AuthProvider,AuthContext} from "../../../contexts/auth";
import {loopDevices,getPositions} from "./basic.jsx";
import {splitroute} from "./splitroute.jsx";

import {seqArray} from "../../../libs/seqArray.jsx";



const SetupDevices =  (devicesMap,devicesUpdateMap,callback) => {

	const {logout,user,global,globalUpdate} = useContext(AuthContext);
    let location = useLocation();
 	const [devices,devicesUpdate] = useState({});
    const [multiPolyline,multiPolylineUpdate] = useState([]);
    

    global.map.selectRoute = () => { 
        devicesUpdateMap([]);
        
        for(let key in devices){
            let positionsOri = devices[key].positionsOri;  
            let positions = devices[key].positions;  
            let data = devices[key].positionsAll;  
            let splitrouteArray = devices[key].splitrouteArray;  
            
            devices[key].optionsMult = [];
            ajustRoute(devices,parseInt(key),positionsOri,splitrouteArray,data)
        }        
        setTimeout(()=>{

            
            // window.requestAnimationFrame(()=>{
                devicesUpdateMap(devices);
            // });
        });
    }


    const ajustRoute = (devices,key,positions,splitrouteArray,routes,mode) => {


        let index = 0;
        for(let keya in splitrouteArray.limited){
            if(splitrouteArray.limited[keya] === null)continue;
            
            for(let keyb in splitrouteArray.limited[keya]){             
                devices[key].points[devices[key].points.length] = splitrouteArray.limiteddata[keya][keyb];
                devices[key].points[devices[key].points.length-1].geopos = [parseFloat(splitrouteArray.limiteddata[keya][keyb].latitude),parseFloat(splitrouteArray.limiteddata[keya][keyb].longitude)];               
            }

            devices[key].optionsMult[devices[key].optionsMult.length] = {active:false, color: devices[key].color,opacity:0.2,weight:2,dashArray:'4,4',opacitytag:0.2};            
            index++;
        }

        for(let keya in devices[key].positiondata){
            for(let keyb in devices[key].positiondata[keya]){
                devices[key].positiondata[keya][keyb].geopos = [parseFloat(devices[key].positiondata[keya][keyb].latitude),parseFloat(devices[key].positiondata[keya][keyb].longitude)];                                                                   
            }            
        }

        if(global.map.positionCurrent === null){
            devices[key].optionsMult[devices[key].optionsMult.length-1] = {active:true, color: devices[key].color,opacity:0.7,weight:2,dashArray:'0,0',opacitytag:1};        
        }else{
            
            devices[key].optionsMult[global.map.positionCurrent-1] = {active:true, color: devices[key].color,opacity:0.7,weight:2,dashArray:'0,0',opacitytag:1};        
        }
  
        multiPolylineUpdate([positions]);
    }



    const loopDevicesHandle = (runcount,callbackDevice,loopMode) => {
                

        // bloqueia utilização quando a página não estiver no mapa
        if(document.querySelector('[route="/dashboard/map"]') === null){          
            globalUpdate((glob)=>{                      
                glob.map.date = null;
                
                return glob;
            });
            return;        
        }
        


        loopDevices((devices)=>{

            if(runcount === 0) devicesUpdate(devices);                  
            runcount++;

            
            seqArray({
                array:devices,
                index:0,
                variables:{dates:[]},
                step:(next,value,key,array,variables)=>{
                
                    // bloqueia utilização quando a página não estiver no mapa
                    if(document.querySelector('[route="/dashboard/map"]') === null){          
                        globalUpdate((glob)=>{                      
                            glob.map.date = null;
                            return glob;
                        });
                        return;        
                    }

                    getPositions(devices[key].imei,global.map.date).then(([imei,positions,routes])=>{

                        let splitrouteArray = splitroute(imei,positions,routes,global.map.positionCurrent);
                        
                        devices[key].positionsOri = positions;
                        devices[key].positionsAll = routes;
                        devices[key].splitrouteArray = splitrouteArray;
                        devices[key].positions = splitrouteArray.limited;
                        devices[key].optionsMult = [];
                        devices[key].points = [];
                        devices[key].positiondata = splitrouteArray.limiteddata;



                        ajustRoute(devices,key,positions,splitrouteArray,routes,'init');

                        next();                       
                    });


                },
                finish:(variables)=>{
                    if(callback)callback(devices); 
                    

                    if(loopMode !== false){
                        setTimeout(()=>{
                            loopDevicesHandle(runcount,callbackDevice);                   
                        },15000);
                    }
                },
            });


        });

    }


   
    // requisita os ŕastreadores e datas ao servidor
    useEffect(()=>{
        // console.log(global.map.date);
        // if(global.map.date === null)
        loopDevicesHandle(0,()=>{});        
    },[]);
   

    // na mudança da data, requisita novamente os ŕastreadores e datas ao servidor
    const refreshHandle = () => {
        loopDevicesHandle(0,()=>{},false);                        
    }

  

    //  atualiza pontos da bifuracao
    useEffect(() => {             
        let count = 0;
        for(let key in devices){

            let cont = true;
            for(let keya in devices[key].positions){
                for(let keyb in devices[key].positions[keya]){
                    if( JSON.stringify(devices[key].positions[keya][keyb]) === '[0,0]' ){
                        cont = false;
                    }
                }
            }

            if(cont === true){
                if(devices[key].positions !== undefined)
            	if( Object.keys(devices[key].positions).length > count ){
            		count = Object.keys(devices[key].positions).length;
            	}
            }
        }
        global.map.positionCount = count;  
        global.map.refresh = refreshHandle;    



        globalUpdate({...global});      
    },[multiPolyline])

}

export {SetupDevices}