import React, {useState, createContext, useEffect,useContext} from "react";
import {AuthProvider,AuthContext} from "../../../../contexts/auth";

import { MapContainer, TileLayer, useMap,Marker,Popup ,Polyline,Tooltip,Circle} from 'react-leaflet'

import L from "leaflet";

import {setMarker,listMarker,deleteMarker} from "../../../../services/apiMarker";

import {PopupDefault} from "../PopupDefault";

// import {diffDateToHuman,geoTodistance} from "../../../../libs/misc";

// import markerDefault from "./icons/marker/default.png";

import markerIcon_default from './icons/marker/default.png';
import markerIcon_home from './icons/marker/home.png';
import markerIcon_hospital from './icons/marker/hospital.png';
import markerIcon_gasstation from './icons/marker/gasstation.png';
import markerIcon_park from './icons/marker/park.png';
import markerIcon_restaurant from './icons/marker/restaurant.png';
import markerIcon_transport from './icons/marker/transport.png';
import markerIcon_work from './icons/marker/work.png';


import {geoToAddressHandle} from "../../../../libs/geoToAddressHandle.jsx";

import { useSnackbar } from 'notistack';

const DrawMarker = (props) => {

    const { enqueueSnackbar } = useSnackbar();

    const [address,addressUpdate] = useState('');

    const markerIcon = {
        'default':markerIcon_default,
        'home':markerIcon_home,
        'hospital':markerIcon_hospital,
        'gasstation':markerIcon_gasstation,
        'park':markerIcon_park,
        'restaurant':markerIcon_restaurant,
        'transport':markerIcon_transport,
        'work':markerIcon_work,
    };

    const {logout,user,location,global,globalUpdate,action,actionUpdate,view,viewUpdate} = useContext(AuthContext);

    const [list,listUpdate] = useState({});

	const listMarkerHandle = async (callback) => {
        const response = await listMarker('');
        

        let status = response.data.status;
        let body = response.data.body;

        if(status === 'success'){
            listUpdate(body);
        }else{
            listUpdate({});

        }
        // setTimeout(()=>{
            if(callback)callback();
        // });
    }

    useEffect(()=>{

        // enqueueSnackbar('Carregando marcadores', { variant: "info"});

        listMarkerHandle();
        global.map.markerRefresh = () => {
            listMarkerHandle();
        };
        globalUpdate(global); 

    },[]);

    return (
		<>
			{Object.keys(list).map((key,index) => {

                let icon = list[key].icon;
                let title = list[key].title;
                let subtitle = list[key].description;
                let latitude = list[key].latitude;
                let longitude = list[key].longitude;
                let position = [list[key].latitude,list[key].longitude];
                let fenceenabled = list[key].fenceenabled;
                let fencesize = list[key].fencesize;

                let htmlDeviceIcon = '<img  src="'+markerIcon[icon]+'">';                    

                var DeviceIcon = L.divIcon({
                    iconSize: [40, 40],
                    iconAnchor: [20, 20],
                    className: 'markericon-'+key,
                    html: htmlDeviceIcon
                });

              
                let pathOptions = {color: 'blue',opacity:0.7,weight:1,dashArray:'4,4'};

                return (
                    <div key={key}>
                    <Marker

                    eventHandlers={{
                        click: (e) => {

                            /*addressUpdate('');

                            let latitude = e.target.getLatLng().lat;
                            let longitude = e.target.getLatLng().lng;

                            let geoToAddressResponse = geoToAddressHandle(latitude,longitude);

                            geoToAddressResponse.then((response)=>{
                                let locateString = response.street+', '+response.city+', '+response.state+' '+response.postcode+' '+response.country
                                addressUpdate(locateString);                               
                            })*/

                        },
                        dragend: (e) => {

                            let latitude = e.target.getLatLng().lat;
                            let longitude = e.target.getLatLng().lng;

                            let geoToAddressResponse = geoToAddressHandle(latitude,longitude);

                            geoToAddressResponse.then((response)=>{
                                
                                let locateString = '';
                                if(typeof response.street!== 'undefined')
                                locateString = response.street+', '+response.city+', '+response.state+' '+response.postcode+' '+response.country
                                // addressUpdate(locateString);                               


                                list[key].latitude = latitude;
                                list[key].longitude = longitude;
                                
                                let data = {
                                    latitude: list[key].latitude,
                                    longitude: list[key].longitude,
                                    description: locateString,
                                };
                                action.map.updateMarker(list[key].id,data);
                                
                            })
                        },
                      }}

                    draggable={view.map.settings==='marker'?true:false}
                    icon={DeviceIcon} 
                    position={position}>
                        <Popup
                        
                          >

                            <PopupDefault 
                                index={key}                               
                                title={title} 
                                // address={ address } 
                                subtitle={subtitle} 
                                position={[latitude,longitude]} 
                                markerEdit={global.map.settingsView==='marker'?true:false} 
                            />
                                                                    
                        </Popup>

                        {fenceenabled === "true" && (
                            <Circle center={position} radius={fencesize} pathOptions={pathOptions} />
                        )}

                    </Marker>
                </div>
                )
            })}
		</>
    );
}

export {DrawMarker}
