import React, {useState, createContext, useEffect,useContext,useCallback} from "react";
import {AuthProvider,AuthContext} from "../../../contexts/auth";

import {setFavorite,listFavorite,deleteFavorite} from "../../../services/apiFavorite";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes,faStar,faChevronLeft,faTrash,faBars,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'
import {faStar as farStar} from '@fortawesome/free-regular-svg-icons'

import { useSnackbar } from 'notistack';

import DatePicker,{registerLocale,setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('pt-BR', ptBR)


const Component = (props) => {

	const { enqueueSnackbar } = useSnackbar();

	let dateToday = (new Date());
  	let dateTodayString = dateToday.getFullYear()+'/'+ ("0" + (dateToday.getMonth()+1)).slice(-2) +'/'+("0" + dateToday.getDate()).slice(-2);


	const {logout,user,location,global,globalUpdate} = useContext(AuthContext);

	const [dateslist,dateslistUpdate] = useState([]);

	const [startDate, startDateUpdate] = useState(new Date());
  	const [endDate, setEndDate] = useState(null);

    const onChange = (dates) => {

    	enqueueSnackbar('Alterando rota.', { variant: "info"});

	  	let currentDate = dates.getFullYear()+'/'+ ("0" + (dates.getMonth()+1)).slice(-2) +'/'+("0" + dates.getDate()).slice(-2);	  	
	  	globalUpdate((glob)=>{  		
			glob.map.date = currentDate;
			return glob;
	  	});
	  	globalUpdate({ ...global,"update":(new Date()).getTime() });

	  	global.map.positionSelect('reset',()=>{
			global.map.selectRoute();
		});
		
		props.handleMenu('');
		global.map.refresh();

	};

  	const selectTodayHandle = () =>{	

  		enqueueSnackbar('Alterando rota.', { variant: "info"});

  		let dates = (new Date());
  		let currentDate = dates.getFullYear()+'/'+ ("0" + (dates.getMonth()+1)).slice(-2) +'/'+("0" + dates.getDate()).slice(-2);
		globalUpdate((glob)=>{  		  			
			glob.map.date = 'today';
			// glob.map.date = currentDate;
			return glob;
	  	});
	  	globalUpdate({ ...global,"update":(new Date()).getTime() });

	  	global.map.positionSelect('reset',()=>{
			global.map.selectRoute();
		});

	  	props.handleMenu('');
	  	global.map.refresh();


	}

	// cria data
	useEffect(() => {	  
		let datesForm = [];
		for(let key in global.map.alldates){
			let date = global.map.alldates[key];
			date = date.replace(/-/g,'/');
			let dateUniq = (new Date(date));
			// datesForm[key] = (new Date(date));

			if(dateUniq.getMonth()+1 === dateToday.getMonth()+1){
				datesForm[key] = (new Date(date));
			}
			// monthBeingViewed.getMonth()+1,monthBeingViewed.getFullYear()

		}	  
		dateslistUpdate(datesForm);

		if(global.map.alldates && Object.keys(global.map.alldates).length > 0){			
			if(document.querySelector('.layout_dashboard .header .layout_header .headerarea .date')){				
				document.querySelector('.layout_dashboard .header .layout_header .headerarea .date').classList.remove('disabled');
				document.querySelector('.layout_dashboard .header .layout_header .headerarea .date').removeAttribute('disabled');
			}
		}

		if(global.map.date === 'today'){
			let dates = (new Date());
	  		global.map.date = dates.getFullYear()+'/'+ ("0" + (dates.getMonth()+1)).slice(-2) +'/'+("0" + dates.getDate()).slice(-2);
		}

		startDateUpdate( (new Date(global.map.date)) );
		handleMonthChange( (new Date(global.map.date)) );

	},[global.map.alldates]);

	const handleMonthChange = (monthBeingViewed) =>{
		// console.log(monthBeingViewed.getMonth()+1,monthBeingViewed.getFullYear());

		let previousMonth = monthBeingViewed.getMonth()+1; 
		let nextMonth = monthBeingViewed.getMonth()+2===13?1:monthBeingViewed.getMonth()+2;
		let currentMonth = monthBeingViewed.getMonth();

		let datesForm = [];
		for(let key in global.map.alldates){
			let date = global.map.alldates[key];
			date = date.replace(/-/g,'/');
			let dateUniq = (new Date(date));
	
			if(
				dateUniq.getMonth()+1 === previousMonth
				|| dateUniq.getMonth()+1 === currentMonth
				|| dateUniq.getMonth()+1 === nextMonth
			){
				datesForm[key] = (new Date(date));
			}

		}	  
		dateslistUpdate(datesForm);

	}

	const favoritedateHandle = () =>{	
		listFavoriteHandle();		
		props.handleMenu('favoritedate');
	}

	const favoritesBackHandle = () =>{	
		// listFavoriteHandle(()=>{
			showFavorited();	
		// });
		props.handleMenu('date');
	}

	const favoritedateSet = async () =>{	
		let title = window.prompt("insira o titulo");		
		if(title == null) return;

		const response = await setFavorite({
			fields:{"date":global.map.date},
			name:title
		});
		
		listFavoriteHandle(()=>{
			showFavorited();	
		});
	}


	const [favorited,favoritedUpdate] = useState(false);
	const [favorites,favoritesUpdate] = useState([]);

	const showFavorited = async () => {

		setTimeout(()=>{

			// console.log('fff');

			let dayAll = document.querySelectorAll('.react-datepicker__day');
			// console.log('--',dayAll.length);
			for (var i = 0; i < dayAll.length; i++) {
				dayAll[i].classList.remove('favorited');
			}
			

			for(let key in favorites){
				let date = JSON.parse(favorites[key].fields).date;
				date = date.replace(/\//g,'-');
				date = date.split('-');
				let day = date[2];
				delete date[2];
				date = date.filter(n => n);
				date = date.join('-')		

				if(document.querySelector('[aria-label="month  '+date+'"] .react-datepicker__day--0'+day))
				document.querySelector('[aria-label="month  '+date+'"] .react-datepicker__day--0'+day).classList.add('favorited');
			}
		},500);

	}

	const listFavoriteHandle = async (callback) => {
		const response = await listFavorite('');

		let status = response.data.status;
		let body = response.data.body;

		

		if(status === 'success'){
			favoritesUpdate(body);
		}else{
			favoritesUpdate({});
		}
		// setTimeout(()=>{
			if(callback)callback();
		// });
	}

	const deleteFavoriteHandle = async (id) => {

		let input = window.confirm("Deseja apagar?");		
		if(input == false) return;
	
		const response = await deleteFavorite(id);

		let status = response.data.status;
		let body = response.data.body;
	
		listFavoriteHandle(()=>{
			// showFavorited();	
		});

		console.log(id);

	}


	const selectFavoriteHandle = async (date) => {

		enqueueSnackbar('Alterando rota.', { variant: "info"});
		
		globalUpdate((glob)=>{  		
			glob.map.date = date;
			return glob;
	  	});
	  	globalUpdate({ ...global,"update":(new Date()).getTime() });
		props.handleMenu('');
		global.map.refresh();
	}


	useEffect(() => {	  
		favoritedUpdate(false);
		for(let key in favorites){			
			if( global.map.date === favorites[key].date){
				favoritedUpdate(true);
			}
		}
	},[global.map.date,favorites]);


	const ajustArrowCalendar = () =>{
		setTimeout(()=>{
			if(document.querySelector('.react-datepicker__navigation--previous'))
			document.querySelector('.react-datepicker__navigation--previous').onclick = function(){
				
				ajustArrowCalendar();
				showFavorited();											
			}
		},500)
		
		setTimeout(()=>{
			if(document.querySelector('.react-datepicker__navigation-icon--next'))
			document.querySelector('.react-datepicker__navigation--next').onclick = function(){
				
				ajustArrowCalendar();
				showFavorited();											
			}
		},500)
	}

	useEffect(() => {
		// console.log('---',props.menuHeaderView);
		

	// useEffect(() => {
	// console.log('==',props.menuHeaderView);	
		if(props.menuHeaderView === 'date'){
			
			listFavoriteHandle(()=>{
				showFavorited();	
			});
			ajustArrowCalendar();
		}
	},[props.menuHeaderView]);
	
	useEffect(() => {
		showFavorited();	
	},[favorites]);
	// },[global.map.alldates,favorites]);



	return (
		<>
			<div className="headerItem date">
				<div className="">

					<div className="line mobile-nobreak headerTitle">
						<div className="column middle left">
							
				    		<button className="link  padding" onClick={()=>{props.handleMenu('');}}>
					    			<FontAwesomeIcon icon={faTimes} />
					    		</button>
				    		
				    	</div>
				    	<div className="column middle right">					    		
				    	</div>
				    	<div className="col2 column middle right">
				    		<button className="link  padding" >
				    			Calendário
				    		</button>
				    	</div>
				    </div>

				    {/*<Calendar isDisabled={isDisabled} startOfWeek={7} value={value} onChange={onChangeCalendar} hideAdjacentDates />*/}

					{<DatePicker
				      selected={startDate}
				      onChange={onChange}
				      startDate={startDate}
				      endDate={endDate}
				      includeDates={dateslist}	
				      locale="pt-BR"	      
				      inline
				      onMonthChange={handleMonthChange}
				    />}
				    <div className="line mobile-nobreak padding">
						<div className="column middle left">
				    		<button className="link  -primary padding" onClick={()=>{selectTodayHandle()}}>Hoje</button>
				    	</div>
				    	<div className="column middle right">
				    		<button className="link  padding" onClick={()=>{favoritedateHandle()}}>Favoritos ({Object.keys(favorites).length})</button>
				    	</div>
				    	<div className="col2 column middle center">
				    		{favorited===true?(
					    		<button className="link  padding " >
					    			<FontAwesomeIcon icon={faStar} />
					    		</button>
				    		):(
					    		<button className="link  padding" onClick={()=>{favoritedateSet()}}>
					    			<FontAwesomeIcon icon={farStar} />
					    		</button>
				    		)}
				    	</div>
				    </div>
				</div>
			</div>

			<div className="headerItem favoritedate">
				<div className="line mobile-nobreak headerTitle">
					<div className="column middle left">
						<button className="link  padding" onClick={()=>{favoritesBackHandle();}}>
			    			<FontAwesomeIcon icon={faChevronLeft} /> 
			    		</button>
			    		
			    		
			    	</div>
			    	<div className="column middle right">					    		
			    	</div>
			    	<div className="col2 column middle right">
			    		<button className="link padding" >
			    			Favoritos
			    		</button>
			    	</div>
			    </div>

			    {Object.keys(favorites).length < 1 && (
			    	<div>
			    		<p>Sem registro</p>
			    	</div>
			    )}

				<ul>

					{Object.keys(favorites).map((key,index)=>{

				   		return (
				   			
			   				<li key={key}>
			   					<div className="line mobile-nobreak ">
									<div className="column middle left">
										<button className="link -primary padding w100 left" onClick={()=>{selectFavoriteHandle(JSON.parse(favorites[key].fields).date);}} >
											<label className="favoritedate-title">{ favorites[key].name }</label>
											<span className="favoritedate-date">{ JSON.parse(favorites[key].fields).date }</span>
										</button>
									</div>
									<div className="column col0 middle left">
										<button className="link -primary padding" onClick={()=>{deleteFavoriteHandle(favorites[key]._id);}}><FontAwesomeIcon icon={faTrash} /></button>
									</div>
								</div>									
							</li>					   			
			   			
				   		)
				   })}

					
				</ul>
			</div>
				
		</>
	);
};


export default Component;