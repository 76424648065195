import React, {useState, createContext, useEffect,useContext,useCallback} from "react";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUserCircle} from '@fortawesome/free-solid-svg-icons'

import { useSnackbar } from 'notistack';

import {md5} from "../../../libs/md5";

import {AuthProvider,AuthContext} from "../../../contexts/auth";

import {setUser as setUserSession} from "../../../services/apiSession";

const Component = () => {

	const { enqueueSnackbar } = useSnackbar();

	const {user,setUser,logout,getUserHandle} = useContext(AuthContext);

	const [loading,loadingUpdate] = useState(false);
	const [fields,fieldsUpdate] = useState({...user});
	const [passwordInput,passwordInputUpdate] = useState(false);
	

	const saveHandle = async (e) =>{
		loadingUpdate(true);
		e.target.classList.add('loading');

		if(fields.password)
		fields.password = md5(fields.password);


        const response = await setUserSession(fields);
        e.target.classList.remove('loading');
		loadingUpdate(false);

		
		

			
		// fields.password = '';
		// fieldsUpdate({...fields});
		passwordInputUpdate(false);

		let status = response.data.status;
		if(status === 'success'){
			setUser({...fields});
			enqueueSnackbar('Salvo com sucesso', { variant: "success"});
		}else{
	
			getUserHandle(fields.id,(bodyUser)=>{				
				fieldsUpdate({...bodyUser});
			});
			enqueueSnackbar('Não foi possivel salvar', { variant: "error"});
		}

	}

	return (
		<div className="page h100 --page padding sticky">
				
				<div className={"wrapper "+(loading===true?'disabled':'')}>
					<div className="header">
						{/*<div className="line">
							<div className="column right">
								<button className="button -primary" onClick={(e)=>{ saveHandle(e) }}>Salvar</button>
							</div>
						</div>*/}
					</div>
					<div className="main center">

					
						<button className="link -primary  padding-top-20 padding-bottom-20" style={{"fontSize":"60px"}}>
							<FontAwesomeIcon icon={faUserCircle} />
						</button>

						{/*<div className="input-float flatmodel disabled">
						      <input type="text" key={fields.id} defaultValue={fields.id} readOnly  />
						      <label >ID</label>
						</div>*/}

						<div className="input-float flatmodel" title={fields.id}>
						      <input type="text" key={fields.name} defaultValue={fields.name} onBlur={(e)=>{ fields.name = e.target.value; fieldsUpdate({...fields}); }} />
						      <label >Nome</label>
						</div>

						<div className="input-float flatmodel">
						      <input type="text" key={fields.email} defaultValue={fields.email} onBlur={(e)=>{ fields.email = e.target.value; fieldsUpdate({...fields}); }} />
						      <label >Email</label>
						</div>


						<div className="input-float flatmodel ">
						      <input type="text" key={fields.username} defaultValue={fields.username}  onBlur={(e)=>{ fields.username = e.target.value; fieldsUpdate({...fields}); }} />
						      <label >Nome de usuário</label>
						</div>

						<div className={passwordInput?'':'hide'}>

							<div className="input-float flatmodel">
							      <input type="text" key={fields.password} onBlur={(e)=>{ fields.password = e.target.value; fieldsUpdate({...fields}); }} />
							      <label >Senha</label>
							</div>

						</div>


						<div className="line mobile-nobreak tablet-nobreak margin-top-20">
							<div className="column left middle">
								<button className="link -primary" onClick={(e)=>{ passwordInputUpdate(!passwordInput) }}>Alterar senha</button>
							</div>
							<div className="column col6 middle right">
								<button className="button -primary" onClick={(e)=>{ saveHandle(e) }}>Salvar</button>
							</div>
						</div>

						
						

					</div>
					<div className="footer">
						{/*<div className="line">
							<div className="column center">
								<button className="link -primary">Salvar</button>
							</div>
						</div>*/}
					</div>
				</div>

		</div>

	);
};


export default Component;
