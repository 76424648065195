import { env } from '../.env.js';
import axios from "axios";

// import {getUniqueID} from "../libs/getuniqueid.js";
// import  {md5} from "../libs/md5";	

export const api = axios.create({
	baseURL: env.AchoordURL
});


// session 
/*export const setFavorite = async (parameters) => {
	let date = parameters['date'];
	let title = parameters['title'];
	let description = parameters['description'];
	
	var bodyFormData = new FormData();

	for(let key in parameters){
		bodyFormData.append(key, parameters[key]);
	}
	// let data = parameters;

	return api.post("/favorite/setfavorite/",parameters,{
	  headers: {
	  	'account': window.username,
	  		// "Content-Type": "multipart/form-data"
	    // 'hashuser': hashuser
	  }
	});

}*/

export const geoToAddress = async (latitude,longitude) => {
	
	return api.get("/misc/geotoaddress",{
	  headers: {
	  	'account': window.username,
	  	'latitude': latitude,
	  	'longitude': longitude,
	  		// "Content-Type": "multipart/form-data"
	    // 'hashuser': hashuser
	  }
	});

}


export const passwordRecovery = async (email) => {
	

	// https://admin.achoord.com/api/v1/accounts/wallrio/onistation/p/users/api/users/email:outro@wallrio.com/
// https://admin.achoord.com/api/v1/accounts/wallrio/onistation/p/users/api/users/{get.userid}/passwordrecovery

	return api.get("/misc/set-password-recovery?email="+email,/*{
	  headers: {
	  	'account': window.username,
	  	'latitude': latitude,
	  	'longitude': longitude
	  }
	}*/);

}




export const sendValidation = async (email,name,validation) => {
	

	// https://admin.achoord.com/api/v1/accounts/wallrio/onistation/p/users/api/users/email:outro@wallrio.com/
// https://admin.achoord.com/api/v1/accounts/wallrio/onistation/p/users/api/users/{get.userid}/passwordrecovery

	return api.get("/misc/send-validation?email="+email+"&name="+name+"&validation="+validation,/*{
	  headers: {
	  	'account': window.username,
	  	'latitude': latitude,
	  	'longitude': longitude
	  }
	}*/);

}


