

import {listDevices} from "../../../services/apiTrackers";
import {getRoutes} from "../../../services/apiRoutes";

const loopDevices =  async (callback) => {

    const response = await listDevices('');

    let status = response.data.status;
    let body = response.data.body;

    if(status === 'success'){

        let devices = {};
        for(let key in response.data.body){
            if( response.data.body[key].enabled === false || response.data.body[key].enabled === 'false' ) continue;
            devices[Object.keys(devices).length] = response.data.body[key];
        }

        // console.log(devices);
        if(callback)callback(devices);
    }
}

export {loopDevices}


const getPositions = async (imei,date) => {

        if(date === 'today'){
            let dates = (new Date());
            date = dates.getFullYear()+'/'+ ("0" + (dates.getMonth()+1)).slice(-2) +'/'+("0" + dates.getDate()).slice(-2);
        }

        const response = await getRoutes(imei,date);

        let status = response.data.status;
        let count = response.data.count;
        let body = response.data.body;

        let positions = [];
        let data = [];

        if(status === 'success'){

            for(let key in response.data.body){
                let latitude = response.data.body[key].latitude;
                let longitude = response.data.body[key].longitude;

                positions[positions.length] = [latitude,longitude];
                data[data.length] = response.data.body[key];
            }


            // multiPolylineUpdate([positions]);

          
            // console.log(positions);
        }

        return [imei,positions,data];
    }


export {getPositions}

