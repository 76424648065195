import React, {useState, createContext, useEffect} from "react";

import {useNavigate,useLocation} from "react-router-dom";

import {getUser,createSession,checkSession,checkSessionDevice,disconnectSessionDevice} from "../services/apiSession";
// import {botList} from "../services/apiBot";
// import {orderList} from "../services/apiOrders";

// import {checkUpServer} from "../services/apiGlobal";

// import {requestPut} from "../services/apiAccount";

// import {notificationList} from "../services/apiNotifications";

import {Storage} from "../libs/Storage.jsx";

import {objToArray,sortKey} from "../libs/misc";

import infoapp from "../../package.json";

export const AuthContext = createContext();



export const AuthProvider = ({children}) => {
	


	const [byformlogon,byformlogonUpdate] = useState(null);
	const [view,viewUpdate] = useState({
		"map":{
			trackersbar:true,
		}
	});

	const [header,headerUpdate] = useState({
		
	});
	
	const [action,actionUpdate] = useState({"map":{}});

	const navigate = useNavigate();
	const location = useLocation();
	const [user,setUser] = useState(null);
	const [global,globalUpdate] = useState({
		update:0,
		map:{
			
			devices:0,
			"settingsView":null,
			"positionCurrent":null,
			imagecurrent:"simple",
			image:{
				"simple":"https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",		
				"complete":"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
				"dark":"https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"		
			},			
			date:null
		}
	});



	useEffect(()=>{

		let time;
		
		checkSessionDevice().then( async (response)=>{
			/*let response = {
				"data":{
					"status":'success',
					"content":{
						"username":"wallrio"
					}
				}
			};*/

			


			var status = response.data.status;
			var body = response.data.body[0];
				
		
			if( status === 'success' ) {

				
				

				
				// const responseUser = await getUser(userid);
				const responseUser = await getUser(body.userid);
				

				let statusUser = responseUser.data.status;
				let bodyUser = responseUser.data.body[0];

				// window.username = body.username;
				window.username = bodyUser.username;
				window.token = bodyUser.token;
				

				if(bodyUser.validation !== 'ok'){
					global.validation = bodyUser.validation;
					globalUpdate({...global});
				}


				setUser(bodyUser);
							
				


				
				
				
				if(location.pathname === '/' || location.pathname === '/login')
					navigate('/dashboard/home');
				else
					navigate(location.pathname);
			}else{



				if(location.pathname === '/' || location.pathname === '/login')
					navigate('/login');
				else
					navigate(location.pathname);

			}

		}).catch((response)=>{

			if(location.pathname === '/' || location.pathname === '/login')
					navigate('/login');
				else
					navigate(location.pathname);

		});

		
		return ()=>{
			clearInterval(time);
		}
	},[byformlogon]);




	const getUserHandle = async (userid,callback) => {
		const responseUser = await getUser(userid);
		let statusUser = responseUser.data.status;
		let bodyUser = responseUser.data.body[0];	
		if(callback)callback(bodyUser);
	}


	const check = async (email) => {
		const response = await checkSession(email);
		return response;
	}

	const checkDevice = async (email) => {
		const response = await checkSessionDevice(email);
		return response;
	}

	const login = async (email, password,callback) => {

		createSession(email,password).then((response)=>{
			
			const status = response.data.status;
			if(status === 'success'){
				const token = response.data.content


				const loggedUser = {			
					email,
					token
				};

				byformlogonUpdate(loggedUser);
				setUser(loggedUser);

				navigate("/dashboard/home");
			}

			if(callback)callback(response);
			
		}).catch((response)=>{
			if(callback)callback();
		});



		


	}

	const logout = () => {

		Storage().remove('onistation-map-date');
		Storage().remove('onistation-map-zoom');
		Storage().remove('onistation-map-position');

		disconnectSessionDevice();
		byformlogonUpdate(null);
		setUser(null);

		navigate("/login");
	}










	return (
		<AuthContext.Provider value={{ authenticated: !!user, user,setUser, login, logout,location,infoapp,global,globalUpdate,action,actionUpdate,view,viewUpdate,header,headerUpdate ,getUserHandle}}>						
			{children}			
		</AuthContext.Provider>
	)
}