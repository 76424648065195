import React, {useState, createContext, useEffect,useContext,useCallback} from "react";
import {AuthProvider,AuthContext} from "../../../contexts/auth";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck,faLocationArrow,faTrash,faEdit,faPlus,faSortAmountDownAlt,faMapMarkerAlt,faUsers,faUserCircle,faGlobe,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'

import {listDevices} from "../../../services/apiTrackers";

import QuestionInline from "../../../components/questioninline";



const Component = () => {

	const {user,header,headerUpdate} = useContext(AuthContext);

	




	const [devicesLoading,devicesLoadingUpdate] = useState(true);
	const [devices,devicesUpdate] = useState([]);
	const [detailShow,detailShowUpdate] = useState(false);

	const HandleGetDevices = async (mode) => {

		// devicesUpdate([]);

		if(document.querySelector('[route="/dashboard/home"]') === null){                      
            return;        
        }
        

		const response = await listDevices('');

		let status = response.data.status;
		let body = response.data.body;

		if(status === 'success'){
			devicesUpdate(response.data.body);
		}

		devicesLoadingUpdate(false);

		if(mode === 'uniquerequest') {			
			return
		}

		setTimeout(()=>{
			HandleGetDevices();
		},11000);
		return response;
	}

	useEffect(()=>{
		devicesUpdate([]);
		HandleGetDevices();

		header.getDevices = HandleGetDevices;		
		headerUpdate({...header});

		

	},[window.username]);

	
	


	const handleGooglemap = (position) =>{
		let latitude = position.split(',')[0];
		let longitude = position.split(',')[1];

		// console.log(latitude+','+longitude);

		window.open('https://www.google.com/maps/dir//'+latitude+','+longitude+'/@'+latitude+','+longitude+',15z');

		// return true;
	}

	

	const dellDeviceHandle = (e,index) => {

		let imei = devices[index].imei;
		
		header.delDevices(imei,()=>{
			devicesUpdate([]);			
			HandleGetDevices('uniquerequest');
		});		
	}
	

	

	const editDeviceHandle = (e,index) => {

		let imei = devices[index].imei;		
	 	header.editDevices(imei,()=>{
	 		header.menuToggle('admdevice');
	 	});	
		
	}
	



	return (
		<div className="page h100 sticky">

			
		
			<div className="line mobile-nobreak tablet-nobreak padding-h-20">
				<div className="column right">

					<button className={"button -default padding margin-right " } onClick={(e)=>{ header.modeEditDevice('add'); header.menuToggle('admdevice'); }} >
		    			<FontAwesomeIcon icon={faPlus} /> Rastreador
		    		</button>

					<button className={"button -toggle padding "+(detailShow===true?'active':'') } onClick={(e)=>{ detailShowUpdate(!detailShow) }} >
		    			<FontAwesomeIcon icon={faSortAmountDownAlt} />
		    		</button>
				</div>
			</div>

			{ devicesLoading === true && (
				<p className="loading margin-top-50 center"></p>
			)}

		  	{ (devicesLoading === false && devices.length < 1) && (
		  		<p className="margin-top-50 center">Sem rastreador</p>
		  	)}

		  	<ul className={"cardDevices "+(devicesLoading===true?'hide':'')}>


		  	{(devices).map((key,index) => {			 	
		 		return (
		 			
					<li key={index} statusonline={""+devices[index].online} enabled={devices[index].enabled}  >

						<div className={"cardDevices_header "+(detailShow===true?'active':'')} style={{"borderColor":devices[index].color}}>
							<div className="line mobile-nobreak">							
								<div className="column col6">
									<span className="status"></span>
									<label>{devices[index].name}</label>
								</div>
								<div className="column right">
									{devices[index].online === true && (
										<>
											{ (devices[index].parked ) && (
												<small className="-primary-text">Estacionado</small>
											)}

											{ ((devices[index].parked === '' || devices[index].parked === null) && (devices[index].lastspeed) > 3 ) && (
												<small className="-success-text">
													<strong>{devices[index].lastspeed?devices[index].lastspeed:'?'} km</strong> - Movendo 												
												</small>
											)}

											{ ((devices[index].parked === '' || devices[index].parked === null) && (devices[index].lastspeed) <= 3 ) && (
												<small className="-success-text">
													Parado
												</small>
											)}
										</>
									)}

									{devices[index].online === false && (
										<small className="-error-text">Sem sinal</small>
									)}
								</div>
							</div>
						</div>
						<div className="line mobile-nobreak cardDevices_content">							
							<div className="column">
								<table>
									<tbody>
									<tr className={detailShow===true?'':'hide'} >
										<th width="30%">IMEI</th>
										<td>{devices[index].imei}</td>										
									</tr>
									<tr className={detailShow===true?'':'hide'}>
										<th>Último online</th>
										{devices[index].lastonline===null?(
											<td ></td>										
										):(
											<td title={devices[index].lastonline}>{(new Date(devices[index].lastonline*1000)).toLocaleString()}</td>										
										)}
									</tr>
									<tr className={detailShow===true?'':'hide'}>
										<th>Última posição</th>
										{devices[index].lastposition===null?(
											<td ></td>										
										):(
											<td title={devices[index].lastposition}>

												<input type="text" name="name" className="inputtransparent w100" defaultValue={devices[index].lastposition}  />

												

												{/*<button type="button" className="link" onClick={ (e)=>{ handleGooglemap(devices[index].lastposition) } }  title={devices[index].lastposition} >
												{devices[index].lastposition}
												</button>*/}
											</td>										
										)}
									</tr>
									{/*<tr>
										<th>Velocidade</th>
										{devices[index].lastposition===null?(
											<td ></td>										
										):(
											<td title={devices[index].lastspeed}>
												{devices[index].lastspeed?devices[index].lastspeed:'?'} km
											</td>										
										)}
									</tr>*/}

									<tr className={detailShow===true?'':'hide'} >
										<th width="30%">Telefone</th>
										<td>+{devices[index].ddi} ({devices[index].ddd}) {devices[index].phone}</td>										
									</tr>
									<tr className={detailShow===true?'':'hide'} >
										<th width="30%">Fuso horário</th>
										<td>{devices[index].timezone}</td>
									</tr>
									<tr className={detailShow===true?'':'hide'} >
										<th width="30%">Modelo</th>
										<td>{devices[index].type}</td>
									</tr>
									<tr className={detailShow===true?'':'hide'} >
										<th width="30%">APN</th>
										<td>{devices[index].apn}</td>
									</tr>
									<tr className={detailShow===true?'':'hide'} >
										<th width="30%">Endereço da APN</th>
										<td>{devices[index].apnaddress}</td>
									</tr>
									<tr className={detailShow===true?'':'hide'} >
										<th width="30%">Usuário da APN</th>
										<td>{devices[index].apnusername}</td>
									</tr>
									<tr className={detailShow===true?'':'hide'} >
										<th width="30%">Senha da APN</th>
										<td>{devices[index].apnpassword}</td>
									</tr>

									</tbody>
								</table>
								
								<div className={detailShow===true?'':'hide'}>

									<div className="line mobile-nobreak">							
										<div className="column right">

											{ devices[index].lastposition&&(
												<button className={"link -primary padding margin-right " } onClick={(e)=>{ handleGooglemap(devices[index].lastposition)}}  >
									    			<FontAwesomeIcon icon={faLocationArrow} />
									    		</button>
											)}

								    		<button className={"link -primary padding margin-right " } onClick={(e)=>{ editDeviceHandle(e,index)		 }} >
								    			<FontAwesomeIcon icon={faEdit} />
								    		</button>

											<QuestionInline>
												<div question="">
													<button className={"link -primary padding " }  >
										    			<FontAwesomeIcon icon={faTrash} />
										    		</button>
												</div>
												<div alternatives="">

													<div response="yes">
														<button className={"link -primary padding" } onClick={(e)=>{ dellDeviceHandle(e,index) }} >
											    			<FontAwesomeIcon icon={faCheck} />
											    		</button>
													</div>

								
												</div>
											</QuestionInline>

											
											
											
						    			</div>
						    		</div>
					    		</div>

							</div>
						</div>
					</li>
					
				);
			})}

		  	</ul>


		</div>
	);
};


export default Component;
