import React, {useState, createContext, useEffect,useContext} from "react";
import {AuthProvider,AuthContext} from "../contexts/auth";

import { env } from '../.env.js';
import axios from "axios";

import {getUniqueID} from "../libs/getuniqueid.js";
import  {md5} from "../libs/md5";	


export const api = axios.create({
	baseURL: env.AchoordURL
});


// session 
export const getRoutes = async (imei,date) => {

	date = date.replace(/\//g,'-');

	// let device = getUniqueID();	
	// password = md5(password);
	// let date = "2022-12-04";
	// let date = "2022-12-23";
	// let date = "2023-01-05";
	// let date = "2023-01-05";
	let hashuser = "3fc01891d40d45a0765fd1d28e9abd62";
	return api.get("/trackers/routes/?imei="+imei+"&date="+date+"&orderasc");

	// return api.get("/users/api/token/?email="+email+"&password="+password);
}


// session 
export const getDates = async (imei) => {
	let hashuser = "3fc01891d40d45a0765fd1d28e9abd62";
	return api.get("/trackers/dates/?mode=all&imei="+imei);
}

