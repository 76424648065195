import {useEffect} from "react";

import Header from "../../layout/header/index.jsx";
import Footer from "../../layout/footer/index.jsx";
import FormLogin from "../../layout/formlogin/index.jsx";
import FormRegister from "../../layout/formregister/index.jsx";
import FormRemember from "../../layout/formremember/index.jsx";

//import logo from '../../logo.png';
// import logolight from './logo.png';
// import "./style.css";

const LoginPage = () => {

	


	useEffect(()=>{
		
		


		if(document.querySelector('[name="formlogin"]'))
			document.querySelector('[name="formlogin"]').scrollIntoView();

		document.querySelector('.--login.sticky').style['scroll-behavior'] = 'smooth';

		window.onresize = function(){
			let { hash } = window.location;
			hash = hash.replace('#','');

			if(document.querySelector('[name="'+hash+'"]'))
				document.querySelector('[name="'+hash+'"]').scrollIntoView();

		}

		/*setInterval(()=>{

			let { hash } = window.location;
			hash = hash.replace('#','');
			// alert( hash);

			if(document.querySelector('[name="'+hash+'"]'))
				document.querySelector('[name="'+hash+'"]').scrollIntoView();
			
		},5000);*/

	},[]);

	return (
		<div className="--login h100 formstart sticky vh100">
			
			<div className="formregister h100">
				<a name="formregister"></a>
				<FormRegister/>
			</div>

			<div className="formlogin h100">
				<a name="formlogin"></a>
				<FormLogin/>
			</div>

			<div className="formremember h100">
				<a name="formremember"></a>
				<FormRemember/>
			</div>
		</div>
	);
};


export default LoginPage;
