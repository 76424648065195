import React, {useState, createContext, useEffect,useContext} from "react";
import {AuthProvider,AuthContext} from "../../../../contexts/auth";


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSortAmountDownAlt,faPlus,faEdit,faTimes,faFlag,faThumbtack,faMapMarkerAlt,faUsers,faUserCircle,faGlobe,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'

import {Marker} from "./Marker";
import {CameraList} from "./CameraList";
import {Trackers} from "./Trackers";
import {ImagesList} from "./ImagesList";

const SettingsMap = (props) => {

    const {view,viewUpdate} = useContext(AuthContext);

    if(view.map.settings === undefined) return(<></>);


    return (
		<>
			<div className={"SettingsMap view-"+view.map.settings}>
				<div className="SettingsMap_area">
					
					{view.map.settings === 'marker' && (	 <Marker/> )}
					{view.map.settings === 'camera' && (	 <CameraList/> )}
					{view.map.settings === 'images' && (	 <ImagesList/> )}
					
					

				</div>
			</div>
		</>
    );
}

export {SettingsMap}
