
function showFullName (name) {
    if(name === 'ef') return "Externo Frente";
    if(name === 'et') return "Externo Traseira";
    if(name === 'ee') return "Externo Esquerda";
    if(name === 'ed') return "Externo Direita";
    if(name === 'if') return "Interno Frente";
    if(name === 'it') return "Interno Traseira";

    return name;
}

export {showFullName}
