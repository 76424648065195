const Storage = (callback) => {

 	return {
 		save:(key,value)=>{
 			window.localStorage.setItem(key,value);
 		},load:(key)=>{
 			return window.localStorage.getItem(key);
 		},remove:(key)=>{
 			return window.localStorage.removeItem(key);
 		}
 	}
}

export {Storage}