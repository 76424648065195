// import Header from "../../layout/header/index.jsx";
// import Footer from "../../layout/footer/index.jsx";
// import FormLogin from "../../layout/formlogin/index.jsx";

// import "./style.css";

const NotFoundPage = () => {

	

	return (
		<div className="--default wrapper layout_login ">
			
			{/*<div class="header">
				
				<Header/>
				
			</div>*/}
			<div className="main">
				
				<div className="line h100">
					<div className="column col6 sideInfo middle center h20">
						<div className="wrapper">
							<div className="main">
								<div className="line h100">
									<div className="column col6 sideInfo middle center h100 ">
										<h1>404</h1>
										<p>Página não encontrada</p>
									</div>
								</div>
							</div>
							<div className="footer right mobile-hide tablet-hide">
								<a href="https://wallrio.com" target="_blank">wallrio.com</a>
							</div>
						</div>
					</div>
					<div className="column sideForm middle center h80">

						<div className="wrapper">
							<div className="main">
								<div className="line h100">
									<div className="column col6 middle center h100 ">
										<h1></h1>
									</div>
								</div>
							</div>
							<div className="footer right desktop-hide ">
								<a href="https://wallrio.com" target="_blank">wallrio.com</a>
							</div>
						</div>

						
					</div>
				</div>



			</div>
			{/*<div class="footer">
				<Footer/>
			</div>*/}
		
		</div>

	);
};


export default NotFoundPage;
