import React, {useContext,useEffect} from "react";

import {AuthProvider,AuthContext} from "./contexts/auth";

import {
	BrowserRouter,
	Route,
	Routes,
	Navigate,
	useLocation
} from "react-router-dom"

import SplashPage from "./pages/splash/index.jsx";
import LoginPage from "./pages/login/index.jsx";
import NotFoundPage from "./pages/notfound/index.jsx";
import DashboardPage from "./pages/dashboard/index.jsx";

import DashboardHomePage from "./pages/dashboard/home/index.jsx";
// import DashboardTrackersPage from "./pages/dashboard/trackers/index.jsx";
import DashboardMapPage from "./pages/dashboard/map/index.jsx";
import DashboardNotFoundPage from "./pages/dashboard/notfound/index.jsx";
import DashboardAccountPage from "./pages/dashboard/account/index.jsx";
import DashboardSimulateRoutePage from "./pages/dashboard/simulateroute/index.jsx";

const AppRoutes = () =>{




	const Private = ({children}) =>{
		const {authenticated,location/*,loading*/} = useContext(AuthContext);
			
		document.querySelector('html').setAttribute('route',location.pathname);

		if(!authenticated){
			return <Navigate to="/" />;
		}

		return children;
	}	

	let basename = '/';
	// let basename = '/api/v1/accounts/wallrio/onistation/p/front/api/app/appv5';

	return (
		<>	
			<BrowserRouter basename={basename}>
				<AuthProvider>
					<Routes>

				        {/*<Route exact path="/" element={<SplashPage/>} />*/}

						<Route exact path="/" element={<SplashPage />} />
						<Route exact path="/login" element={<LoginPage />} />

						
						<Route path="dashboard" element={<Private><DashboardPage /></Private>}>
				          {/*<Route path="apikeys" element={<DashboardApiKeysPage/>} />*/}
				          
				          <Route path="home" element={<DashboardHomePage/>} />
				          {/*<Route path="trackers" element={<DashboardTrackersPage/>} />*/}
				          <Route path="map" element={<DashboardMapPage/>} />
				          <Route path="account" element={<DashboardAccountPage/>} />
				          <Route path="simulateroute" element={<DashboardSimulateRoutePage/>} />
				          {/*<Route path="bots" element={<DashboardBotsPage/>} >
					          	<Route index path="" element={<DashboardBotsListPage/>} />
					          	<Route path="new" element={<DashboardBotsNewPage/>} />
					          	<Route path="edit:botid" element={<DashboardBotsEditPage/>} />
					          	<Route path="monitor:botid" element={<DashboardBotsMonitorPage/>} />
					          	<Route path="backtest:botid" element={<DashboardBotsBacktestPage/>} />
				          </Route>

				          <Route path="exchanges" element={<DashboardExchangesPage/>} >
					          	<Route index path="" element={<DashboardExchangesListPage/>} />
					          	<Route path="new" element={<DashboardExchangesNewPage/>} />
					          	<Route path="edit:botid" element={<DashboardExchangesEditPage/>} />
				          </Route>
				          
				          <Route path="orders" element={<DashboardOrdersPage/>} />
				          <Route path="notifications" element={<DashboardNotificationsPage/>} />

				          <Route path="settings" element={<DashboardSettingsPage/>} >
					          	<Route index path="" element={<DashboardSettingsListPage/>} />
				          		<Route path="account" element={<DashboardSettingsAccountPage/>} />
				          		<Route path="logout" element={<DashboardSettingsLogoutPage/>} />
				          </Route>*/}

				          <Route path="*" element={<DashboardNotFoundPage/>} />
				        </Route>

						<Route path="*" element={<NotFoundPage />} />
					</Routes>
				</AuthProvider>
			</BrowserRouter>			
		</>
	);
};

export default AppRoutes;