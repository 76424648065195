import React, {useState, createContext, useEffect,useContext} from "react";
import {AuthProvider,AuthContext} from "../../../../../contexts/auth";


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStop,faPause,faPlay,faVideo,faDownload,faLock,faUnlock,faWindowMaximize,faCheck,faSave,faBan,faTrash,faSortAmountDownAlt,faPlus,faEdit,faTimes,faFlag,faThumbtack,faMapMarkerAlt,faUsers,faUserCircle,faGlobe,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'

import {startRealtimeCam,getVideos,getImageCamera,listCameras} from "../../../../../services/apiCameras";

import QuestionInline from "../../../../../components/questioninline";
// import { useMap, useMapEvents,useMapEvent } from 'react-leaflet/hooks'


import {geoToAddressHandle} from "../../../../../libs/geoToAddressHandle.jsx";

import {slugfy} from "../../../../../libs/misc";
import {Base64} from "../../../../../libs/base64";
import {showFullName} from "../../../../../libs/tracker.js";

import { useSnackbar } from 'notistack';

import "./style.css";

import imageEmpty from "./empty.jpg";
// import encBase64 from "crypto-js/enc-base64";


const ImagesList = (props) => {

	const { enqueueSnackbar } = useSnackbar();

    const {logout,user,location,global,globalUpdate,action,actionUpdate,view,viewUpdate} = useContext(AuthContext);

	
	let dataDefault = {name:"ef",fields:{address:""}};


    const [viewLocal,viewLocalUpdate] = useState(['list',0]);
    const [list,listUpdate] = useState({});
    const [data,dataUpdate] = useState(dataDefault);
    const [areaView,areaViewUpdate] = useState(false);
    
    const [countImagesExist,countImagesExistUpdate] = useState(0);
	

	
 
    const settingsClose = () =>{
        view.map.settings = undefined;   
        viewUpdate({...view});
    }


	
	


    const onDoubleClickBackHandle = (e) => {
        document.querySelector('.SettingsMap').classList.remove('fullscreen');
        viewLocalUpdate(['list',window.viewLocal]);
    }

    const onDoubleClickHandle = (e,key) => {
        window.viewLocal = key
        viewLocalUpdate(['imagesingle',window.viewLocal]);
    }

    const onClickHandle = (e,key) => {
        window.viewLocal = key
        viewLocalUpdate(['imagesingle',window.viewLocal]);
    }

    /* const onLoadHandle = (e) => {
        
		console.log(countImagesExist);
		countImagesExistUpdate((count)=>{
			return count+1;
		});
    }
 */
    const onErrorHandle = (e) => {
        e.target.src = imageEmpty;
		countImagesExistUpdate((count)=>{
			return count-1;
		}); 
    }

    const maximizeCamerasHandle = (key) => {
		document.querySelector('.SettingsMap').classList.toggle('fullscreen');
	}

    const saveImageHandle = (data) =>{
		document.querySelector('.imageSaveBtn').setAttribute( 'download',"imagem_do_trecho");
		document.querySelector('.imageSaveBtn').setAttribute( 'href',data);
	}

    const loopCheckFollow = async (callback) => {

		if(global.map.followdevice !== null){
			
			listImagesHandle();
		}

		setTimeout(()=>{
			loopCheckFollow();
		},5000);
	}

    const listImagesHandle = async (callback) => {

        let imei = view.map.data.imei;
        let positionid = view.map.data.positionid;
        let lastupdate = view.map.data.lastupdate;
        let arrivalTime = view.map.data.arrivalTime ?? view.map.data.lastupdate;
        let created = Number(view.map.data.created)*1000;

		let dateArray = null;
		if(arrivalTime !== null){
			dateArray = arrivalTime.split(' ');
		}else{
			dateArray = lastupdate.split(' ');

		}

        let date = dateArray[0].replace(/-/g,'/');

        let year = (new Date(created)).getFullYear();
        let month = (new Date(created)).getMonth()+1;
        let day = ("0" + (new Date(created)).getDate()).slice(-2);

        let url = "https://admin.achoord.com/api/v1/accounts/wallrio/onistation/p/files/api/files/?pwd=idr/"
        +imei+"/cameras/images/{position}/"+date+"/"
        +positionid+".jpg&mode=content";

        let listCams = ["ef","et","ee","ed","it","if"];

        let body = {};
        for(let key in listCams){        
            body[ listCams[key] ] = url.replace(/{position}/g,listCams[key]);
        }
      
		//console.log(global.map.followdevice);
		
         //console.log(body);

		// if(body.length > 0){			
			listUpdate(body);
		/* }else{
			listUpdate({});			
		} */

		countImagesExistUpdate(Object.keys(body).length);

		// setTimeout(()=>{
			if(callback)callback(body);
		// });
	}

    useEffect(()=>{		
       		
		//viewLocalUpdate(['imagesingle',window.viewLocal]);
		// console.log('==',global.map.followdevice);
		// viewLocalUpdate(['imagesingle',window.viewLocal]);
		listImagesHandle((listResponse)=>{
			// getImageLoop(listResponse);			
			loopCheckFollow();
		});
	},[]); 



    return (
		<>
			
				
				<div className="line mobile-nobreak tablet-nobreak SettingsMap_header">
					<div className="column left middle">
						<h3>Imagens do trecho

					
						<div>
							
						

							<small>{view.map.data.name}</small>

						

						{ ( viewLocal[0] === 'imagesingle'  ) && (
							<>
							&nbsp;<small>({ showFullName(viewLocal[1]) })</small>
							</>
						)}


						

						{ (viewLocal[0] === 'view'  ) && (
							<>						
								&nbsp;<small>({ showFullName(list[viewLocal[1]][0]) })</small>
							</>
						)}

						</div>

						</h3>	
									
					</div>	

					
					
					{ (viewLocal[0] === 'imagesingle' ) && (
						<>						
									
							<div className="column col1 middle">
								<a className="link  w100 padding imageSaveBtn" download="camera_image.jpg" onClick={()=>{ saveImageHandle(list[viewLocal[1]]) }}>
									<FontAwesomeIcon icon={faDownload} />
								</a>
							</div>				
							<div className="column col1 middle">
								<button className="link  w100 padding" onClick={()=>{ maximizeCamerasHandle() }}>
									<FontAwesomeIcon icon={faWindowMaximize} />
								</button>
							</div>	
							
						</>
					)}
					


                    { (viewLocal[0] === 'list') && (
						<>						
							<div className="column col1 right middle">
								<button className="link  padding-h-10" onClick={()=>{ settingsClose() }}>
									<FontAwesomeIcon icon={faTimes} />
								</button>
							</div>	
						</>
					)}

                    { ( viewLocal[0] === 'imagesingle') && (
						<>						
							<div className="column col1 right middle">
								<button className="link  padding-h-10" onClick={()=>{ onDoubleClickBackHandle() }}>
									<FontAwesomeIcon icon={faTimes} />
								</button>
							</div>	
						</>
					)}
										
				</div>	

					<div className="SettingsMap_content">
					
					
				

					
					
                        { viewLocal[0] === 'imagesingle' && (
                            <>
                               <img className="imagesingle" src={list[window.viewLocal]} alt="" onClick={onDoubleClickBackHandle} onError={onErrorHandle}  />											
                            </>
                        )}

					{ viewLocal[0] === 'list' && (
						<>

                           

						{Object.keys(list).length < 1 &&(
							<p className="padding-h-20">Nenhuma imagem</p>
						) }

						<div className="listing">
							<ul>
							{Object.keys(list).map((key,index) => {

								

							
								return (
									<li key={key}>
										<label>{showFullName(key)}</label>
										<img src={list[key]} alt="" onDoubleClick={(e)=>{ onDoubleClickHandle(e,key) }} onClick={(e)=>{ onClickHandle(e,key) }} onError={onErrorHandle} />	
										
									</li>
								)
							})}
							</ul>
						</div>
						</>
					)}

					</div>
				

				
		</>
    );
}

export {ImagesList}
