import React, {useState, createContext, useEffect,useContext} from "react";
import {AuthProvider,AuthContext} from "../../../../contexts/auth";

import { MapContainer, TileLayer, useMap,Marker,Popup ,Polyline,Tooltip,Circle} from 'react-leaflet'

import L from "leaflet";

import stoppedOffline from "./icons/stopped/point-offline.png";

import stoppedOnlineGreen from "./icons/stopped/point-online-green.png";
import stoppedOnlineRed from "./icons/stopped/point-online-red.png";
import stoppedOnlineBlue from "./icons/stopped/point-online-blue.png";
import stoppedOnlinePink from "./icons/stopped/point-online-pink.png";
import stoppedOnlineYellow from "./icons/stopped/point-online-yellow.png";

import runOnlineGreen from "./icons/point-online-green.png";
import runOnlineRed from "./icons/point-online-red.png";
import runOnlineBlue from "./icons/point-online-blue.png";
import runOnlinePink from "./icons/point-online-pink.png";
import runOnlineYellow from "./icons/point-online-yellow.png";

import parkedIcon from "./icons/parked.png";
import maxSpeedIcon from "./icons/maxspeed.png";

import {PopupMap} from "../PopupMap";

import {getDateLastPosition} from "../getDateLastPosition";

import {geoToAddressHandle} from "../../../../libs/geoToAddressHandle.jsx";

const DrawDevices = (props) => {


    const [address,addressUpdate] = useState('');
    const {logout,user,location,global,globalUpdate} = useContext(AuthContext);

    if(props.devices.length === 0) return;

    
    return (
		<>
			{Object.keys(props.devices).map((key,index) => {
                                      
                    let dates = (new Date());
                    let currentDate = dates.getFullYear()+'/'+ ("0" + (dates.getMonth()+1)).slice(-2) +'/'+("0" + dates.getDate()).slice(-2);
                    let currentTime = dates.getHours()+':'+ ("0" + (dates.getMinutes()+1)).slice(-2) +':'+("0" + dates.getSeconds()).slice(-2);
    
                    let datesLastonline = (new Date(props.devices[key].lastonline*1000));
                    let currentDateLastonline = datesLastonline.getFullYear()+'/'+ ("0" + (datesLastonline.getMonth()+1)).slice(-2) +'/'+("0" + datesLastonline.getDate()).slice(-2);

                    {/*let lastupdate = (new Date(parseInt(props.devices[key].lastonline)*1000)).toLocaleTimeString();*/}
                    
                    let datesLast = (new Date(parseInt(props.devices[key].lastonline)*1000));
                    let lastupdate = datesLast.getFullYear()+'/'+ ("0" + (datesLast.getMonth()+1)).slice(-2) +'/'+("0" + datesLast.getDate()).slice(-2) + " " + ("0" + (datesLast.getHours())).slice(-2)+':'+ ("0" + (datesLast.getMinutes())).slice(-2) +':'+("0" + datesLast.getSeconds()).slice(-2);
    
                    let positionid = null;
                    if(props.devices[key].points.length > 0){
                        positionid = props.devices[key].points[props.devices[key].points.length-1].positionid;              
                    }else{
                        positionid = "last";
                    }
                    
                //  console.log( props.devices[key] );
                    

                    let lastspeed = props.devices[key].lastspeed;

                    let countPositions = 0;
                    let lastposition;
                    for(let keya in props.devices[key].positions){
                        let count = props.devices[key].positions[keya].length-1;
                        lastposition = props.devices[key].positions[keya][count];   
                        countPositions = countPositions + count;                 
                    }
                    
                    if( global.map.date !== 'today' ){                        
                        for(let keya in props.devices[key].positionsAll){
                            let item = props.devices[key].positionsAll[keya];                        
                            // lastupdate = item.date.split(' ')[1];
                            // let lastupdate = ("0" + (datesLast.getHours())).slice(-2)+':'+ ("0" + (datesLast.getMinutes())).slice(-2) +':'+("0" + datesLast.getSeconds()).slice(-2);
    
                        }
                    }

                    let lastpositiondata;
                    for(let keya in props.devices[key].positiondata){
                        lastpositiondata = props.devices[key].positiondata[keya];             
                    }

                    if(lastpositiondata !== undefined)
                    props.devices[key].speed = lastpositiondata.speed===''?0:lastpositiondata.speed;


                    if( currentDate === currentDateLastonline && countPositions === 0 && (global.map.date === 'today') )
                    if(props.devices[key].lastposition !== null){
                        lastposition = props.devices[key].lastposition.split(',');  
                        lastposition = [parseFloat(lastposition[0]),parseFloat(lastposition[1])];
                    }
                    

                    let heading = 0;                    
                    if( Object.keys(props.devices[key].positiondata).length > 0 ){                        
                        let positiondataArrayLast = props.devices[key].positiondata[Object.keys(props.devices[key].positiondata).length-1];
                        heading = positiondataArrayLast[positiondataArrayLast.length-1].heading;                                    
                    }

                    heading = heading - 180;


                    let htmlDeviceIcon = '';                    
                    if( props.devices[key].lastspeed <= 3 || countPositions <= 1){                    
                        htmlDeviceIcon = '<img style="transform: rotate('+heading+'deg);" height="50" width="50" src="'+stoppedOnlineGreen+'">';                    
                        if(props.devices[key].color === 'red') htmlDeviceIcon = '<img style="transform: rotate('+heading+'deg);" height="50" width="50" src="'+stoppedOnlineRed+'">';
                        if(props.devices[key].color === 'blue') htmlDeviceIcon = '<img style="transform: rotate('+heading+'deg);" height="50" width="50" src="'+stoppedOnlineBlue+'">';
                        if(props.devices[key].color === 'pink') htmlDeviceIcon = '<img style="transform: rotate('+heading+'deg);" height="50" width="50" src="'+stoppedOnlinePink+'">';
                        if(props.devices[key].color === 'yellow') htmlDeviceIcon = '<img style="transform: rotate('+heading+'deg);" height="50" width="50" src="'+stoppedOnlineYellow+'">';
                    }else{
                        htmlDeviceIcon = '<img style="transform: rotate('+heading+'deg);" height="50" width="50" src='+runOnlineGreen+'>';                    
                        if(props.devices[key].color === 'red') htmlDeviceIcon = '<img style="transform: rotate('+heading+'deg);" height="50" width="50" src="'+runOnlineRed+'">';
                        if(props.devices[key].color === 'blue') htmlDeviceIcon = '<img style="transform: rotate('+heading+'deg);" height="50" width="50" src="'+runOnlineBlue+'">';
                        if(props.devices[key].color === 'pink') htmlDeviceIcon = '<img style="transform: rotate('+heading+'deg);" height="50" width="50" src="'+runOnlinePink+'">';
                        if(props.devices[key].color === 'yellow') htmlDeviceIcon = '<img style="transform: rotate('+heading+'deg);" height="50" width="50" src="'+runOnlineYellow+'">';
                        
                    }
                    

                    

                    {/*if( global.map.date !== 'today' && (global.map.date !== currentDate || props.devices[key].online === false) &&  countPositions <= 1 ){*/}
                    if(  ((global.map.date !== 'today' && global.map.date !== currentDate) || props.devices[key].online === false)  ){
                        
                        {/*console.log(global.map.date , currentDate);*/}

                        {/*console.log(props.devices[key].name, props.devices[key].online, global.map.date    );*/}
                        htmlDeviceIcon = '<img style="transform: rotate('+heading+'deg);" height="50" width="50" src="'+stoppedOffline+'">';                    
                        lastspeed = null;
                    }

                    if(lastspeed <= 3){                      
                        lastspeed = null;
                    }

                    
                    var DeviceIcon = L.divIcon({
                        iconSize: [40, 40],
                        iconAnchor: [25, 25],
                        className: 'deviceicon deviceicon-'+index,
                        html: htmlDeviceIcon
                    });

                    let DeviceIconParked = '';

                    if(props.devices[key].parked){

                        DeviceIconParked = L.divIcon({
                            iconSize: [20, 20],
                            iconAnchor: [0, 0],
                            className: 'parkedicon',
                            html: '<img  height="15" width="15" src="'+parkedIcon+'">'
                        });

                        

                    }else{
                        if( lastspeed >= 45){

                            DeviceIconParked = L.divIcon({
                                iconSize: [20, 20],
                                iconAnchor: [0, 0],
                                className: 'parkedicon',
                                html: '<img  height="15" width="15" src="'+maxSpeedIcon+'">'
                            });
                        }else{
                            DeviceIconParked = L.divIcon({
                                iconSize: [20, 20],
                                iconAnchor: [0, 0],
                                className: 'parkedicon',
                                html: ''
                            });
                        }
                    }
                    

                    if(lastposition !== undefined){

                        let indexLast= props.devices[key].positiondata.length-1;
                        let responseDateLastPosition = getDateLastPosition(props.devices[key].positiondata,indexLast);
                        

                        let parkedTime = currentTime;
                        let arrivalTime = null;
                        let exitTime = '';
                        let travelTime = '';
                        let departureTime = '';
                        let geoDistance = '';
                        let geoDistanceTotal = '';
                        let geopos = lastposition;
                        let subtitle = '';
                        let parkedTitle = '';

                       // console.log(responseDateLastPosition);

                        if(responseDateLastPosition !== null){


                            parkedTime = responseDateLastPosition['parkedTime'];
                            arrivalTime = responseDateLastPosition['arrivalTime'];
                            exitTime = responseDateLastPosition['exitTime'];
                            travelTime = responseDateLastPosition['travelTime'];
                            departureTime = responseDateLastPosition['departureTime'];
                            geoDistance = responseDateLastPosition['geoDistance'];
                            geoDistanceTotal = responseDateLastPosition['geoDistanceTotal'];
                            geopos = responseDateLastPosition['geopos'];
                            subtitle = responseDateLastPosition['subtitle'];
                            parkedTitle = '';
                        }

                       {/* if(!geopos){
                            geopos = lastposition;
                        }*/}


                        if(props.devices[key].online === true){
                        

                            if(props.devices[key].parked){                                
                                parkedTitle = 'Estacionado';
                            }
    

                              
                            if( (props.devices[key].parked === ' ' || props.devices[key].parked === '' || props.devices[key].parked === null) && parseFloat(lastspeed) >3 ){                                                         
                               parkedTitle = 'Movendo';     
                               parkedTime = null;                            
                               arrivalTime = null;                            
                            }else if( (props.devices[key].parked === ' ' || props.devices[key].parked === '' || props.devices[key].parked === null) && parseFloat(lastspeed) <= 3 ){                                                         
                               parkedTitle = 'Parado';  
                               parkedTime = null;                                                               
                               arrivalTime = null;                                                               
                            }                        
                        }

                        if(props.devices[key].online === false){
                            parkedTitle = 'Sem sinal';
                        }
            
                        

                        return (
                            <div key={key}>

                               
                                <Marker icon={DeviceIconParked} position={geopos}></Marker>
                         

                                <Marker icon={DeviceIcon} 

                                eventHandlers={{
                                    click: (e) => {
/*
                                        addressUpdate('');

                                        let latitude = e.target.getLatLng().lat;
                                        let longitude = e.target.getLatLng().lng;

                                       let geoToAddressResponse = geoToAddressHandle(latitude,longitude);

                                         geoToAddressResponse.then((response)=>{

                                             let locateString = response.street+', '+response.city+', '+response.state+' '+response.postcode+' '+response.country;

                                            addressUpdate('a');  
                                        })*/

                                    }
                                }}

                                position={geopos}>
                                    <Popup ref={(r) => {
                                        
                                        if(typeof global.map.popups === 'undefined') 
                                        global.map.popups = {};

                                        global.map.popups[props.devices[key].name] = r;

                                        globalUpdate((glob)=>{                      
                                            glob.map.popups = global.map.popups;
                                            return glob;
                                        }); 
                                        // globalUpdate(global);
                                        // console.log(global.map.popups);
                                        // popupRef = r;
                                        // setRefReady(true);
                                    }}>

                                        <PopupMap 
                                            index={key}
                                            name={props.devices[key].name} 
                                            subtitle={parkedTitle} 
                                            devices={props.devices}
                                            global={global}
                                            globalUpdate={globalUpdate}
                                            follow={true}
                                            marker={true}
                                            camera={true}
                                            commands={true}
                                            images={true}
                                            imei={props.devices[key].imei} 
                                            color={props.devices[key].color} 
                                            parked={props.devices[key].parked} 
                                            online={props.devices[key].online} 
                                            speed={lastspeed} 
                                            lastupdate={lastupdate}     
                                            positionid={positionid}                                        
                                            // lastposition={lastposition}  


                                            lastposition={geopos}  
                                            parkedTime={parkedTime}  
                                            arrivalTime={arrivalTime}  
                                            exitTime={exitTime}  
                                            travelTime={travelTime}  
                                            departureTime={departureTime}  
                                            geoDistance={geoDistance}  
                                            geoDistanceTotal={geoDistanceTotal}  
                                            address={address}  
                                        />
                                                                                
                                    </Popup>
                                </Marker>
                            </div>
                        );
                    }

                
            })}

		</>
    );
}

export {DrawDevices}
