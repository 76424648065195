import React, {useState, createContext, useEffect,useContext} from "react";
import {AuthProvider,AuthContext} from "../../../contexts/auth";

import {seqArray} from "../../../libs/seqArray.jsx";
import { useMap, useMapEvents,useMapEvent } from 'react-leaflet/hooks'
import {getRoutes,getDates} from "../../../services/apiRoutes";

import {Storage} from "../../../libs/Storage.jsx";
import {ajustArrowZoom} from "../../../libs/ajustArrowZoom.jsx";



import {geoToAddressHandle} from "../../../libs/geoToAddressHandle.jsx";

const DataMap = (props) => {

    
    // return;
	const {logout,user,location,global,globalUpdate} = useContext(AuthContext);
	const map1 = useMap();

    useEffect(() => {    
        global.map.handle = map1;
        globalUpdate(global);    
    });

    useEffect(() => {     

        // if(Object.keys(global.map.devices).length === Object.keys(props.devices).length ) return;

         // console.log(Object.keys(global.map.devices).length, Object.keys(props.devices).length );

    // cria as datas para utilização no calendario
        seqArray({
            array:props.devices,
            index:0,
            variables:{dates:[]},
            step:(next,value,index,array,variables)=>{
                let datePre = variables.dates;                 
                getDates(value.imei).then((response)=>{    
                    for(let key in response.data.body){
                        datePre[response.data.body[key]] = response.data.body[key];
                    }
                    next({dates:datePre});
                });                        
            },
            finish:(variables)=>{
                let dates = [];
                let datePre = variables.dates;
                for(let key in datePre){
                    dates.push(datePre[key]);
                }
                global.map.alldates = dates;
                globalUpdate(global);         
            },
        });
 
    	
        //seleciona os trechos das rotas individuamente
    	global.map.positionSelect = (mode,callback) => {   
            if(mode === 'reset'){
                global.map.positionCurrent = null;
            }else{

                if( global.map.positionCount <= 1 ){
                    return;
                }

                if(global.map.positionCurrent === global.map.positionCount){
                    global.map.positionCurrent = null;
                    globalUpdate({ ...global});
                    if(callback)callback();
                    return;
                }
                global.map.positionCurrent++;  
            }

            

            globalUpdate({ ...global});   
            // global.map.refresh(); 
            if(callback)callback();
    	}


    	 global.map.locateDevice = () => {

    	 	let devices = props.devices;
            let countDevices = Object.keys(devices).length;
          
            if( global.map.locateDeviceCurrent === undefined ){
                global.map.locateDeviceCurrent = 0;
            }else{
                global.map.locateDeviceCurrent++;
            }
                        
            if(global.map.locateDeviceCurrent >= countDevices)
                global.map.locateDeviceCurrent = 0;
            
            if( Object.keys(devices[global.map.locateDeviceCurrent].positiondata).length < 1 ){
                setTimeout(()=>{
                    global.map.locateDevice();
                });
                return;
            }
           
            
            let count = Object.keys(devices[global.map.locateDeviceCurrent].positiondata).length-1;        
            
            if( count < 0 ){
                return;
            }

            let deviceCurrent = devices[global.map.locateDeviceCurrent].positiondata[count];
            deviceCurrent = deviceCurrent[deviceCurrent.length-1];

         

            if( deviceCurrent !== undefined ){

	            let latitude = deviceCurrent.latitude;
	            let longitude = deviceCurrent.longitude;

                

                document.querySelector('.deviceicon-'+global.map.locateDeviceCurrent+' img').classList.add('devicehighlight');
                setTimeout(()=>{
                    document.querySelector('.deviceicon-'+global.map.locateDeviceCurrent+' img').classList.remove('devicehighlight');
                },1000);
	            map1.setView([latitude,longitude], 15, {
			      animate: true,
			    });

                setTimeout(()=>{
                    // console.log(document.querySelector('.deviceicon-'+global.map.locateDeviceCurrent+' img'));
                    document.querySelector('.deviceicon-'+global.map.locateDeviceCurrent+' img').click();
                },700);
            }
            
        }

       /* let zoom = Storage().load('onistation-map-zoom');   

        console.log(zoom);

        setTimeout(()=>{            
            ajustArrowZoom(zoom);
        },3000);*/

        // global.map.devices = props.devices
        // globalUpdate({...global})

	// },[]);
    },[props.devices]);
    
    
/*    const geoToAddressHandle = async (latitude,longitude) =>{
        const response = await geoToAddress(latitude,longitude);

        
        // let status = response.data.status;
        // let body = response.data.body;

        // console.log(response.data.status);

        if(response.data.status === 'no-match'){
            return {};
        }

        return {
            street:response.data.address.road,
            state:response.data.address.state,
            postcode:response.data.address.postcode,
            country:response.data.address.country,
            city:response.data.address.city,
            district:response.data.address.suburb,
        };
    }
*/

    useEffect(() => {        
        if(global.map.followdevice){

           /* if(typeof props.devices[parseInt(global.map.followdevice.index)] === 'undefined'){
                global.map.followdevice = null;
                globalUpdate({...global}); 
                return;
            }*/
            

            let lastspeed = props.devices[parseInt(global.map.followdevice.index)].lastspeed;
            let latitude = props.devices[parseInt(global.map.followdevice.index)].lastposition.split(',')[0];
            let longitude = props.devices[parseInt(global.map.followdevice.index)].lastposition.split(',')[1];

            global.map.followdevice.speed = lastspeed;

            let geoToAddressResponse = geoToAddressHandle(latitude,longitude);

            geoToAddressResponse.then((response)=>{

                global.map.followdevice.address = response;

                // console.log( props.devices[parseInt(global.map.followdevice.index)]);
                
                map1.setView([latitude,longitude], map1.getZoom(), {
                  animate: true,
                });
                 globalUpdate({...global});      
            })
        }
    },[props.devices]);



    useEffect(() => {	      	
        let dates = (new Date());
        if(global.map.date === null){
            global.map.date = 'today';
        }else if(global.map.date === dates.getFullYear()+'/'+ ("0" + (dates.getMonth()+1)).slice(-2) +'/'+("0" + dates.getDate()).slice(-2)){
            global.map.date = 'today';    
        }
        Storage().save('onistation-map-date',global.map.date);
	},[global.map.date]);

    
    


	const map = useMapEvents({
		zoomend: (e) => {            
            Storage().save('onistation-map-zoom',e.target.getZoom());

            ajustArrowZoom(e.target.getZoom());
        },
        move(e) {
            Storage().save('onistation-map-position',[e.target.getCenter().lat,e.target.getCenter().lng]);
        },click(e) {

            

        	// console.log( e.target.setCenter([50.5, 30.5]) );
        	// console.log( map.locate() );
        },dblclick(e) {
            console.log(e.latlng);
        },locationfound: (location) => {
	      // console.log(location.latlng)
	      // console.log('location found:', location)

	      // map.setCenter([50.5, 30.5])

	      // map.panTo(location.latlng);

	    },
    });

	return (
		<>
		</>
	);
}

export  {DataMap};