import React,{useState,useContext} from "react";

import  {AuthContext} from "../../contexts/auth";	

import {passwordRecovery} from "../../services/apiMisc";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPaperPlane,faKey,faUserPlus,faChevronLeft,faSignInAlt,faLocationArrow,faTimes,faTrash,faEdit,faPlus,faSortAmountDownAlt,faMapMarkerAlt,faUsers,faUserCircle,faGlobe,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'

import logolight from './logo.png';
// import "./style.css";

import { useSnackbar } from 'notistack';

const FormLogin = () => {

	const { enqueueSnackbar } = useSnackbar();

	
	const {authenticated, login} = useContext(AuthContext);

	const [email,setEmail] = useState("");
	const [password,setPassword] = useState("");
	
	const [responseremember,responserememberUpdate] = useState("Ao clicar em <strong>Enviar</strong>, será enviado um link para redefinição de senha no email informado acima");

	const handleSubmit = (e) =>{
		e.preventDefault();


		
		if(!email){
			responserememberUpdate('Insira seu email');
			return;
		}

		e.target.querySelector('#sendremenber').classList.add('loading');
		e.target.classList.add('disabled');

		passwordRecovery(email).then((response)=>{
			let status = response.data.status;
			let body = response.data.body;
			if(status === 'success'){
				// e.target.querySelect
				setEmail('');
				responserememberUpdate('Acesse seu email para redefinir a senha.');

				enqueueSnackbar('Acesse seu email para redefinir a senha.', { variant: "success"});
			}
			
			e.target.querySelector('#sendremenber').classList.remove('loading');
			e.target.classList.remove('disabled');

		});
	}
	
	return (

		<div className="wrapper layout_login">
			{/*<div className="header">
				
				<Header/>
				
			</div>*/}
			<div className="main">
				
				<div className="line h100">
					<div className="column col6 sideInfo middle center mobile-h40 tablet-h40">
						<div className="wrapper">
							<div className="main">
								<div className="line h100">
									<div className="column col6 sideInfo desktop-middle mobile-bottom tablet-bottom h100  ">

										<img src={logolight} className="logo" />

										<h1>Onistation</h1>
										<br/>
										<p className="desktop-hide">Redefinir senha</p>
										<br/>
										<br/>
									</div>
								</div>
							</div>
							<div className="footer left mobile-hide tablet-hide">
								<a href="https://wallrio.com" target="_blank">wallrio.com</a>
							</div>
						</div>
					</div>
					<div className="column sideForm desktop-middle center mobile-h60 tablet-h60">

						<div className="wrapper">
							<div className="main">
								<div className="line h100">
									<div className="column col6 desktop-middle center h100 ">
										

										<div className="w100">			
											
											<p className="mobile-hide tablet-hide">Redefinir senha</p>

											<form onSubmit={handleSubmit} className="desktop-w50 mobile-w90">
												
												<div className="inputs">
													<input 
														type="text" 
														name="username" 
														placeholder="Email"
														value={email}
														onChange={(e)=>setEmail(e.target.value)}
														autoComplete="username"
														className="input w100"
													/>
													
												</div>
												
											



												<div className="line mobile-nobreak tablet-nobreak desktop-w100 mobile-w100  controls">
													<div className="column col5 top center ">	
														<a href="#formlogin" type="submit" id="back" className="button -link w100">
															<FontAwesomeIcon icon={faChevronLeft} /> Voltar
														</a>					
														
													</div>
													<div className="column top right ">	
														<button type="submit" id="sendremenber" className="button -purple w100">
															<FontAwesomeIcon icon={faPaperPlane} /> Enviar
														</button>					
														
													</div>
												</div>

												<p className="padding-top-30" dangerouslySetInnerHTML={{ __html: responseremember }}>
													
													
												</p>

											</form>
											
										</div>



		
									</div>
								</div>
							</div>
							<div className="footer right desktop-hide ">
								<div className="line mobile-nobreak tablet-nobreak">
									<div className="column col6 left">
									
									</div>
									<div className="column ">										
										{/*<a href="https://wallrio.com"  className="button -link w100">wallrio.com</a>*/}
									</div>
								</div>
							</div>
						</div>

						
					</div>
				</div>



			</div>
			{/*<div className="footer">
				<Footer/>
			</div>*/}
		</div>


		
	);
};


export default FormLogin;