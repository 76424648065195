import { env } from '../.env.js';
import axios from "axios";

// import {getUniqueID} from "../libs/getuniqueid.js";
// import  {md5} from "../libs/md5";	

export const api = axios.create({
	baseURL: env.AchoordURL
});



export const blockVehicle = async (dataTracker,id) => {
	
	let imei = dataTracker.imei;

    var formDataTracker = new FormData();
	formDataTracker.append('type', 'default');
	formDataTracker.append('title', 'energy_off');
	formDataTracker.append('body', '');
	formDataTracker.append('custom1', imei);


	return api.post("https://admin.achoord.com/api/v1/accounts/wallrio/onistation/p/pushnotification/api/channel/sendtotrackers/messages/", formDataTracker,{
        headers: {
                "Content-Type": "application/json",
                'account': window.username,
                // 'imei': imei,
        }
      });

}