import { MapContainer, TileLayer, useMap,Marker,Popup ,Polyline,Tooltip,Circle} from 'react-leaflet'


const DrawLines = (props) => {

	const handleGooglemap = (position) =>{
        
        let latitude = position[0];
        let longitude = position[1];
    
        window.open('https://www.google.com/maps/dir//'+latitude+','+longitude+'/@'+latitude+','+longitude+',15z');
    }
    
    // if( Object.keys(props.devices).length < 1 ) return (<></>);


    return (
		<>
			{Object.keys(props.devices).map((key,index) => {
                    
                return (
                    <span key={key}>
                        {Object.keys(props.devices[key].positions).map((key2,index2) => {
                           
                            return (
                                <span key={key2}>
                                    <Polyline pathOptions={props.devices[key].optionsMult[index2]} positions={props.devices[key].positions[key2]} />
                                </span>
                            );
                        })}
                    </span>
                );
            })}
		</>
    );
}

export {DrawLines}
