import React,{useState,useContext,useEffect} from "react";
import {Link,useLocation,useNavigate} from "react-router-dom"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUsers,faUserCircle,faGlobe,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'
import {AuthContext} from "../../contexts/auth";	

import MenuMobile from "../../components/menumobile";	



// import BottomNavigation from '@mui/material/BottomNavigation';
// import BottomNavigationAction from '@mui/material/BottomNavigationAction';


const Component = () => {

	const {authenticated,user,setUser,statusGlobal,loading,login,logout} = useContext(AuthContext);

	const location = useLocation();
	// const {authenticated, login,logout} = useContext(AuthContext);
	/*const [email,setEmail] = useState("");
	const [password,setPassword] = useState("");

	const handleSubmit = (e) =>{
		e.preventDefault();
		login(email,password);
	}

	const handleLogout = () =>{
		logout();
	}
	*/
	let homeAlert = '';
	let botsAlert = '';
	let exchangesAlert = '';
	let ordersAlert = '';
	let notificationsAlert = '';
	let settingsAlert = '';

	/*if( statusGlobal.orders > 0 ){
		ordersAlert = faCircle;
	}
	
	if( statusGlobal.notificationNews > 0 ){
		notificationsAlert = faCircle;
	}*/
	
	const menuArray = [
		
		{"to":"/dashboard/home", "title":"Home","icon":faHome,"alert":homeAlert ,"class":""},
		// {"to":"/dashboard/trackers", "title":"Rastreadores","icon":faRobot,"alert":botsAlert},
		{"to":"/dashboard/map", "title":"Map","icon":faGlobe,"alert":exchangesAlert,"class":""},
		// {"to":"/dashboard/operators", "title":"Operadores","icon":faUsers,"alert":ordersAlert},
		{"to":"/dashboard/account", "title":"Conta","icon":faUserCircle,"alert":notificationsAlert ,"class":""},
		// {"to":"/dashboard/settings", "title":"Configurações","icon":faGear,"alert":settingsAlert},
	];

/*
	const [isMobile, setIsMobile] = useState(false);
	const handleResize = () => {
	  if (window.innerWidth < 720) {
	      setIsMobile(true)
	  } else {
	      setIsMobile(false)
	  }

	  setTimeout(()=>{
	  	if(document.querySelector('.css-1gnz8kn-MuiBottomNavigation-root'))
	  	document.querySelector('.css-1gnz8kn-MuiBottomNavigation-root').style.width = window.innerWidth + "px";
	  },500);

	}
	useEffect(() => {
	  handleResize();

	  

	},[])
	useEffect(() => {
	  window.addEventListener("resize", handleResize)
	})

	
	const navigate = useNavigate();
	const [menuvalue, menuvalueUpdate] = useState('/dashboard/home');
	const handleMenuChange = (event: React.SyntheticEvent, newValue: string) => {
		menuvalueUpdate(newValue);
		  navigate(newValue); 
		// history.push(newValue);
	};*/

	return (
		<>

		
        	<div className=" layout_dashboard_menumobile">

            	<MenuMobile>
            		{Object.keys(menuArray).map((key,index) => {
			 			


				 		return (
				 			
							<li key={index} className={ ( location.pathname.indexOf(menuArray[key].to) != -1 )?'active':'' }>
								<Link to={menuArray[key].to} className={menuArray[key].class}>
									<FontAwesomeIcon icon={(menuArray[key].icon)} />
									<span>{menuArray[key].title}</span>
								</Link>
							</li>
							
						);
					})}


            	</MenuMobile>

		    </div>
     
		
	        


		</>
	);
};


export default Component;