import React,{useState,useContext,useEffect} from "react";

import  {AuthContext} from "../../contexts/auth";	

import {addUserSession} from "../../services/apiSession";
import {md5} from "../../libs/md5";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUserPlus,faChevronLeft,faSignInAlt,faLocationArrow,faTimes,faTrash,faEdit,faPlus,faSortAmountDownAlt,faMapMarkerAlt,faUsers,faUserCircle,faGlobe,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'

import logolight from './logo.png';
// import "./style.css";


import AlertDialogDefault from "../../layout/alertdialog/default/";


const FormLogin = () => {


	const {authenticated, login} = useContext(AuthContext);

	const [fields,fieldsUpdate] = useState({});

	const [loading,loadingUpdate] = useState(false);

	const [email,setEmail] = useState("");
	const [password,setPassword] = useState("");

	const handleSubmit = async (e) =>{
		e.preventDefault();
		
		loadingUpdate(true);
		document.querySelector('.formstart .formregister .inputs').classList.add('disabled');
		e.target.querySelector('#signup').classList.add('loading');
		
		
		const passwordRegistred = fields.password;

		if(fields.password)
		fields.password = md5(fields.password);

	
        addUserSession(fields).then((response)=>{
			

			const status = response.data.status;
			
			if(status === 'already-exists'){

				alertDialogUpdate({
					"status":true,
					"title":"Não cadastrado",
					"description":"Escolha outro email",
					"yesCaption":"Ok",
					// "noCaption":"Ok",
					"yesHandle":(handleClose)=>{
						handleClose();						
					}/*,"noHandle":(handleClose)=>{
						handleClose();						
					},*/
				});

			}else if(status === 'success'){
				
				

				alertDialogUpdate({
					"status":true,
					"title":"Cadastrado com sucesso",
					"description":"Você tem 24h para validar seu cadastro através do link que enviamos ao seu email.",
					"yesCaption":"Entrar",
					"noCaption":"Ok",
					"noHandle":(handleClose)=>{

						fieldsUpdate({});
						document.querySelector('.layout_login form').reset();
						
						handleClose();
						setTimeout(()=>{
							document.querySelector('[name="formlogin"]').scrollIntoView();
						},500);
					},
					"yesHandle":(handleClose)=>{

						fieldsUpdate({});
						document.querySelector('.layout_login form').reset();

						handleClose();

						login(fields.email,passwordRegistred,()=>{
							setTimeout(()=>{
								if(document.querySelector('.formstart .formregister .inputs'))
								document.querySelector('.formstart .formregister .inputs').classList.remove('disabled');
								e.target.querySelector('#signup').classList.remove('loading');
							},3000);
						});
					}
				});

				

			
			}

			setTimeout(()=>{
				if(document.querySelector('.formstart .formregister .inputs'))
				document.querySelector('.formstart .formregister .inputs').classList.remove('disabled');
				e.target.querySelector('#signup').classList.remove('loading');
				loadingUpdate(false);
			},1000);
			
		}).catch((response)=>{
			
			setTimeout(()=>{
				document.querySelector('.formstart .formregister .inputs').classList.remove('disabled');
				e.target.querySelector('#signup').classList.remove('loading');
				loadingUpdate(false);
			},1000);

		});
        e.target.classList.remove('loading');
		


	}

	

	const [alertDialog,alertDialogUpdate] = useState({
		"status":false,
		"title":"",
		"description":"",
	});


	

	

	return (

		<div className="wrapper layout_login">
			{/*<div className="header">
				
				<Header/>
				
			</div>*/}
			<div className="main">
				
				<div className="line h100">
					<div className="column col6 sideInfo middle center mobile-h40 tablet-h40">
						<div className="wrapper">
							<div className="main">
								<div className="line h100">
									<div className="column col6 sideInfo desktop-middle mobile-bottom tablet-bottom h100  ">

										<img src={logolight} className="logo" />

										<h1>Onistation </h1>
										<br/>
										<p className="desktop-hide">Cadastrar</p>
										<br/>
										<br/>
										
										<AlertDialogDefault close={()=>{alertDialog.status = false; alertDialogUpdate({...alertDialog}); }}  handle={alertDialog}  />

									</div>
								</div>
							</div>
							<div className="footer left mobile-hide tablet-hide">
								<a href="https://wallrio.com" target="_blank">wallrio.com</a>
							</div>
						</div>
					</div>
					<div className="column sideForm desktop-middle center mobile-h60 tablet-h60">

						<div className="wrapper">
							<div className="main">
								<div className="line h100">
									<div className="column col6 desktop-middle center h100 ">
										
										<p className="mobile-hide tablet-hide">Cadastro</p>

										<div className="w100">			
			
											<form onSubmit={handleSubmit} className="desktop-w50 mobile-w90">
												
												
												<div className="inputs">
													<input 
														type="text" 
														name="name" 
														placeholder="Nome"
														required
														defaultValue={fields.name} 
														onChange={(e)=>{ fields.name = e.target.value; fieldsUpdate({...fields}); }}

														autoComplete="name"
														className="input w100"
													/>				

													<input 
														type="text" 
														name="username" 
														placeholder="Nome de usuário"
														required
														defaultValue={fields.username} 
														onChange={(e)=>{ fields.username = e.target.value; fieldsUpdate({...fields}); }}

														autoComplete="username"
														className="input w100"
													/>

													<input 
														type="text" 
														name="email" 
														placeholder="Email"
														required
														defaultValue={fields.email} 
														onChange={(e)=>{ fields.email = e.target.value; fieldsUpdate({...fields}); }}


														autoComplete="email"
														className="input w100"
													/>
													
													<input 
														type="text" 
														name="password" 
														placeholder="Senha"
														required
														defaultValue={fields.password} 
														onChange={(e)=>{ fields.password = e.target.value; fieldsUpdate({...fields}); }}


														autoComplete="password"
														className="input w100"
													/>
												</div>
												
											
												<div className="line mobile-nobreak">
													{/*<div className="column col6 middle center ">						
														<button type="submit" id="sigup" className="button -primary w100">Registrar</button>
													</div>*/}
													<div className="column middle right ">
														
													</div>
												</div>


												<div className="line mobile-nobreak tablet-nobreak desktop-w100 mobile-w100  controls">
													<div className="column col5 top center ">	
														<a href="#formlogin" type="submit" id="back" className="button -link w100">
															<FontAwesomeIcon icon={faChevronLeft} /> Voltar
														</a>					
														
													</div>
													<div className="column top right ">	
														<button type="submit" id="signup" className="button -purple w100">
															<FontAwesomeIcon icon={faUserPlus} /> Cadastrar
														</button>					
														
													</div>
												</div>

											</form>
											
										</div>



		
									</div>
								</div>
							</div>
							<div className="footer right desktop-hide ">
								<div className="line mobile-nobreak tablet-nobreak">
									<div className="column col6 left">
									
									</div>
									<div className="column ">										
										{/*<a href="https://wallrio.com"  className="button -link w100">wallrio.com</a>*/}					
									</div>
								</div>
							</div>
						</div>

						
					</div>
				</div>



			</div>
			{/*<div className="footer">
				<Footer/>
			</div>*/}
		</div>


		
	);
};


export default FormLogin;