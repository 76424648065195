const Component = () => {
	return (
		<>
			<ul className="headerItem notification">
				<li>
					<span>1</span>
				</li>
				<li>
					<span>2</span>
				</li>
				<li>
					<span>3</span>
				</li>
			</ul>
			
		</>
	);
}

export default Component;