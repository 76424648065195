import React, {useState, createContext, useEffect,useContext,useCallback} from "react";
import {AuthProvider,AuthContext} from "../../../contexts/auth";

import {Link} from "react-router-dom"

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLocationArrow,faTimes,faTrash,faEdit,faPlus,faSortAmountDownAlt,faMapMarkerAlt,faUsers,faUserCircle,faGlobe,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'


import QuestionInline from "../../../components/questioninline";

const Component = (props) =>{

	const {logout,user,location,global,globalUpdate} = useContext(AuthContext);

	const handleLogout = () =>{
		logout();
	}

	const handleSimulateRoute = () =>{
		props.handleMenu('');
	}


	return (
		<>
			<ul className="headerItem menu">
				{/*<li>
					<Link to={"/dashboard/simulateroute"} onClick={()=>{ handleSimulateRoute() }}>
						<div className="line mobile-nobreak tablet-nobreak">
							<div className="column col0 padding">
		    					<FontAwesomeIcon icon={faLocationArrow} /> 
							</div>
							<div className="column">
								Simulador de rotas
							</div>
						</div>
					</Link>
				</li>*/}
				<li>
					<QuestionInline className="w100">
						<div question="">
							<a className={"link padding w100 left" }  >
								<div className="line mobile-nobreak tablet-nobreak">
									<div className="column col0 padding">
				    					<FontAwesomeIcon icon={faSignOut} /> 
									</div>
									<div className="column">
										Sair
									</div>
								</div>
				    		</a>
						</div>
						<div alternatives="">

								<div response="yes" className="w100">
									<a className={"link padding w100 left" } onClick={(e)=>{ handleLogout() }} >
						    			<div className="line mobile-nobreak tablet-nobreak">
											<div className="column col0 padding">
						    					<FontAwesomeIcon icon={faTimes} /> 
											</div>
											<div className="column">
												Sair agora
											</div>
										</div>
						    		</a>
								</div>

		
						</div>
					</QuestionInline>

				</li>
			</ul>
		</>
	);
}

export default Component;