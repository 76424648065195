import React, {useState, createContext, useEffect,useContext,useRef} from "react";
import {AuthProvider,AuthContext} from "../../../../../contexts/auth";


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSave,faBan,faTrash,faSortAmountDownAlt,faPlus,faEdit,faTimes,faFlag,faThumbtack,faMapMarkerAlt,faUsers,faUserCircle,faGlobe,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'

import {setMarker,listMarker,deleteMarker,updateMarker} from "../../../../../services/apiMarker";


const TrackersBar = (props) => {

	const inputRef = useRef(null);

    const {logout,user,location,global,globalUpdate,action,actionUpdate,view,viewUpdate} = useContext(AuthContext);

    const [countOnline,countOnlineUpdate] = useState(0);

	const settingsClose = () =>{
        view.map.settings = undefined;   
        viewUpdate({...view});
    }

    const selectDevideHandle = (e,key) =>{

    	if( props.devices[key].lastposition === null){    		
    		return false;
    	}

        let name = props.devices[key].name;
	        let latitude = props.devices[key].lastposition.split(',')[0];
	        let longitude = props.devices[key].lastposition.split(',')[1];



      
// global.map.handle.getZoom()
        global.map.handle.setView([latitude,longitude], 15, {
              animate: true,
            });



        let trackersBar_content = inputRef.current.querySelector('.TrackersBar_content');
        let parentEl = null;
        if( e.target.tagName === 'A'){
        	parentEl = e.target;
        }else if( e.target.parentElement.parentElement.tagName === 'A'){
        	parentEl = e.target.parentElement.parentElement;
        }else if( e.target.parentElement.parentElement.parentElement.tagName === 'A'){
        	parentEl = e.target.parentElement.parentElement.parentElement;
        }
        let elPosLeft =  (parentEl.offsetLeft + (parentEl.offsetWidth) - (parentEl.offsetWidth/2)) - ( trackersBar_content.offsetWidth/2 ) ;
        trackersBar_content.scrollTo(elPosLeft,0);

		setTimeout(()=>{			
			if(typeof global.map.popups[name] !== 'undefined') global.map.popups[name].openOn();
		},500);

     /*   let lis = trackersBar_content.querySelectorAll('li a');
        for (var i = 0; i < lis.length; i++) {
        	lis[i].classList.remove('active');
        }
        parentEl.classList.add('active');
        */
    }

    
    useEffect(()=>{

    	let countOnlinePre = 0;
    	for(let key in props.devices){
    		if(props.devices[key].online === true)countOnlinePre++;
    	}

    	countOnlineUpdate(countOnlinePre);

    },[props.devices]);

    return (
		<>
			<div className={"TrackersBar"} ref={inputRef} >
				<div className="TrackersBar_area">
			
					{/*<div className="line mobile-nobreak tablet-nobreak SettingsMap_header">
						<div className="column left middle">
							<h3>Rastreadores</h3>				
						</div>	
						
						
						<div className="column col1 right middle">
							<button className="link  padding-h-10" onClick={()=>{ settingsClose() }}>
								<FontAwesomeIcon icon={faTimes} />
							</button>
						</div>	
					

											
					</div>	*/}

					<div className="line mobile-nobreak tablet-nobreak ">
						<div className="column col0 left middle padding-10 areacount">
							<small>{countOnline}/{Object.keys(props.devices).length}</small>		
						</div>	
						
						
						<div className="column  right middle">
							<div className="TrackersBar_content hsticky  scrollhidden">					
								<div className="hlisting vw100">
									<ul className="">
										{ Object.keys(props.devices).map((key,index)=>{

											let color = props.devices[key].color;
											let name = props.devices[key].name;
											let online = props.devices[key].online;
											let positiondata = props.devices[key].positiondata;

											{/*let opacity = Object.keys(positiondata).length<1?0.4:1;*/}
											let opacity = 1;

											let onlineStatus = 'offline';
											if(online === true) onlineStatus = 'online';

											return(
												<li key={key} style={{"opacity":opacity}}>
													<a onClick={(e)=>{ selectDevideHandle(e,key)}} tabIndex="-1" >

														<div className={"line mobile-nobreak tablet-nobreak "+onlineStatus}>
															<div className="column middle">
																<span style={{"backgroundColor":color}} ></span>
															</div>
															<div className="column middle left">
																<label>{name}</label>
															
																{props.devices[index].online === true && (
																	<>
																		{(props.devices[index].parked ) && (
																			<small className="-primary-text">Estacionado</small>
																		)}

																		{ ( (props.devices[index].parked === '' || props.devices[index].parked === null) && props.devices[index].lastspeed > 3) && (
																			<small className="-success-text">
																			<strong>{props.devices[index].lastspeed?props.devices[index].lastspeed:'?'} km</strong> - Movendo 
																			
																			</small>
																		)}

																		{ ( (props.devices[index].parked === '' || props.devices[index].parked === null) && props.devices[index].lastspeed <= 3) && (
																			<small className="-success-text">Parado</small>
																		)}
																	</>
																)}

																{props.devices[index].online === false && (
																	<small className="-error-text">Sem sinal</small>
																)}


															</div>
														</div>
														
														
														
													</a>
												</li>
											)
										})}						





									</ul>
								</div>		
							</div>
						</div>	
					
											
					</div>
				</div>
			</div>
		</>
    );
}

export {TrackersBar}
