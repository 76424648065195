
import './App.css';

import AppRouters from "./AppRouters"

import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <div className="App">     
		<SnackbarProvider 
			
			maxSnack={3} 
			autoHideDuration={3000} 
			anchorOrigin={{
	            vertical: 'top',
	            horizontal: 'right',
	          }}

	  
	        >
			<AppRouters/>   
		</SnackbarProvider>   
    </div>
  );
}

export default App;