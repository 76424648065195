import React,{useState,useContext} from "react";

import  {AuthContext} from "../../contexts/auth";	

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUserPlus,faSignInAlt,faLocationArrow,faTimes,faTrash,faEdit,faPlus,faSortAmountDownAlt,faMapMarkerAlt,faUsers,faUserCircle,faGlobe,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'

import logolight from './logo.png';
// import "./style.css";

import { useSnackbar } from 'notistack';

const FormLogin = () => {

	const { enqueueSnackbar } = useSnackbar();

	const {authenticated, login} = useContext(AuthContext);

	const [email,setEmail] = useState("");
	const [password,setPassword] = useState("");

	const handleSubmit = (e) =>{
		
		document.querySelector('.formstart .formlogin .inputs').classList.add('disabled');

		e.target.querySelector('#sigin').classList.add('loading');
		e.preventDefault();
		
		login(email,password,(response)=>{

			const status = response.data.status;
			if(status === 'success'){

				e.target.querySelector('#sigin').classList.add('-success');

				setTimeout(()=>{
					if(document.querySelector('.formstart .formlogin .inputs'))
					document.querySelector('.formstart .formlogin .inputs').classList.remove('disabled');
					e.target.querySelector('#sigin').classList.remove('loading');
					e.target.querySelector('#sigin').classList.remove('-error');
				},1000);

				enqueueSnackbar('Acesso liberado.', { variant: "success"});

			}else{

				if(document.querySelector('.formstart .formlogin .inputs'))
					document.querySelector('.formstart .formlogin .inputs').classList.remove('disabled');
				
				e.target.querySelector('#sigin').classList.add('-error');
				e.target.querySelector('#sigin').classList.remove('loading');
				setTimeout(()=>{
					e.target.querySelector('#sigin').classList.remove('-error');
				},1000);

				enqueueSnackbar('Acesso negado.', { variant: "error"});
			}
		});
	}
	
	return (

		<div className="wrapper layout_login">
			{/*<div class="header">
				
				<Header/>
				
			</div>*/}
			<div className="main">
				
				<div className="line h100">
					<div className="column col6 sideInfo middle center mobile-h40 tablet-h40">
						<div className="wrapper">
							<div className="main">
								<div className="line h100">
									<div className="column col6 sideInfo desktop-middle mobile-bottom tablet-bottom  h100  ">

										<img src={logolight} className="logo" />

										<h1>Onistation</h1>
										{/*<p>Rastreamento veicular 24h</p>*/}
										<br/>
										<br/>
									</div>
								</div>
							</div>
							<div className="footer left mobile-hide tablet-hide">
								<a href="https://wallrio.com" target="_blank">wallrio.com</a>
							</div>
						</div>
					</div>
					<div className="column sideForm desktop-middle mobile-top tablet-top center mobile-h60 tablet-h60">

						<div className="wrapper">
							<div className="main">
								<div className="line h100">
									<div className="column col6 desktop-middle mobile-top tablet-top center h100 ">
										

										<div className="w100">			
			
											<form onSubmit={handleSubmit} className="desktop-w50 tablet-w50 mobile-w90">
												
												<div className="line mobile-nobreak tablet-nobreak ">
													<div className="column left">
														<a href="#formregister" className="button -link w100">
															<FontAwesomeIcon icon={faUserPlus} /> Cadastrar
														</a>	
													</div>
													<div className="column right">
														<a href="#formremember"  className="button -link w100">Redefinir senha</a>					
													</div>
												</div>

												{/*<label>Logon</label>*/}
												<div className="inputs">
													<input 
														type="text" 
														name="username" 
														placeholder="Email"
														value={email}
														required
														onChange={(e)=>setEmail(e.target.value)}
														autoComplete="username"
														className="input w100"
													/>
													{/*<label>Password</label>*/}
													<input 
														type="password" 
														name="password" 
														placeholder="Senha"
														value={password}
														required
														onChange={(e)=>setPassword(e.target.value)}
														autoComplete="password"
														className="input w100"
													/>
												</div>
												
											{/*	<div class="input-float flatmodel">
												      <input type="text" />
												      <label >User ID</label>
												</div>*/}

												<div className="line mobile-nobreak">
													{/*<div class="column col6 middle center ">						
														<button type="submit" id="sigup" className="button -primary w100">Registrar</button>
													</div>*/}
													<div className="column middle right ">
														
													</div>
												</div>


												<div className="line mobile-nobreak tablet-nobreak   controls">
													<div className="column col5 top center ">	
														{/*<a href="#formregister" className="button -link w100">
															<FontAwesomeIcon icon={faUserPlus} /> Cadastrar
														</a>	*/}				
														
													</div>
													<div className="column top right ">	
														<button type="submit" id="sigin" className="button -purple w100">
															<FontAwesomeIcon icon={faSignInAlt} /> Entrar
														</button>					
														
														
													</div>
												</div>

											</form>
											
										</div>




									</div>
								</div>
							</div>
							<div className="footer  left ">
								<div className="line mobile-nobreak tablet-nobreak ">
									<div className="column col6 left desktop-hide">				
									</div>
									<div className="column">										
										{/*<a href="https://wallrio.com"  className="button -link w100">wallrio.com</a>*/}
									</div>
								</div>
							</div>
						</div>

						
					</div>
				</div>



			</div>
			{/*<div class="footer">
				<Footer/>
			</div>*/}
		</div>


		
	);
};


export default FormLogin;