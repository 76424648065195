import React, { useState, useEffect, useRef,useContext,createContext } from 'react';
// import React, {useState, createContext, useEffect,useContext} from "react";
import {AuthProvider,AuthContext} from "../../../contexts/auth";

import { MapContainer, TileLayer, useMap,Marker,Popup ,Polyline,Tooltip,Circle} from 'react-leaflet'



import L from 'leaflet';


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLocationArrow,faFlag,faThumbtack,faMapMarkerAlt,faUsers,faUserCircle,faGlobe,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'

import myposition from "./draw/icons/myposition.png";

const LocationMarker = (props) => {

	const {logout,user,location,global,globalUpdate,action,actionUpdate,view,viewUpdate} = useContext(AuthContext);

    const [position, setPosition] = useState(null);


    const [info, infoUpdate] = useState([]);

    const map = useMap();

    const handlePermission = () => {
		  navigator.permissions.query({ name: 'geolocation' }).then((result) => {
		      
		    if (result.state === 'granted') {
		      // report(result.state);
		      // geoBtn.style.display = 'none';
		    } else if (result.state === 'prompt') {
		      // report(result.state);
		      // geoBtn.style.display = 'none';
		      // navigator.geolocation.getCurrentPosition(revealPosition,positionDenied,geoSettings);
		    } else if (result.state === 'denied') {
		      // report(result.state);
		      // geoBtn.style.display = 'inline';
		      alert('O recurso do GPS foi negado');

		    
				view.map.mylocate = undefined;			
				viewUpdate({...view});	

		    }
		    result.addEventListener('change', () => {
		      console.log(result.state);
		    });
		  });
	}

    const panMyViewHandle = () => {
    	
    	let latitude = info.latitude;
		let longitude = info.longitude;

		// let zoom = global.map.handle.getZoom();

    	global.map.handle.setView([latitude,longitude], 15, {
          animate: true,
        });
    }

    const getPositionHandle = () => {

    	if(view.map.mylocate === undefined) return;

    
    	if (navigator.geolocation) {

			navigator.geolocation.getCurrentPosition((position)=>{
				let accuracy = position.coords.accuracy;
				let latitude = position.coords.latitude;
				let longitude = position.coords.longitude;
				let altitude = position.coords.altitude;
				let heading = position.coords.heading;
				let speed = position.coords.heading;

				

				infoUpdate(position.coords);

				setPosition([
					latitude,
					longitude
				]);

				setTimeout(()=>{
					getPositionHandle();
				},500);

			},(e)=>{
				// alert('Ocorreu um erro ao capturar o GPS');
			});
		} else {

		}

		
    }

    useEffect(() => {
    	handlePermission();
    	getPositionHandle();   
    }, []);

    let heading = 0;
    let htmlDeviceIcon = '<img style="transform: rotate('+heading+'deg);" height="34" width="34" src="'+myposition+'">';                    

    var icon = L.divIcon({
        iconSize: [40, 40],
        iconAnchor: [25, 25],
        popupAnchor: [-5, -10],
        className: '',
        html: htmlDeviceIcon
    });

    return position === null ? null : (
    	<>
	    	<div className="myLocateDisplay">
	    		<button className="link  padding " onClick={(e)=>{ panMyViewHandle()}} >
	    			<FontAwesomeIcon icon={faLocationArrow} />
	    		</button>
	    	</div>

			<Marker position={position} icon={icon}>
				<Popup o>

					<h3>Você esta aqui</h3>

					<table>
		                <tbody>
		                  
	                        <tr>
	                            <th>latitude</th><td>{info.latitude}</td>
	                        </tr>
	                        <tr>
	                            <th>longitude</th><td>{info.longitude}</td>
	                        </tr>
	                        <tr>
	                            <th>Direção</th><td>{info.heading}</td>
	                        </tr>
		                  
	                        <tr>
	                            <th>Velocidade</th><td>{info.speed}</td>
	                        </tr>
		                  
	                        <tr>
	                            <th>Sinal</th><td>{info.accuracy}</td>
	                        </tr>
		                  
		                </tbody>
		            </table>

				</Popup>
			</Marker>
    	</>
    );
}

export {LocationMarker}