import { MapContainer, TileLayer, useMap,Marker,Popup ,Polyline,Tooltip,Circle} from 'react-leaflet'

import L from "leaflet";

import {PopupMap} from "../PopupMap";

import {diffDateToHuman,geoTodistance} from "../../../../libs/misc";

import {getDateLastPosition} from "../getDateLastPosition";

const DrawTags = (props) => {

	const handleGooglemap = (position) =>{
        
        let latitude = position[0];
        let longitude = position[1];
    
        window.open('https://www.google.com/maps/dir//'+latitude+','+longitude+'/@'+latitude+','+longitude+',15z');
    }

    return (
		<>
			{Object.keys(props.devices).map((key,index) => {
                    
                        {/*|| ( props.devices[key].positiondata[0] !== undefined && props.devices[key].positiondata[0].length <= 1) */}
                    if(
                        props.devices[key].positiondata === undefined 

                        ) 
                        return(<div key={key}></div>);
                    

                    return (
                        <span key={key}>
                            {Object.keys(props.devices[key].positiondata).map((key2,index2) => {

                                 // impede a exibição da tag no ultimo ponto caso o veiculo esteja em movimento
                                {/*if( !props.devices[key].parked &&
                                    Object.keys(props.devices[key].positiondata).length-1 === index2) return;*/}

                                if( Object.keys(props.devices[key].positiondata).length-1 === index2) return;
                                    

                                var PointIcon = L.divIcon({
                                    iconSize: [40, 40],
                                    iconAnchor: [20, 50],
                                    className: '',
                                    html: ``,
                                }) 

                                {/*console.log(props.devices[key].name);*/}
                                let responseDateLastPosition = getDateLastPosition(props.devices[key].positiondata,index2,props.devices[key].name);
                                
                                let parkedTime = responseDateLastPosition['parkedTime'];
                                let arrivalTime = responseDateLastPosition['arrivalTime'];
                                let exitTime = responseDateLastPosition['exitTime'];
                                let travelTime = responseDateLastPosition['travelTime'];
                                let departureTime = responseDateLastPosition['departureTime'];
                                let geoDistance = responseDateLastPosition['geoDistance'];
                                let geoDistanceTotal = responseDateLastPosition['geoDistanceTotal'];
                                let geopos = responseDateLastPosition['geopos'];
                                let subtitle = responseDateLastPosition['subtitle'];


                                return (
                                    <span key={key2}>
                                   

                                        <Marker 
                                        icon={PointIcon} 
                                        position={geopos}
                                        eventHandlers={{
                                            click: (e) => {
                                              
                                            },
                                          }}

                                        >                                            
                                            <Popup offset={[0, -30]}>

                                                <PopupMap 
                                                    marker={true}
                                                    subtitle={subtitle}
                                                    //imei={props.devices[key].imei} 
                                                    color={props.devices[key].color} 
                                                    name={props.devices[key].name} 
                                                    //speed={props.devices[key].positiondata[key2][pos].speed} 
                                                    // lastonline={parseInt(props.devices[key].positiondata[key2][0].created)} 
                                                    //lastupdate={props.devices[key].positiondata[key2][pos].date.split(' ')[1]} 
                                                    lastposition={geopos}  
                                                    parkedTime={parkedTime}  
                                                    arrivalTime={arrivalTime}  
                                                    exitTime={exitTime}  
                                                    travelTime={travelTime}  
                                                    departureTime={departureTime}  
                                                    geoDistance={geoDistance}  
                                                    geoDistanceTotal={geoDistanceTotal}  
                                                />

                                               
                                              
                                                
                                                
                                            </Popup>
                                            <Tooltip direction="top" offset={[0, -10]} className={"PointIcon -"+props.devices[key].color} opacity={props.devices[key].optionsMult[index2].opacitytag} permanent>{index2+1}</Tooltip>
                                        </Marker>

                                    </span>
                                );
                            })}
                        </span>
                    );
                })}
		</>
    );
}

export {DrawTags}
