const splitroute =  (idTracker,route,data) => {

	var newRoute = [];
	var newRoute_complete = [];
	var index = 0;

	let parkingDuration = 60*5;

	
	const routeHandle = (mode,idTracker,route,data) =>{
		
		let newRoute_complete_last = [0,0];
		let index2 = 0;
		let index3 = 0;
		let index4 = 0;
		let count = 0;
		let newRouteAjust = [];
		let newRouteAjustData = [];
		for(let key in route){		

			var time = parseFloat(data[key].created);
			var speed = data[key].speed;
			// var speed = data[key].ce;
			
			if(mode === 'default'){
				if(speed < 5) continue;
			}


			if(typeof timeLast1 === 'undefined'){
				var diffTime = 0;
			}else{
				var diffTime = time-timeLast1;
			}
			if(key>0){
				var timeLast1 = parseFloat(data[key].created);
				var datPrev = route[key];
			}else{
				var datPrev = {};
				var timeLast1 = time;
			}

			diffTime = Math.abs(diffTime);

			
			// separa trajeto conforme tempo parado
			if(diffTime>parkingDuration){		
				index2 = 0;
				count++;

			}



			if(newRouteAjustData[count] === undefined ) newRouteAjustData[count] = [];
			if(newRouteAjust[count] === undefined ) newRouteAjust[count] = [];

			index3 = newRouteAjust[count].length;

			// ajusta linha para iniciar no final da ultima
			if(index3 === 0){		
				if(index4 > 0){				
					// newRouteAjust[count].push(route[index4-1]);	
					// newRouteAjustData[count].push(data[index4-1]);		
				}
			}


			// console.log(positionCurrent,count);

			// if( count === 1 ) continue;
			// if( positionCurrent !== null && positionCurrent-1 !== count+1) continue;



			newRouteAjust[count].push(route[key]);
			newRouteAjustData[count].push(data[key]);

			index2++;
			index4++;
			
			newRoute_complete_last = route[key];
			if(mode === 'full'){
				break;
			}
		}

		return [newRouteAjust,newRouteAjustData,newRoute_complete_last];
	}


	let [newRouteAjust,newRouteAjustData,newRoute_complete_last] = routeHandle('default',idTracker,route,data);

	if( Object.keys(newRouteAjustData).length < 1 ){

		// let [newRouteAjust,newRouteAjustData,newRoute_complete_last] = routeHandle('full',idTracker,route,data);
		var routeResponse = routeHandle('full',idTracker,route,data);


		newRouteAjust = routeResponse[0];
		newRouteAjustData = routeResponse[1];
		newRoute_complete_last = routeResponse[2];
	}
	
	/*delete newRouteAjust[0];
	delete newRouteAjust[1];*/

	// console.log(newRouteAjust);

	return {
		// complete:newRoute_complete,
		limited:newRouteAjust,
		limiteddata:newRouteAjustData
	};

}

export {splitroute}
