import { env } from '../.env.js';
import axios from "axios";

// import {getUniqueID} from "../libs/getuniqueid.js";
// import  {md5} from "../libs/md5";	

export const api = axios.create({
	baseURL: env.AchoordURL
});


// session 
export const setFavorite = async (parameters) => {
/* 	let date = parameters['date'];
	let title = parameters['title'];
	let description = parameters['description'];
	 */
	var bodyFormData = new FormData();

	for(let key in parameters){
		bodyFormData.append(key, parameters[key]);
	}
	// let data = parameters;

	return api.post("/favorite/setfavorite/",parameters,{
	  headers: {
	  	'account': window.username,
	  		// "Content-Type": "multipart/form-data"
	    // 'hashuser': hashuser
	  }
	});

}

export const listFavorite = async () => {
	
	return api.get("/favorite/listfavorite/",{
	  headers: {
	  	'account': window.username,
	  		// "Content-Type": "multipart/form-data"
	    // 'hashuser': hashuser
	  }
	});

}

export const deleteFavorite = async (id) => {
	
	return api.delete("/favorite/deletefavorite/?id="+id,{
	  headers: {
	  	'account': window.username,
	  		// "Content-Type": "multipart/form-data"
	    // 'hashuser': hashuser
	  }
	});

}