import React, {useState, createContext, useEffect,useContext,useCallback} from "react";
import {AuthProvider,AuthContext} from "../../../contexts/auth";

import {Link} from "react-router-dom"


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes,faStar,faChevronLeft,faTrash,faBars,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'
import {faStar as farStar} from '@fortawesome/free-regular-svg-icons'

import {addDevice,delDevices,getDevices,setDevice} from "../../../services/apiTrackers";

import { useSnackbar } from 'notistack';

const Component = (props) =>{

	const { enqueueSnackbar } = useSnackbar();

	const {logout,user,location,global,globalUpdate,header,headerUpdate} = useContext(AuthContext);

	const [mode,modeUpdate] = useState('add');
	const [fields,fieldsUpdate] = useState({});

	const handleLogout = () =>{
		logout();
	}

	const handleSimulateRoute = () =>{
		props.handleMenu('');
	}

	
	const addDeviceHandle = async () =>{

		enqueueSnackbar('Adicionando rastreador', { variant: "info",autoHideDuration:1000});

        const response = await addDevice({
			imei:fields.imei,
			name:fields.name,
			color:fields.color,
			type:fields.type,
			timezone:fields.timezone,
			password:fields.password,
			ddi:fields.ddi,
			ddd:fields.ddd,
			phone:fields.phone,
			apnaddress:fields.apnaddress,
			apn:fields.apn,
			apnusername:fields.apnusername,
			apnpassword:fields.apnpassword,
		});

        fieldsUpdate({});
		header.getDevices('uniquerequest');	
		props.handleMenu('');

		enqueueSnackbar('Rastreador adicionado', { variant: "success",autoHideDuration:1000});
    }

	const saveDeviceHandle = async () =>{
		
		enqueueSnackbar('Salvando rastreador', { variant: "info",autoHideDuration:1000});

		let imei = fields.imei;

        const response = await setDevice(imei,{
			imei:fields.imei,
			name:fields.name,
			color:fields.color,
			type:fields.type,
			timezone:fields.timezone,
			password:fields.password,
			ddi:fields.ddi,
			ddd:fields.ddd,
			phone:fields.phone,
			apnaddress:fields.apnaddress,
			apn:fields.apn,
			apnusername:fields.apnusername,
			apnpassword:fields.apnpassword,
		});

        fieldsUpdate({});
		header.getDevices('uniquerequest');	
		props.handleMenu('');

		enqueueSnackbar('Rastreador salvo', { variant: "success",autoHideDuration:1000});
    }

    const delDeviceHandle = async (id,callback) => {
		
		enqueueSnackbar('Removendo rastreador', { variant: "info",autoHideDuration:1000});
	
		const response = await delDevices(id);

		let status = response.data.status;
		let body = response.data.body;
	
		header.getDevices('uniquerequest');	
		props.handleMenu('');
		if(callback)callback();

		enqueueSnackbar('Rastreador removido', { variant: "success",autoHideDuration:1000});

	}

    const editDevicesHandle = async (imei,callback) => {
		

		modeUpdate('edit');
		// console.log(imei);
	
		const response = await getDevices(imei);

		let status = response.data.status;
		let body = response.data.body;
		
		// console.log('--',body);

		fieldsUpdate({...body[0]});

		
		enqueueSnackbar('Rastreador salvo', { variant: "success",autoHideDuration:1000});
		// header.getDevices('uniquerequest');	
		// props.handleMenu('');
		if(callback)callback();
	}

	useEffect(()=>{	
		header.modeEditDevice = (mode)=>{
			fieldsUpdate({});
			modeUpdate(mode);
		};		
		header.editDevices = editDevicesHandle;		
		header.delDevices = delDeviceHandle;		
		headerUpdate({...header});
	},[]);

	const handleSubmit = (e) =>{
		e.preventDefault();
		
		if(mode==='add'){
			addDeviceHandle();
		}else{
			saveDeviceHandle();
		}

	}

		return (
		<>			
			<form onSubmit={handleSubmit} className="headerItem admdevice">
				<div className="line mobile-nobreak headerTitle">
					<div className="column middle left">
						<button type="button" className="link  padding" onClick={()=>{props.handleMenu('');}}>
			    			<FontAwesomeIcon icon={faTimes} />
			    		</button>
			    		
			    		
			    	</div>
			    	<div className="column middle right">					    		
			    	</div>
			    	<div className=" column middle right">
			    		<button className="link  padding" >
			    			{mode==='add'?'Novo':'Editar'} Rastreador
			    		</button>
			    	</div>
			    </div>

			    <div name="admdevice" className="sticky vh50  ">
				    <div className="line mobile-nobreak padding">
						<div className="column middle left">
								
							

							{mode==='add'?(
								<div className="input-float flatmodel">
								      <input type="text" required key={fields.imei}  defaultValue={fields.imei} onBlur={(e)=>{ fields.imei = e.target.value; fieldsUpdate({...fields}); }}   />
								      <label >IMEI</label>
								</div>
							):(
								<div className="input-float flatmodel">
								      <input type="text" className="disabled" required readOnly key={fields.imei} defaultValue={fields.imei} onBlur={(e)=>{ fields.imei = e.target.value; fieldsUpdate({...fields}); }}  />
								      <label >IMEI</label>
								</div>
							)} 

							


							<div className="input-float flatmodel">
							      <input type="text" required key={fields.name} defaultValue={fields.name} onBlur={(e)=>{ fields.name = e.target.value; fieldsUpdate({...fields}); }} />
							      <label >Nome do veículo</label>
							</div>
									
							
							<select className="select input-float flatmodel w100 margin" required key={fields.color} defaultValue={fields.color} onBlur={(e)=>{ fields.color = e.target.value; fieldsUpdate({...fields}); }}>
								<option disabled>Cor</option>
								<option value="red">Vermelho</option>
								<option value="green">Verde</option>
								<option value="blue">Azul</option>
								<option value="pink">Rosa</option>
								<option value="black">Preto</option>
							</select>

							<div className="input-float flatmodel">
							      <input type="text" key={fields.type} defaultValue={fields.type} onBlur={(e)=>{ fields.type = e.target.value; fieldsUpdate({...fields}); }} />
							      <label >Tipo de rastreador</label>
							</div>

							<div className="input-float flatmodel">
							      <input type="text" key={fields.password} defaultValue={fields.password} onBlur={(e)=>{ fields.password = e.target.value; fieldsUpdate({...fields}); }} />
							      <label >Senha do rastreador</label>
							</div>

							<select className="select input-float flatmodel w100 margin"  key={fields.timezone} defaultValue={fields.timezone} onBlur={(e)=>{ fields.timezone = e.target.value; fieldsUpdate({...fields}); }}>
								<option>Fuso Horário</option>
								<option value="America/Fortaleza">America/Fortaleza</option>
								<option value="item3">item 3</option>
								<option value="item4">item 4</option>
							</select>

							<div className="input-float flatmodel">
							      <input type="text" key={fields.ddi} defaultValue={fields.ddi} onBlur={(e)=>{ fields.ddi = e.target.value; fieldsUpdate({...fields}); }} />
							      <label >DDI</label>
							</div>

							<div className="input-float flatmodel">
							      <input type="text" key={fields.ddd} defaultValue={fields.ddd} onBlur={(e)=>{ fields.ddd = e.target.value; fieldsUpdate({...fields}); }} />
							      <label >DDD</label>
							</div>

							<div className="input-float flatmodel">
							      <input type="text" key={fields.phone} defaultValue={fields.phone} onBlur={(e)=>{ fields.phone = e.target.value; fieldsUpdate({...fields}); }} />
							      <label >Número</label>
							</div>

							<div className="input-float flatmodel">
							      <input type="text" key={fields.apnaddress} defaultValue={fields.apnaddress} onBlur={(e)=>{ fields.apnaddress = e.target.value; fieldsUpdate({...fields}); }} />
							      <label >APN address</label>
							</div>
							
							<div className="input-float flatmodel">
							      <input type="text" key={fields.apn} defaultValue={fields.apn} onBlur={(e)=>{ fields.apn = e.target.value; fieldsUpdate({...fields}); }} />
							      <label >APN</label>
							</div>

							<div className="input-float flatmodel">
							      <input type="text" key={fields.apnusername} defaultValue={fields.apnusername} onBlur={(e)=>{ fields.apnusername = e.target.value; fieldsUpdate({...fields}); }} />
							      <label >APN username</label>
							</div>

							<div className="input-float flatmodel">
							      <input type="text" key={fields.apnpassword} defaultValue={fields.apnpassword} onBlur={(e)=>{ fields.apnpassword = e.target.value; fieldsUpdate({...fields}); }} />
							      <label >APN password</label>
							</div>



							

				    	</div>
				    </div>
			    </div>

			    <div className="line mobile-nobreak padding">
					<div className="column middle left">

						{mode==='add'?(
							<button className="link -primary padding" >
				    			Adicionar
				    		</button>
						):(
							<button className="link -primary padding" >
				    			Salvar
				    		</button>
						)} 
						

			    	</div>
			    </div>

	
			</form>
				
		</>
	);
};


export default Component;