// import Header from "../../layout/header/index.jsx";
// import Footer from "../../layout/footer/index.jsx";
// import FormLogin from "../../layout/formlogin/index.jsx";

import {useLocation} from "react-router-dom";

// import "./style.css";

const DashboardNotFoundPage = () => {

	const location = useLocation();

	return (
		<div className="line">
			<div className="column middle">
				<h1>404</h1>
				<p>Página não encontrada</p>
				<span><strong>{location.pathname}</strong></span>
			</div>
		</div>

	);
};


export default DashboardNotFoundPage;
