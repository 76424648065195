import { env } from '../.env.js';
import axios from "axios";

// import {getUniqueID} from "../libs/getuniqueid.js";
// import  {md5} from "../libs/md5";	

export const api = axios.create({
	baseURL: env.AchoordURL
});


// session 
export const setMarker = async (parameters) => {
	// let date = parameters['date'];
	// let title = parameters['title'];
	// let description = parameters['description'];
	
	var bodyFormData = new FormData();

	for(let key in parameters){
		bodyFormData.append(key, parameters[key]);
	}

	// console.log(parameters);
	// let data = parameters;

	// return api.put("http://admin.achoord.com/api/v1/accounts/wallrio/onistation/p/apigateway/api/call/marker/setmarker/",bodyFormData,{
	return api.post("/marker/setmarker/",parameters,{
	  headers: {
	  		"Content-Type": "application/json",
	  		'account': window.username,
	  		// "Content-Type": "multipart/form-data"
	    // 'hashuser': hashuser
	  }
	});

}

export const updateMarker = async (id,parameters) => {
	let date = parameters['date'];
	let title = parameters['title'];
	let description = parameters['description'];
	
	var bodyFormData = new FormData();

	for(let key in parameters){
		bodyFormData.append(key, parameters[key]);
	}
	// let data = parameters;
	// return api.put("http://admin.achoord.com/api/v1/accounts/wallrio/onistation/p/apigateway/api/call/marker/updatemarker/",bodyFormData,{
	return api.put("/marker/updatemarker/",bodyFormData,{
	  headers: {
	  	// "Content-Type": "multipart/form-data",
	  	// "Content-Type": "application/x-www-form-urlencoded",
	  	"Content-Type": "application/json",
	    'account': window.username,
	    'id': id,
	  }
	});

}

export const listMarker = async () => {
	
	return api.get("/marker/listmarker/",{
	  headers: {
	  	'account': window.username,
	  		// "Content-Type": "multipart/form-data"
	    // 'hashuser': hashuser
	  }
	});

}

export const deleteMarker = async (id) => {
	
	return api.delete("/marker/deletemarker/",{
	  headers: {
	  	'account': window.username,
	  	'id': id
	  		// "Content-Type": "multipart/form-data"
	    // 'hashuser': hashuser
	  }
	});

}