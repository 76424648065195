import React, {useState, createContext, useEffect,useContext} from "react";
import {AuthProvider,AuthContext} from "../../../../../contexts/auth";


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck,faSave,faBan,faTrash,faSortAmountDownAlt,faPlus,faEdit,faTimes,faFlag,faThumbtack,faMapMarkerAlt,faUsers,faUserCircle,faGlobe,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'

import {setMarker,listMarker,deleteMarker,updateMarker} from "../../../../../services/apiMarker";

import QuestionInline from "../../../../../components/questioninline";
// import { useMap, useMapEvents,useMapEvent } from 'react-leaflet/hooks'

import markerIcon_default from '../../draw/icons/marker/default.png';
import markerIcon_home from '../../draw/icons/marker/home.png';
import markerIcon_hospital from '../../draw/icons/marker/hospital.png';
import markerIcon_gasstation from '../../draw/icons/marker/gasstation.png';
import markerIcon_park from '../../draw/icons/marker/park.png';
import markerIcon_restaurant from '../../draw/icons/marker/restaurant.png';
import markerIcon_transport from '../../draw/icons/marker/transport.png';
import markerIcon_work from '../../draw/icons/marker/work.png';

import {geoToAddressHandle} from "../../../../../libs/geoToAddressHandle.jsx";

import { useSnackbar } from 'notistack';

const Marker = (props) => {

	const { enqueueSnackbar } = useSnackbar();

    const {logout,user,location,global,globalUpdate,action,actionUpdate,view,viewUpdate} = useContext(AuthContext);


	const markerIcon = {
        'default':markerIcon_default,
        'home':markerIcon_home,
        'hospital':markerIcon_hospital,
        'gasstation':markerIcon_gasstation,
        'park':markerIcon_park,
        'restaurant':markerIcon_restaurant,
        'transport':markerIcon_transport,
        'work':markerIcon_work,
    };



    const [viewLocal,viewLocalUpdate] = useState(['list',0]);
    const [list,listUpdate] = useState({});
    const [areaView,areaViewUpdate] = useState(false);

 
    const settingsClose = () =>{
        view.map.settings = undefined;   
        viewUpdate({...view});
    }

    const areaViewHandle = () =>{
        areaViewUpdate(!areaView);
    }

    const addMarkerHandle = async () =>{

    	let latitude = global.map.handle.getCenter().lat;
        let longitude = global.map.handle.getCenter().lng;

 
        enqueueSnackbar('Adicionando marcador...', { variant: "info"});


        let geoToAddressResponse = geoToAddressHandle(latitude,longitude);

        geoToAddressResponse.then( async (response)=>{

        	let locateString = '';
        	if(typeof response.street !== 'undefined')
            locateString = response.street+', '+response.city+', '+response.state+' '+response.postcode+' '+response.country


            let count = Object.keys(list).length+1;

	        const responseMarker = await setMarker({
				icon:"default",
				title:"new Marker "+count,
				latitude:latitude,
				longitude:longitude,
				fenceenabled:false,
				fencesize:0,
				description: locateString,
			});

	
			listMarkerHandle(()=>{
				global.map.markerRefresh();
			});

        })


    }


    const listMarkerHandle = async (callback) => {
		const response = await listMarker('');

		let status = response.data.status;
		let body = response.data.body;


		if(status === 'success'){
			listUpdate(body);
		}else{
			listUpdate({});			
		}
		// setTimeout(()=>{
			if(callback)callback();
		// });
	}

	const saveMarkerHandle = async () => {
		
		enqueueSnackbar('Salvando marcador...', { variant: "info"});

		viewLocalUpdate(['list']);

		let key = viewLocal[1];

		let id = list[key].id;

		// let input = window.confirm("Deseja salvar?");		
		// if(input == false) return;

		let date = list[key];
		
		const response = await updateMarker(id,date);

		// let status = response.data.status;
		// let body = response.data.body;
	
		listMarkerHandle(()=>{
			global.map.markerRefresh();
			
		});

	}
	const delMarkerHandle = async (key) => {
		

		let id = list[key].id;

		// let input = window.confirm("Deseja apagar?");		
		// if(input == false) return;
	
		enqueueSnackbar('Apagando marcador...', { variant: "info"});
		
		const response = await deleteMarker(id);

		let status = response.data.status;
		let body = response.data.body;
	
		listMarkerHandle(()=>{
			global.map.markerRefresh();
		});

	}



	useEffect(()=>{		
		action.map.updateMarker = async (id,data) => {   	        
			const response = await updateMarker(id,data);			
			listMarkerHandle(()=>{
				global.map.markerRefresh();
			});		       
		}	    
	    actionUpdate({ ...action});   
	},[]);

	const selectMarkerHandle = (key) => {

		let latitude = list[key].latitude;
		let longitude = list[key].longitude;

		global.map.handle.setView([latitude,longitude], global.map.handle.getZoom(), {
              animate: true,
            });

		setTimeout(()=>{
			document.querySelector('.markericon-'+key).click();
		},500);

	}

	useEffect(()=>{
		listMarkerHandle();
	},[]);

	

    return (
		<>
			
				
				<div className="line mobile-nobreak tablet-nobreak SettingsMap_header">
					<div className="column left middle">
						<h3>Marcador</h3>				
					</div>	
					
					{ viewLocal[0] === 'edit' && (
						<>
							<div className="column col1 middle">
								<button className="link  w100 padding" onClick={()=>{ saveMarkerHandle() }}>
									<FontAwesomeIcon icon={faSave} />
								</button>
							</div>	
							<div className="column col1 middle">
								<button className="link  w100 padding" onClick={()=>{ viewLocalUpdate(['list']) }}>
									<FontAwesomeIcon icon={faBan} />
								</button>
							</div>	
						</>
					)}

					{ viewLocal[0] === 'list' && (
						<>
							<div className="column col1 middle">
								<button className="link  w100 padding" onClick={()=>{ addMarkerHandle() }}>
									<FontAwesomeIcon icon={faPlus} />
								</button>
							</div>	
							<div className="column col1 right middle">
								<button className="link  padding-h-10" onClick={()=>{ settingsClose() }}>
									<FontAwesomeIcon icon={faTimes} />
								</button>
							</div>	
						</>
					)}

					{/*<div className="column col1 middle">
						<button className="link  w100" onClick={()=>{ areaViewHandle() }}>
							<FontAwesomeIcon icon={faSortAmountDownAlt} />
						</button>
					</div>	*/}
										
				</div>	

					<div className="SettingsMap_content">
				
					
					{ viewLocal[0] === 'edit' && (
						

						<div className="SettingsMap_edit">
							
							<div className="line mobile-nobreak tablet-nobreak">								
								<div className="column left middle padding">
									
									<div className="line mobile-nobreak tablet-nobreak">								
										<div className="column col4 left top padding-right ">
											<label>Icone</label>
											<select className="select w100" value={list[viewLocal[1]].icon} onChange={(e)=>{ list[viewLocal[1]].icon = e.target.value; listUpdate({...list});}}>
												<option value="default">Default</option>
												<option value="home">Casa</option>
												<option value="hospital">Hospital</option>
												<option value="gasstation">Combustivel</option>
												<option value="park">Parque</option>
												<option value="restaurant">Restaurante</option>
												<option value="transport">Transporte</option>
												<option value="work">Trabalho</option>
											</select>
										</div>
										<div className="column left top ">
											<label>Titulo</label>
											<input type="text" name="title" placeholder="" className="input w100" value={list[viewLocal[1]].title} onChange={(e)=>{ list[viewLocal[1]].title = e.target.value; listUpdate({...list}); }}/>
										</div>
									</div>

									
						
									<label>Descrição</label>
									<input type="text" name="description" placeholder="" className="input w100" value={list[viewLocal[1]].description} onChange={(e)=>{ list[viewLocal[1]].description = e.target.value; listUpdate({...list}); }}/>

									<div className="line mobile-nobreak tablet-nobreak">								
										<div className="column left middle padding-right ">
											<label>Latitude</label>
											<input type="text" name="latitude" placeholder="" className="input w100" value={list[viewLocal[1]].latitude} onChange={(e)=>{ list[viewLocal[1]].latitude = e.target.value; listUpdate({...list}); }}/>
										</div>
										<div className="column left middle ">
											<label>Longitude</label>
											<input type="text" name="longitude" placeholder="" className="input w100" value={list[viewLocal[1]].longitude} onChange={(e)=>{ list[viewLocal[1]].longitude = e.target.value; listUpdate({...list}); }}/>
										</div>
									</div>

									<div className="line mobile-nobreak tablet-nobreak">								
										<div className="column col6 left top padding-right ">
											
											<label>Cerca virtual</label>
											<select className="select w100" value={list[viewLocal[1]].fenceenabled} onChange={(e)=>{ list[viewLocal[1]].fenceenabled = e.target.value; listUpdate({...list});}}>
												<option value="false">Certa desativada</option>
												<option value="true">Certa ativada</option>
											</select>
										</div>
										<div className="column left top ">
											<label>Tamanho da certa</label>
											<input type="number" name="fencesize" placeholder="" className="input w100" value={list[viewLocal[1]].fencesize} onChange={(e)=>{ list[viewLocal[1]].fencesize = e.target.value; listUpdate({...list});}}/>
										</div>
									</div>

									

									


								</div>
							</div>
								
							
							{/*<div className="line mobile-nobreak tablet-nobreak">
								
								<div className="column col1 left middle">
									<button className="link  w100 padding" onClick={()=>{  }}>
										<FontAwesomeIcon icon={faSave} />
									</button>
								</div>	
								<div className="column col1 left middle">
									<button className="link  w100 padding" onClick={()=>{  }}>
										<FontAwesomeIcon icon={faBan} />
									</button>
								</div>	
							</div>*/}

						</div>
					)}


					{ viewLocal[0] === 'list' && (
						
						<div className="listing">
							<ul>
							{Object.keys(list).map((key,index) => {

								let icon = list[key].icon;

								{/*let htmlDeviceIcon = '<img  src="'+markerIcon[icon]+'">'; */}

								return (
									<li key={key}>
										<div className="line mobile-nobreak tablet-nobreak">
											<div className="column col1 left middle">
												<img  src={markerIcon[icon]} className="iconlist"/>
											</div>
											<div className="column left middle">
												<a onClick={()=>{ selectMarkerHandle(key)}} >{list[key].title}</a>
											</div>
											<div className="column col1 left middle">
												<button className="link  w100 padding" onClick={()=>{ viewLocalUpdate(['edit',key]) }}>
													<FontAwesomeIcon icon={faEdit} />
												</button>
											</div>	
											<div className="column col1 left middle">

												<QuestionInline>
													<div question="">
														<button className="link  w100 padding" >
															<FontAwesomeIcon icon={faTrash} />
														</button>
													</div>
													<div alternatives="">

														<div response="yes">
															<button className={"link -primary padding" } onClick={()=>{ delMarkerHandle(key) }} >
												    			<FontAwesomeIcon icon={faCheck} />
												    		</button>
														</div>

									
													</div>
												</QuestionInline>
												
											</div>	
										</div>	
										
									</li>
								)
							})}
							</ul>
						</div>
					)}

					</div>
				

				
		</>
    );
}

export {Marker}
