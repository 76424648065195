import { env } from '../.env.js';
import axios from "axios";

// import {getUniqueID} from "../libs/getuniqueid.js";
// import  {md5} from "../libs/md5";	

export const api = axios.create({
	baseURL: env.AchoordURL
});



export const getVideos = async (username,camName,dataTracker) => {
	
	// let username = user.username;
	let imei = dataTracker.imei;

	// console.log('--',dataTracker);
	let date = '2023';
	let month = '11';
	let day = '12';

	// return api.get("/cameras/listcameras/",{
	return api.get("http://192.168.15.30/achoord/plugins_source/files/files/api/files/",{
	  headers: {
	  	// 'account': window.username,
		'pwd': username+'/'+imei+'/'+camName+'/'+date+'/'+month+'/'+day+'/',
	  }
	});

}


export const listCameras = async (dataTracker) => {
	
	let imei = dataTracker.imei;
	
	return api.get("/cameras/listcameras/",{
	  headers: {
	  	'account': window.username,
		'imei': imei,
	  }
	});

}

export const startRealtimeCam = async (dataTracker,position) => {
	
	let imei = dataTracker.imei;

    var formDataTracker = new FormData();
	formDataTracker.append('type', 'default');
	formDataTracker.append('title', 'realtimeimages');
	formDataTracker.append('body', position);
	formDataTracker.append('custom1', imei);


	return api.post("https://admin.achoord.com/api/v1/accounts/wallrio/onistation/p/pushnotification/api/channel/sendtotrackers/messages/", formDataTracker,{
        headers: {
                "Content-Type": "application/json",
                'account': window.username,
                // 'imei': imei,
        }
      });

}



export const getImageCamera = async (dataTracker,position) => {
	
	let imei = dataTracker.imei;

	let year = '2023';
	let month = '11';
	let day = '28';

	//  return api.get("/cameras/getimage/",{
	 return api.get("https://admin.achoord.com/api/v1/accounts/wallrio/onistation/p/files/api/files/?pwd=/idr/"+imei+"/cameras/images/ef/"+year+"/"+month+"/"+day+"/current.jpg&mode=content",{
	  headers: {
	  	'account': window.username,
	  	//'id': id,
		//'imei': imei,
	  }
	}); 

}