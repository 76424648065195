import React, {useEffect} from "react";
import { MapContainer, TileLayer, useMap,Marker,Popup ,Polyline,Tooltip,Circle} from 'react-leaflet'


import L from "leaflet";

import stoppedOffline from "./icons/stopped/point-offline.png";

import stoppedOnlineGreen from "./icons/stopped/point-online-green.png";
import stoppedOnlineRed from "./icons/stopped/point-online-red.png";
import stoppedOnlineBlue from "./icons/stopped/point-online-blue.png";

import runOnlineGreen from "./icons/point-online-green.png";
import runOnlineRed from "./icons/point-online-red.png";
import runOnlineBlue from "./icons/point-online-blue.png";

import arrowBlack from "./icons/arrow-black.png";
import arrowRed from "./icons/arrow-red.png";
import arrowBlue from "./icons/arrow-blue.png";
import arrowGreen from "./icons/arrow-green.png";

import {Storage} from "../../../../libs/Storage.jsx";
import {md5} from "../../../../libs/md5.jsx";


// import {ajustArrowZoom} from "../../../../libs/ajustArrowZoom.jsx";
import {PopupMap} from "../PopupMap";

import {getDateLastPosition} from "../getDateLastPosition";

const DrawPoints = (props) => {

	const handleGooglemap = (position) =>{
        
        let latitude = position[0];
        let longitude = position[1];
    
        window.open('https://www.google.com/maps/dir//'+latitude+','+longitude+'/@'+latitude+','+longitude+',15z');
    }
    

   /* useEffect(() => {           
        let zoom = Storage().load('onistation-map-zoom');   
        setTimeout(()=>{            
            ajustArrowZoom(zoom);
        },4000);
    },[props.devices])*/

    return (
		<>
			{Object.keys(props.devices).map((key,index) => {
                    
              props.devices[key].pointsLoaded = {};

                return (
                    <span key={key}>
                        {Object.keys(props.devices[key].points).map((key2,index2) => {

                            let name = props.devices[key].name;
                            let color = props.devices[key].color;
                            let geopos = props.devices[key].points[key2].geopos;
                            let speed = props.devices[key].points[key2].speed;
                            let date = props.devices[key].points[key2].date;
                            let positionid = props.devices[key].points[key2].positionid;
                            let imei = props.devices[key].imei;
                            let created = props.devices[key].created;
                           
                            
                            let options = {
                                active:true, 
                                color: props.devices[key].color,
                                opacity:0.1,
                                weight:10,
                                // dashArray:'0,0',
                                opacitytag:1,
                            };

              

                            let posid = md5(JSON.stringify(geopos));

                            if(typeof props.devices[key].pointsLoaded[posid] !== 'undefined'){
                                return(<div key={key2}></div>)
                            }

                            props.devices[key].pointsLoaded[posid] = 1;

                            let heading = props.devices[key].points[key2].heading;
                                        
                            var opacity = 1;
                            let width = 15;
                            let height = 15;

                            let zoom = Storage().load('onistation-map-zoom');     
                            if(zoom <= 17){                                         
                                width = 0;
                                height = 0;
                                options.weight = 0;
                            }
                
                            let htmlDeviceIcon = '<img class="arrowdirection" style="opacity:'+opacity+';transform: rotate('+heading+'deg);" height="'+width+'" width="'+width+'" src='+arrowBlack+'>';                    
                            if(props.devices[key].color === 'red') htmlDeviceIcon = '<img class="arrowdirection" style="opacity:'+opacity+';transform: rotate('+heading+'deg);" height="'+width+'" width="'+width+'" src="'+arrowRed+'">';
                            if(props.devices[key].color === 'blue') htmlDeviceIcon = '<img class="arrowdirection" style="opacity:'+opacity+';transform: rotate('+heading+'deg);" height="'+width+'" width="'+width+'" src="'+arrowBlue+'">';
                            if(props.devices[key].color === 'green') htmlDeviceIcon = '<img class="arrowdirection" style="opacity:'+opacity+';transform: rotate('+heading+'deg);" height="'+width+'" width="'+width+'" src="'+arrowGreen+'">';

                            

                            var DeviceIcon = L.divIcon({
                                iconSize: [width, height],
                                iconAnchor: [0, 0],
                                //iconAnchor: [width/2, height/2],
                                className: 'pointsicon num-'+index,
                                html: htmlDeviceIcon
                            });

                            

                            return (
                                <span key={index2}>

                                    <Marker 
                                    icon={DeviceIcon} 
                                    position={[geopos[0],  geopos[1]]}                                    
                                    >
                                        <Popup offset={[0, -30]}>
                                            <PopupMap 
                                                marker={true}
                                                images={true}
                                                subtitle={"Ponto no trajeto"}
                                                //imei={props.devices[key].imei} 
                                                created={created} 
                                                imei={imei} 
                                                color={color} 
                                                name={name} 
                                                speed={speed} 
                                                positionid={positionid} 
                                                // lastonline={parseInt(props.devices[key].positiondata[key2][0].created)} 
                                                //lastupdate={props.devices[key].positiondata[key2][pos].date.split(' ')[1]} 
                                                 lastposition={geopos}  
                                                 arrivalTime={date}  
                                               /* parkedTime={parkedTime}  
                                                departureTime={departureTime}  
                                                exitTime={exitTime}  
                                                travelTime={travelTime}  
                                                geoDistance={geoDistance}  
                                                geoDistanceTotal={geoDistanceTotal}  */ 
                                            />
                                        </Popup>
                    
                                    </Marker>


                                    {/*  <Circle 
                                     className="point"
                                    center={[geopos[0],  geopos[1]]}                                                      
                                    pathOptions={options} 
                                    radius={2}>
                                    
                                    </Circle>  */}
                                </span>
                            );
                        })}
                    </span>
                );
            })}
		</>
    );
}

export {DrawPoints}
