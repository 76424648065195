// import Header from "../../layout/header/index.jsx";
// import Footer from "../../layout/footer/index.jsx";
// import FormLogin from "../../layout/formlogin/index.jsx";

import logo from "../../logo.png";

import "./style.css";

const Splash = () => {

	

	return (
		<div className="--splash wrapper layout_splash h100">
			<div className="line h100">
				<div className="column middle center h100">

					<img src={logo} className="logo" />
					<h1>Onistation</h1>

				</div>
			</div>
		</div>

	);
};


export default Splash;
