// import React, {useState, createContext, useEffect,useContext} from "react";
// import {AuthProvider,AuthContext} from "../../../contexts/auth";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFlag,faThumbtack,faMapMarkerAlt,faUsers,faUserCircle,faGlobe,faCircle,faBell,faHome,faRobot,faSignOut,faCodeBranch,faBuildingColumns,faWaveSquare,faGear} from '@fortawesome/free-solid-svg-icons'


const PopupDefault = (props) => {

    const handleGooglemap = (position) =>{
        
        let latitude = position[0];
        let longitude = position[1];
    
        window.open('https://www.google.com/maps/dir//'+latitude+','+longitude+'/@'+latitude+','+longitude+',15z');
    }



    return(
        <>
            <h3>
            {props.title}
            <small>{props.subtitle}</small>
            </h3>

            {props.address && (
                <small style={{"display":"block","marginBottom":"10px","fontSize":"11px"}}>{props.address}</small>
            )}

            {/*<table>
                <tbody>
                  
                    {props.description && (
                        <tr>
                            <th>Descrição</th><td>{props.description}</td>
                        </tr>
                    )}

                </tbody>
            </table>*/}
            <div className="line mobile-nobreak">
                {props.position && (
	                <div className="column ">
	                    <button className="button w100" title="Ver localização no Google Maps" onClick={()=>{handleGooglemap(props.position)}}><FontAwesomeIcon icon={faMapMarkerAlt} /></button>
	                </div>
                )}

                
                    
                
            </div>
        </>
    )
}

export {PopupDefault}